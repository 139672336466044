<template>
  <app-full-modal :title="$t('wallet.transaction.transaction_detail')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="transaction">
        <!-- Transaction Details -->

        <div class="p-5 space-y-2">
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.id") }}
            </p>
            <p class="col-span-4">{{ transaction?._id }}</p>
          </div>
          <div
            v-if="isMasterBusinessRole"
            class="block sm:grid grid-cols-5 text-sm"
          >
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.business_name") }}
            </p>
            <p class="col-span-4">{{ transaction?.business?.name }}</p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.amount") }}
            </p>
            <p class="col-span-4">
              {{
                $formats.currency(
                  transaction?.currency,
                  transaction?.amount ?? 0.0
                )
              }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.type") }}
            </p>
            <p class="col-span-4 capitalize">
              {{ transaction?.type?.replace("_", " ") }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.date") }}
            </p>
            <p class="col-span-4">
              {{
                $moment(transaction?.created_at).format("DD MMM YYYY hh:mm a")
              }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.description") }}
            </p>
            <p class="col-span-4">
              {{ transaction?.description ?? "-" }}
            </p>
          </div>
        </div>

        <div v-if="showBusiness">
          <!-- Transaction Details -->
          <p class="font-medium px-4 py-2">
            {{ $t("wallet.transaction.business_detail") }}
          </p>
          <hr />

          <div class="p-5 space-y-2">
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.transaction.business_id") }}
              </p>
              <p class="col-span-4">{{ transaction.business?.id ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.transaction.business_name") }}
              </p>
              <p class="col-span-4">{{ transaction.business?.name ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.transaction.created_at") }}
              </p>
              <p class="col-span-4">
                {{
                  $moment(transaction.business?.created_at).format(
                    "DD MMM YYYY hh:mm a"
                  )
                }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.transaction.submitted_at") }}
              </p>
              <p class="col-span-4">
                {{
                  $moment(transaction.business?.submitted_at).format(
                    "DD MMM YYYY hh:mm a"
                  )
                }}
              </p>
            </div>
          </div>
        </div>

        <!-- Settlement Reference  -->
        <div v-if="transaction.reference_model?.includes('Settlement')">
          <p class="font-medium px-4 py-2">
            {{ $t("wallet.transaction.settlement_reference") }}
          </p>

          <hr />

          <div class="p-5 space-y-2">
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.transaction.processing_id") }}
              </p>
              <p class="col-span-4">
                {{ transaction.reference_id }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.transaction.start_date") }}
              </p>
              <p class="col-span-4">
                {{
                  $moment(transaction.reference?.settlement_start_date).format(
                    "DD MMM YYYY hh:mm a"
                  )
                }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.transaction.end_date") }}
              </p>
              <p class="col-span-4">
                {{
                  $moment(transaction.reference?.settlement_end_date).format(
                    "DD MMM YYYY hh:mm a"
                  )
                }}
              </p>
            </div>
          </div>

          <p class="font-medium px-4 py-2">
            {{ $t("wallet.transaction.payment_details") }}
          </p>

          <hr />

          <div class="flex justify-center p-10">
            <app-link
              :to="{
                name: routeName,
                params: {
                  settlement_id: transaction.reference_id,
                  business_id: transaction.business_id,
                },
              }"
            >
              <app-button-outline>
                {{ $t("wallet.transaction.view_payment_details") }}
              </app-button-outline>
            </app-link>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>

export default {
  props: {
    transaction: {
      type: Object,
      default: null,
    },
    showBusiness: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["walletStore/loading"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },
  },
  methods: {
    onClick(value) {
      this.$emit("onClick", value);
    },
  },
};
</script>
