<template>
  <div>
    <!-- Label Payment Method -->
    <div class="flex flex-row mb-4 space-x-1.5 items-center">
      <p class="text-lg font-semibold">
        {{ $t("payment.link.payment_method") }}
      </p>
      <app-test-mode-badge :testMode="test_mode" />
    </div>

    <!-- Selection Payment Method -->
    <div class="space-y-3 md:space-y-0 flex flex-col md:grid md:gap-2" :class="
      payment_methods.length == 1 || preview_mode
        ? 'md:grid-cols-1'
        : 'md:grid-cols-2'
    ">
      <app-button class="flex flex-col space-y-1" v-for="payment_method in payment_methods" :key="payment_method.id"
        :id="payment_method.payment_gateway_method_application
        .payment_gateway_method.payment_method.name" :showf70Icon="false" alignment="start" :primary="false" :class="{
    'border border-primary':
      selectedBusinessPaymentGatewayId == payment_method._id,
  }" :active="selectedBusinessPaymentGatewayId == payment_method._id" @click="selectPaymentMethod(payment_method)">
        <img v-if="show_icon_payment_method" class="h-5 object-contain" :src="
          payment_method.payment_gateway_method_application
            .payment_gateway_method.payment_method.image_url
        " />
        <p class="text-left" :class="[preview_mode ? 'text-xs' : 'text-xs md:text-sm']">
          {{
              $t(
                "payment.link.payment_methods." +
                payment_method.payment_gateway_method_application
                  .payment_gateway_method.payment_method.id
              )
          }}
        </p>
      </app-button>
    </div>

    <!-- Show Bank List -->
    <div v-if="showOnlineBankLayout">
      <app-form-select-scroll id="online_bank" :labelText="$t('payment.link.select_bank')" name="online_bank"
        class="text-sm pt-5" type="String" :datas="onlineBanks" :allowFilter="true" :showIcon="true"
        v-model="selectOnlineBank" />
    </div>

    <!-- Show Card Info -->
    <div v-if="showCardLayout">
      <p class="text-left text-sm w-full mt-4">
        {{ $t("payment.link.card_info") }}
      </p>

      <div class="flex flex-col">
        <app-form-input type="text" inputclass="rounded-t-md" :rounded="false" v-model="card_info.card_name"
          :placeholder="$t('payment.link.card_name_placeholder')" />
        <app-form-input-mask type="text" class="w-full" :rounded="false" v-model="card_info.card_number"
          :placeholder="$t('payment.link.card_no_placeholder')" :mask="'#### #### #### ####'" />
        <div class="flex flex-row space-x-2 mt-2">
          <app-form-input-mask type="text" class="w-full" inputclass="rounded-bl-md" v-model="card_info.card_expiry"
            :rounded="false" :placeholder="$t('payment.link.card_expiry_placeholder')" :showlabel="false"
            :mask="'##/##'" />
          <app-form-input-mask type="text" class="w-full" inputclass="rounded-br-md" v-model="card_info.card_cvc"
            :rounded="false" :placeholder="$t('payment.link.card_ccv_placeholder')" :showlabel="false" :mask="'###'" />
        </div>
      </div>
    </div>

    <div class="mt-4 p-4 border rounded-md font-light text-base shadow-xs focus:outline-none border-gray-300"
      id="card-element" v-if="showStripeLayout">
    </div>

    <!-- Show Errors -->
    <div class="flex flex-col mt-4" v-if="errors.open_payment_link && !preview_mode">
      <p class="text-error">
        {{ errors.open_payment_link[0] }}
      </p>
    </div>

    <div class="flex flex-col mt-4" v-if="errors.payment_link && !preview_mode">
      <p class="text-error">
        {{ errors.payment_link[0] }}
      </p>
    </div>

    <!-- Show FPX Term -->
    <p v-if="showOnlineBankLayout && !loading" class="text-xs mt-4">
      <span class="text-sm text-gray-700">
        {{ $t("payment.link.fpx_terms_word") }}
      </span>
      <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" class="text-bluelink text-sm" target="_blank">
        {{ $t("payment.link.fpx_terms_conditions") }}
      </a>
    </p>

    <!-- Proceed To Payment -->
    <app-button type="submit" class="w-full mt-6" :loading="loading" :disabled="disabledButton" :showProceedIcon="true"
      @click="proceedPayment">
      <p class="truncate ...">{{ $t("payment.link.proceed") }}</p>
    </app-button>
  </div>
</template>

<script>
import PAYMENT_METHODS from "@/utils/const/payment_methods";
import PAYMENT_CHANNEL from "@/utils/const/payment_channel";
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
import { loadStripe } from '@stripe/stripe-js';

export default {
  props: {
    payment_methods: {
      required: true,
    },
    preview_mode: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    test_mode: {
      type: Boolean,
      default: false,
    },
    show_icon_payment_method: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      showIcon: true,
      showDetails: false,
      showCardLayout: false,
      showOnlineBankLayout: false,
      showStripeLayout: false,
      selectedBusinessPaymentGatewayId: 0,
      selectOnlineBank: null,
      tailwindConfig: null,
      stripe: null,
      stripeCanProceed: null
    };
  },
  computed: {
    business() {
      return this.masterBusinessMode
        ? this.open_payment_link.business
        : this.$store.getters["businessStore/business"];
    },
    payment_link() {
      return this.$store.getters["paymentStore/payment_link"];
    },
    card_info() {
      return this.$store.getters["paymentStore/card_info"];
    },
    onlineBanks() {
      return this.$store.getters["paymentStore/onlineBanks"];
    },
    disabledButton() {
      if (this.showOnlineBankLayout) {
        return (
          this.selectOnlineBank == null ||
          this.selectedBusinessPaymentGatewayId == 0
        );
      }

      if (this.showStripeLayout) {
        return (this.stripeCanProceed == null ||
          this.stripeCanProceed == false ||
          this.selectedBusinessPaymentGatewayId == 0
        );
      }

      return this.selectedBusinessPaymentGatewayId == 0;
    },
  },

  beforeCreate() {
    this.$store.commit("paymentStore/initCardInfo");
  },

  async mounted() {
    this.tailwindConfig = resolveConfig(tailwindConfig);
  },

  watch: {
    payment_methods: function (payment_methods) {
      if (payment_methods?.length > 0) {
        this.selectPaymentMethod(this.payment_methods[0]);
      }
    },
  },

  methods: {
    async proceedPayment() {
      if (this.preview_mode) return;

      if (this.showStripeLayout) {
        this.$store.commit("paymentStore/setLoading", true);
        try {
          const stripe_create_payment_result = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.elements,
            billing_details: {
              email: this.payment_link.email,
            },
          });

          this.$emit("proceed_payment", {
            business_payment_gateway_method_id:
              this.selectedBusinessPaymentGatewayId,
            card_info: null,
            bank_info: {
              code: null,
            },
            stripe_create_payment_result: stripe_create_payment_result,
            stripe: this.stripe
          });
          return;
        } catch (_) {
          this.$store.commit("paymentStore/setLoading", false);
        }
      }

      this.$emit("proceed_payment", {
        business_payment_gateway_method_id:
          this.selectedBusinessPaymentGatewayId,
        card_info: this.card_info,
        bank_info: {
          code: this.selectOnlineBank?.id ?? null,
        }
      });
    },
    async selectPaymentMethod(businessPaymentMethodGateway) {
      this.resetState();

      this.selectedBusinessPaymentGatewayId = businessPaymentMethodGateway._id;

      let paymentGatewayMethod =
        businessPaymentMethodGateway.payment_gateway_method_application
          .payment_gateway_method;

      // show card layout
      this.showCardLayout =
        (paymentGatewayMethod.payment_method_id ==
          PAYMENT_METHODS.CREDIT_CARD &&
          paymentGatewayMethod.channel == PAYMENT_CHANNEL.ROSE_ENGINE) ||
        paymentGatewayMethod.channel == PAYMENT_CHANNEL.GO_BIZ;

      // show online bank layout
      this.showOnlineBankLayout =
        paymentGatewayMethod.payment_method_id ==
        PAYMENT_METHODS.ONLINE_BANKING_B2C;

      this.showStripeLayout = (paymentGatewayMethod.payment_method_id ==
        PAYMENT_METHODS.CREDIT_CARD &&
        paymentGatewayMethod.channel == PAYMENT_CHANNEL.STRIPE);

      if (this.showStripeLayout) {
        await this.loadStripe();
      }

      if (this.showOnlineBankLayout) {
        this.getOnlineBankingByBusiness();
      }
    },
    async loadStripe() {
      const fontSize = (this.getScreenSize() == "sm") ?
        this.tailwindConfig.theme.fontSize.sm :
        this.tailwindConfig.theme.fontSize.md

      let publishableKey = this.test_mode 
          ? process.env.VUE_APP_STRIPE_PUBLIC_KEY_TEST 
          : process.env.VUE_APP_STRIPE_PUBLIC_KEY;
      
      this.stripe = await loadStripe(publishableKey);

      const style = {
        base: {
          fontFamily: this.tailwindConfig.theme.fontFamily.qanelas.toString(),
          fontWeight: 'light',
          color: this.tailwindConfig.theme.colors.black,
          fontSize: fontSize,
          "::placeholder": {
            color: this.tailwindConfig.theme.colors.bordercolor
          }
        },
        invalid: {
          fontFamily: this.tailwindConfig.theme.fontFamily.qanelas.toString(),
          fontWeight: 'light',
          color: this.tailwindConfig.theme.colors.error,
          fontSize: fontSize,
          iconColor: "#fa755a"
        }
      };

      const fontPath = require("@/assets/fonts/qanelas/qanelas_soft_regular.otf");
      const elements = await this.stripe.elements({
        fonts: [
          {
            src: "url('" + fontPath + "')",
            family: "Qanelas Soft"
          }
        ]
      });
      // Create and mount the Payment Element
      this.elements = elements.create('card', {
        hidePostalCode: true,
        style: style
      });

      this.elements.mount('#card-element');

      this.elements.on('change', function (event) {
        this.stripeCanProceed = (event.complete);
      }.bind(this));
    },
    async getOnlineBankingByBusiness() {
      await this.$store.dispatch(
        "paymentStore/getOnlineBankByBusiness",
        this.business.id
      );
    },
    async resetState() {
      this.$store.commit("paymentStore/setErrorMessage");
    },

    getScreenSize() {
      var screenSize = 'sm';
      for (const [key, value] of Object.entries(this.tailwindConfig.theme.screens)) {
        if (window.innerWidth > parseInt(value.replace('px', ''))) {
          screenSize = key;
        }

      }
      return screenSize;
    }
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Qanelas Soft";
  font-weight: 100;
  src: url('../../../assets/fonts/qanelas/qanelas_soft_regular.otf');
}
</style>