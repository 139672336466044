import { VueReCaptcha } from 'vue-recaptcha-v3'

function registerAppComponent(app) {

  const requireComponent = require.context('@/views/components', true, /app-[\w-]+\.vue$/)

  requireComponent.keys().forEach((fileName) => {

    const componentConfig = requireComponent(fileName)

    const componentName = fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, '')

    app.component(componentName, componentConfig.default || componentConfig)

  })
}

function registerReCaptchaComponent(app) {

  app.use(VueReCaptcha,
    {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loaderOptions: {
        autoHideBadge: true
      }
    })

  app.component('VueReCaptcha', VueReCaptcha);
}

export default function register(app) {

  registerAppComponent(app)
  registerReCaptchaComponent(app)

}
