import AuthRepository from "../../data/repository/auth-repository";
import baseStore from "../base-store";
import { logEvent } from "firebase/analytics";
import IntegrationRepository from "../../data/repository/integration-repository";

const initialIntegration = {
  id: null,
  api_key: null,
  signature_key: null,
};

const initialWebhook = {
  id: null,
  name: null,
  url: null,
  enabled: null,
};

const initialEventNames = [];

export default {
  namespaced: true,
  state: {
    ...baseStore.state,
    apiPaginationWebhookLogResponse: null,
    integration: initialIntegration,
    webhook: initialWebhook,
    eventNames: initialEventNames,
    loadingEventNames: false,
  },

  getters: {
    ...baseStore.getters,

    integration(state) {
      return state.integration;
    },
    webhook(state) {
      return state.webhook;
    },
    eventNames(state) {
      return state.eventNames;
    },
    loadingEventNames(state) {
      return state.loadingEventNames;
    },
    apiPaginationWebhookLogResponse(state) {
      return state.apiPaginationWebhookLogResponse;
    }
  },

  mutations: {
    ...baseStore.mutations,

    setIntegration(state, integration) {
      state.integration = integration;
    },
    setWebhook(state, webhook) {
      state.webhook = webhook;
    },
    clearWebhook(state) {
      state.webhook = initialWebhook;
    },
    setEventNames(state, eventNames) {
      state.eventNames = eventNames;
    },
    setLoadingEventNames(state, loadingEventNames) {
      state.loadingEventNames = loadingEventNames;
    },
    setApiPaginationWebhookLogResponse(state, apiPaginationWebhookLogResponse) {
      state.apiPaginationWebhookLogResponse = apiPaginationWebhookLogResponse;
    }
  },

  actions: {
    async retrieveIntegration(state, businessId) {
      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = businessId ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const integrationResult = await integrationRepository.getIntegration(
        business_id
      );

      if (integrationResult.message != undefined) {
        logEvent(this.$analytics, "get-integration", { result: "failed" });
        state.commit("setErrorMessage", integrationResult);
        return;
      } else {
        logEvent(this.$analytics, "get-integration", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setIntegration", integrationResult.data);
      return integrationResult;
    },

    async resetApiKey(state, businessId) {
      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = businessId ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const resetApiResult = await integrationRepository.resetApiKey(
        business_id,
        state.getters.integration.id
      );

      if (resetApiResult.message != undefined) {
        logEvent(this.$analytics, "reset-api", { result: "failed" });
        state.commit("setErrorMessage", resetApiResult);
        return;
      } else {
        logEvent(this.$analytics, "reset-api", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setIntegration", resetApiResult.data);
      return resetApiResult;
    },

    async resetSignatureKey(state, businessId) {
      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = businessId ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const resetSignatureResult =
        await integrationRepository.resetSignatureKey(
          business_id,
          state.getters.integration.id
        );

      if (resetSignatureResult.message != undefined) {
        logEvent(this.$analytics, "reset-signature", { result: "failed" });
        state.commit("setErrorMessage", resetSignatureResult);
        return;
      } else {
        logEvent(this.$analytics, "reset-signature", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setIntegration", resetSignatureResult.data);
      return resetSignatureResult;
    },

    async webhookIndex(state, filters) {
      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = filters.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);

      const webhookIndexResult = filters.is_master_business
        ? await integrationRepository.webhookIndexForMasterBusiness(
          business_id,
          state.getters.integration.id,
          filters
        )
        : await integrationRepository.webhookIndex(
          business_id,
          state.getters.integration.id,
          filters
        );

      if (webhookIndexResult.message != undefined) {
        logEvent(this.$analytics, "webhook-index", { result: "failed" });
        state.commit("setErrorMessage", webhookIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "webhook-index", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", webhookIndexResult);
      return webhookIndexResult;
    },

    async webhookDetailsIndex(state, params) {

      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = params.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const webhookIndexResult =
        await integrationRepository.webhookDetailsIndex(
          business_id,
          params.integration_id,
          params.webhook_event_id,
          params.filters
        );

      if (webhookIndexResult.message != undefined) {
        logEvent(this.$analytics, "webhook-details-index", {
          result: "failed",
        });
        state.commit("setErrorMessage", webhookIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "webhook-details-index", {
          result: "success",
        });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationWebhookLogResponse", webhookIndexResult);
      return webhookIndexResult;
    },

    async webhookRetry(state, data) {
      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id ??
        data.business_id;

      if (business_id == null) {
        return;
      }

      const webhookEventNamesResult = await integrationRepository.webhookRetry(
        business_id,
        data.integration_id,
        data.webhook_event_id,
        data.webhook_event_log_id
      );

      if (webhookEventNamesResult.message != undefined) {
        logEvent(this.$analytics, "webhook-retry", { result: "failed" });
        state.commit("setErrorMessage", webhookEventNamesResult);
      } else {
        logEvent(this.$analytics, "webhook-retry", { result: "success" });
      }
      state.commit("setLoading", false);

      return webhookEventNamesResult;
    },

    async retrieveEventNames(state, filters) {
      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoadingEventNames", true);

      const webhookEventNamesResult = filters.is_master_business
        ? await integrationRepository.getWebhookEventNamesForMasterBusiness(business_id)
        : await integrationRepository.getWebhookEventNames(business_id);

      if (webhookEventNamesResult.message != undefined) {
        logEvent(this.$analytics, "get-event-names", { result: "failed" });
        state.commit("setErrorMessage", webhookEventNamesResult);
        return;
      } else {
        logEvent(this.$analytics, "get-event-names", { result: "success" });
      }

      const eventNames = [];
      webhookEventNamesResult.data?.forEach((eventName, index) => {
        eventNames.push({
          id: index + 1,
          name: eventName,
        });
      });

      state.commit("setLoadingEventNames", false);
      state.commit("setEventNames", eventNames);
      return webhookEventNamesResult;
    },

    async createOrUpdateWebhook(state, businessId) {
      const integrationRepository = new IntegrationRepository();
      const authRepository = new AuthRepository();

      const business_id = businessId ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const webhookResult = await integrationRepository.createOrUpdate(
        business_id,
        state.getters.integration.id,
        state.getters.webhook
      );

      if (webhookResult.message != undefined) {
        logEvent(
          this.$analytics,
          webhookResult.id == null
            ? "create-webhook-event-link"
            : "update-webhook-event-link",
          { result: "failed" }
        );
        state.commit("setErrorMessage", webhookResult);
        return null;
      } else {
        logEvent(
          this.$analytics,
          webhookResult.id == null
            ? "create-webhook-event-link"
            : "update-webhook-event-link",
          { result: "success" }
        );
      }

      state.commit("setLoading", false);
      state.commit("setWebhook", initialWebhook);
      return webhookResult;
    },

    async generateTestModePaymentProvider(state, businessId) {
      const integrationRepository = new IntegrationRepository();

      if (businessId == null) {
        return;
      }
      state.commit("setLoading", true);

      const resetApiResult = await integrationRepository.generateTestModePaymentProvider(
        businessId,
      );

      if (resetApiResult.message != undefined) {
        logEvent(this.$analytics, "generate-test-mode-payment-provider", { result: "failed" });
        state.commit("setErrorMessage", resetApiResult);
        return;
      }

      logEvent(this.$analytics, "generate-test-mode-payment-provider", { result: "success" });
      state.commit("setLoading", false);

      return resetApiResult;
    },

  },
};
