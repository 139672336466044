export default {
  "app": {
    "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swipe"])};fn.source="Swipe";return fn;})(),
    "company_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swipe Malaysia Sdn Bhd"])};fn.source="Swipe Malaysia Sdn Bhd";return fn;})(),
    "product_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swipe Go"])};fn.source="Swipe Go";return fn;})()
  },
  "general": {
    "swipego": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SwipeGo"])};fn.source="SwipeGo";return fn;})(),
    "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
    "phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])};fn.source="Phone Number";return fn;})(),
    "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
    "full_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])};fn.source="Full Name";return fn;})(),
    "country": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])};fn.source="Country";return fn;})(),
    "state": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])};fn.source="State";return fn;})(),
    "business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])};fn.source="Business";return fn;})(),
    "master_merchant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Merchant"])};fn.source="Master Merchant";return fn;})(),
    "type_of_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of Business"])};fn.source="Type Of Business";return fn;})(),
    "company_annual_turnover": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Annual Turnover"])};fn.source="Company Annual Turnover";return fn;})(),
    "bank": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])};fn.source="Bank";return fn;})(),
    "placeholder_bank_search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for your bank"])};fn.source="Search for your bank";return fn;})(),
    "industries": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industries"])};fn.source="Industries";return fn;})(),
    "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])};fn.source="Password";return fn;})(),
    "confirm_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])};fn.source="Confirm Password";return fn;})(),
    "new_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])};fn.source="New Password";return fn;})(),
    "retype_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-type your password"])};fn.source="Re-type your password";return fn;})(),
    "privacy_policy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])};fn.source="Privacy Policy";return fn;})(),
    "sign_in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])};fn.source="Sign In";return fn;})(),
    "contact": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])};fn.source="Contact";return fn;})(),
    "term_and_conditions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])};fn.source="Terms & Conditions";return fn;})(),
    "placeholder_search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])};fn.source="Search...";return fn;})(),
    "continue": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved & Continue"])};fn.source="Saved & Continue";return fn;})(),
    "change_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])};fn.source="Change Password";return fn;})(),
    "sign_up": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])};fn.source="Sign Up";return fn;})(),
    "sign_out": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])};fn.source="Sign Out";return fn;})(),
    "unable_to_reach_server": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we're unable to reach the server right now."])};fn.source="Sorry, we're unable to reach the server right now.";return fn;})(),
    "try_again": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to retry"])};fn.source="Click to retry";return fn;})(),
    "page_not_found": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])};fn.source="Page Not Found";return fn;})(),
    "field_required": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("name")), " field is required."])};fn.source="The {name} field is required.";return fn;})(),
    "loading": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])};fn.source="Loading...";return fn;})(),
    "no_results": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])};fn.source="No results";return fn;})(),
    "no_more_results": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more results"])};fn.source="No more results";return fn;})(),
    "view_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])};fn.source="View All";return fn;})(),
    "please_select": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please Select ", _interpolate(_named("name"))])};fn.source="Please Select {name}";return fn;})(),
    "required": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])};fn.source="This field is required.";return fn;})(),
    "week_to_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week-to-date"])};fn.source="Week-to-date";return fn;})(),
    "month_to_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month-to-date"])};fn.source="Month-to-date";return fn;})(),
    "year_to_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year-to-date"])};fn.source="Year-to-date";return fn;})(),
    "daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])};fn.source="Daily";return fn;})(),
    "weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])};fn.source="Weekly";return fn;})(),
    "monthly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])};fn.source="Monthly";return fn;})(),
    "yearly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])};fn.source="Yearly";return fn;})(),
    "resend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])};fn.source="Resend";return fn;})(),
    "test_mode_environment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Mode Environment"])};fn.source="Test Mode Environment";return fn;})(),
    "test_mode": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Mode"])};fn.source="Test Mode";return fn;})(),
    "create": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])};fn.source="Create";return fn;})(),
    "email_sent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent!"])};fn.source="Email sent!";return fn;})(),
    "email_error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])};fn.source="Error!";return fn;})(),
    "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
    "saved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved!"])};fn.source="Saved!";return fn;})(),
    "edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])};fn.source="Edit";return fn;})(),
    "next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])};fn.source="Next";return fn;})(),
    "submit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])};fn.source="Submit";return fn;})(),
    "close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])};fn.source="Close";return fn;})(),
    "return_homepage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Homepage"])};fn.source="Return to Homepage";return fn;})(),
    "data_saved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data has been saved."])};fn.source="Data has been saved.";return fn;})(),
    "no_data_found": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found"])};fn.source="No data found";return fn;})(),
    "powered_by": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])};fn.source="Powered by";return fn;})(),
    "address": {
      "line_1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 1"])};fn.source="Address Line 1";return fn;})(),
      "line_2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 2"])};fn.source="Address Line 2";return fn;})(),
      "postcode": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])};fn.source="Postal Code";return fn;})(),
      "city": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])};fn.source="City";return fn;})(),
      "state": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])};fn.source="State";return fn;})()
    },
    "back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])};fn.source="Back";return fn;})(),
    "add": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
    "edit_information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit information"])};fn.source="Edit information";return fn;})(),
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
    "view_more_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view more details"])};fn.source="view more details";return fn;})(),
    "proceed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])};fn.source="Proceed";return fn;})(),
    "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])};fn.source="From";return fn;})(),
    "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])};fn.source="To";return fn;})(),
    "generated_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERATED_ID"])};fn.source="GENERATED_ID";return fn;})(),
    "actions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])};fn.source="Actions";return fn;})(),
    "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})(),
    "submitting": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting..."])};fn.source="Submitting...";return fn;})(),
    "contact_support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])};fn.source="Contact support";return fn;})(),
    "url_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://"])};fn.source="https://";return fn;})(),
    "yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])};fn.source="Yes";return fn;})(),
    "no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])};fn.source="No";return fn;})(),
    "reset_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])};fn.source="Reset Password";return fn;})(),
    "copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])};fn.source="Copy";return fn;})(),
    "copied": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])};fn.source="Copied";return fn;})(),
    "retry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])};fn.source="Retry";return fn;})(),
    "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
    "unsaved_changes_warning": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to leave? you have unsaved changes!"])};fn.source="Do you really want to leave? you have unsaved changes!";return fn;})(),
    "change": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])};fn.source="Change";return fn;})(),
    "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])};fn.source="Delete";return fn;})(),
    "download": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])};fn.source="Download";return fn;})(),
    "download_csv": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])};fn.source="Download CSV";return fn;})(),
    "view": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])};fn.source="View";return fn;})(),
    "invalid_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid link"])};fn.source="Invalid link";return fn;})(),
    "read_more": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])};fn.source="Read more";return fn;})(),
    "read_less": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read less"])};fn.source="Read less";return fn;})(),
    "optional": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Optional)"])};fn.source="(Optional)";return fn;})()
  },
  "navigation": {
    "business_structure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Structure"])};fn.source="Business Structure";return fn;})(),
    "business_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Details"])};fn.source="Business Details";return fn;})(),
    "business_representatives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Representatives"])};fn.source="Business Representatives";return fn;})(),
    "business_owners": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Owners"])};fn.source="Business Owners";return fn;})(),
    "business_support_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Details"])};fn.source="Support Details";return fn;})(),
    "business_bank_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Details"])};fn.source="Bank Details";return fn;})(),
    "business_supporting_document": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting Documents"])};fn.source="Supporting Documents";return fn;})(),
    "business_payment_provider": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Providers"])};fn.source="Payment Providers";return fn;})(),
    "list_sub_merchant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of Sub Merchants"])};fn.source="List of Sub Merchants";return fn;})()
  },
  "message": {
    "resend_successfully": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Successfully"])};fn.source="Resend Successfully";return fn;})(),
    "update_successful": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Successful"])};fn.source="Update Successful";return fn;})(),
    "send_successful": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Successfully"])};fn.source="Send Successfully";return fn;})()
  },
  "notification": {
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])};fn.source="Notifications";return fn;})(),
    "all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])};fn.source="all";return fn;})(),
    "unread": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unread"])};fn.source="unread";return fn;})(),
    "mark_all_as_read": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as read"])};fn.source="Mark all as read";return fn;})()
  },
  "modal": {
    "app_cropper_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust and crop your preffered area of the photo"])};fn.source="Adjust and crop your preffered area of the photo";return fn;})()
  },
  "datatable": {
    "show": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show: "])};fn.source="Show: ";return fn;})(),
    "per_page": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Page"])};fn.source="Per Page";return fn;})(),
    "empty_item_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no item found"])};fn.source="Oops no item found";return fn;})(),
    "empty_item_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items found."])};fn.source="No items found.";return fn;})(),
    "empty_button_text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new Item"])};fn.source="Create new Item";return fn;})(),
    "loading_data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading data..."])};fn.source="Loading data...";return fn;})(),
    "filter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])};fn.source="Filter";return fn;})(),
    "active_filters": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active filters"])};fn.source="Active filters";return fn;})(),
    "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to "])};fn.source=" to ";return fn;})(),
    "clear_filter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filter"])};fn.source="Clear filter";return fn;})()
  },
  "auth": {
    "register": {
      "strength_1_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register and get fast approval to start your business"])};fn.source="Register and get fast approval to start your business";return fn;})(),
      "strength_1_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete registration and send all necessary documentation for fast approval"])};fn.source="Complete registration and send all necessary documentation for fast approval";return fn;})(),
      "strength_2_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth your revenue 100X with swipe"])};fn.source="Growth your revenue 100X with swipe";return fn;})(),
      "strength_2_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus on your business, let's swipe manage your payment(accept, manage, distribute) securely. "])};fn.source="Focus on your business, let's swipe manage your payment(accept, manage, distribute) securely. ";return fn;})(),
      "strength_3_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payment securely"])};fn.source="Accept payment securely";return fn;})(),
      "strength_3_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payment securely with most powerful payment safety protection"])};fn.source="Accept payment securely with most powerful payment safety protection";return fn;})(),
      "agreement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the Swipe's"])};fn.source="I agree to the Swipe's";return fn;})(),
      "user_agreement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreement"])};fn.source="agreement";return fn;})(),
      "create_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])};fn.source="Create Account";return fn;})(),
      "already_have_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])};fn.source="Already have an account?";return fn;})(),
      "password_strength_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain the following:"])};fn.source="Password must contain the following:";return fn;})(),
      "password_strength_upper_case": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Uppercase letter"])};fn.source="1 Uppercase letter";return fn;})(),
      "password_strength_special_char": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["1 Special character ", _interpolate(_named("character"))])};fn.source="1 Special character {character}";return fn;})(),
      "password_strength_min_8": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be longer than 8 characters"])};fn.source="Must be longer than 8 characters";return fn;})(),
      "error_password_strength": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contains 1 Uppercase letter, 1 Special character and Must be longer than 8 characters"])};fn.source="Password must contains 1 Uppercase letter, 1 Special character and Must be longer than 8 characters";return fn;})(),
      "resend_email_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have email you to reset your password. Please check your email for instructions."])};fn.source="We have email you to reset your password. Please check your email for instructions.";return fn;})(),
      "rensend_email_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn’t get the email? Try check your spam folder or resend"])};fn.source="Didn’t get the email? Try check your spam folder or resend";return fn;})(),
      "password_changed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Changed!"])};fn.source="Password Changed!";return fn;})(),
      "password_changed_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your passsword has been changed successfully"])};fn.source="Your passsword has been changed successfully";return fn;})()
    },
    "login": {
      "title_header": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your Swipe account"])};fn.source="Login to your Swipe account";return fn;})(),
      "stay_signed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay signed in for a week"])};fn.source="Stay signed in for a week";return fn;})(),
      "dont_have_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account yet?"])};fn.source="Don't have an account yet?";return fn;})(),
      "forgot_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])};fn.source="Forgot Password?";return fn;})(),
      "reset_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])};fn.source="Reset your password";return fn;})(),
      "reset_my_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset my password"])};fn.source="Reset my password";return fn;})(),
      "reset_message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your registered email address and we'll send you a link to reset your password."])};fn.source="Enter your registered email address and we'll send you a link to reset your password.";return fn;})(),
      "reset_password_error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password did not match"])};fn.source="Password did not match";return fn;})(),
      "resend_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Email"])};fn.source="Resend Email";return fn;})(),
      "return_signin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to sign in"])};fn.source="Return to sign in";return fn;})()
    },
    "verify": {
      "return_to_home": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Home"])};fn.source="Return to Home";return fn;})(),
      "verified_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email successfully verified."])};fn.source="Email successfully verified.";return fn;})()
    },
    "role": {
      "not_found": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role not found"])};fn.source="Role not found";return fn;})()
    }
  },
  "layout": {
    "navbar": {
      "dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])};fn.source="Dashboard";return fn;})(),
      "payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])};fn.source="Payments";return fn;})(),
      "balance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])};fn.source="Balances";return fn;})(),
      "customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])};fn.source="Customers";return fn;})(),
      "product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])};fn.source="Products";return fn;})(),
      "tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])};fn.source="Tax";return fn;})(),
      "report": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])};fn.source="Report";return fn;})(),
      "link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])};fn.source="Link";return fn;})(),
      "tutorial": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial"])};fn.source="Tutorial";return fn;})(),
      "integration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration"])};fn.source="Integration";return fn;})(),
      "test_mode": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Mode"])};fn.source="Test Mode";return fn;})(),
      "list_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Business"])};fn.source="List Business";return fn;})(),
      "list_user": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List User"])};fn.source="List User";return fn;})(),
      "list_payout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Payout"])};fn.source="List Payout";return fn;})(),
      "audit_trail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Trails"])};fn.source="Audit Trails";return fn;})(),
      "telescope": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Monitor"])};fn.source="System Monitor";return fn;})(),
      "cron": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cron Jobs"])};fn.source="Cron Jobs";return fn;})(),
      "settlement_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement"])};fn.source="Settlement";return fn;})(),
      "payment_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Links"])};fn.source="Payment Links";return fn;})(),
      "open_payment_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment Links"])};fn.source="Open Payment Links";return fn;})(),
      "subscription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])};fn.source="Subscriptions";return fn;})(),
      "setting": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})(),
      "more": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])};fn.source="More";return fn;})()
    },
    "header": {
      "invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])};fn.source="Invoice";return fn;})(),
      "subscription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])};fn.source="Subscription";return fn;})(),
      "payment_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])};fn.source="Payment Link";return fn;})(),
      "payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])};fn.source="Payment";return fn;})(),
      "edit_profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])};fn.source="Edit Profile";return fn;})(),
      "settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})(),
      "logout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])};fn.source="Logout";return fn;})(),
      "payment_online": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYMENT ONLINE"])};fn.source="PAYMENT ONLINE";return fn;})(),
      "activate_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Swipe Account"])};fn.source="Activate Swipe Account";return fn;})(),
      "account_under_review": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account under review"])};fn.source="Account under review";return fn;})(),
      "account_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account approved"])};fn.source="Account approved";return fn;})(),
      "account_rejected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account rejected"])};fn.source="Account rejected";return fn;})(),
      "account_amended": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please amend account information"])};fn.source="Please amend account information";return fn;})(),
      "complete_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete business information"])};fn.source="Complete business information";return fn;})(),
      "business_under_review": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business under review"])};fn.source="Business under review";return fn;})(),
      "business_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business approved"])};fn.source="Business approved";return fn;})(),
      "business_rejected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business rejected"])};fn.source="Business rejected";return fn;})(),
      "business_amended": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please amend business information"])};fn.source="Please amend business information";return fn;})(),
      "master_merchant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Merchant"])};fn.source="Master Merchant";return fn;})(),
      "administration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])};fn.source="Administration";return fn;})()
    }
  },
  "dashboard": {
    "report_overview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Overview"])};fn.source="Report Overview";return fn;})(),
    "today_summary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's Summary"])};fn.source="Today's Summary";return fn;})(),
    "gross_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Sales"])};fn.source="Gross Sales";return fn;})(),
    "balance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Balance"])};fn.source="Available Balance";return fn;})(),
    "payouts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Received"])};fn.source="Payout Received";return fn;})(),
    "total_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Sales"])};fn.source="Total Sales";return fn;})(),
    "net_volume": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Volume"])};fn.source="Net Volume";return fn;})(),
    "total_customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Of Customer"])};fn.source="No Of Customer";return fn;})(),
    "successful_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Payment"])};fn.source="Successful Payment";return fn;})(),
    "avg_spend_per_customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Spend Per Customer"])};fn.source="Average Spend Per Customer";return fn;})(),
    "verify_your_email_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your email address"])};fn.source="Verify your email address";return fn;})(),
    "check_email_for_verify": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before you get started, check <b>", _interpolate(_named("email")), "</b> for a link to verify your email."])};fn.source="Before you get started, check <b>{email}</b> for a link to verify your email.";return fn;})(),
    "didnt_get_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn’t get it? Check your spam folder or"])};fn.source="Didn’t get it? Check your spam folder or";return fn;})(),
    "add_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Business"])};fn.source="Add Business";return fn;})(),
    "add_new_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Business"])};fn.source="Add New Business";return fn;})(),
    "feature_access": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is disabled by your administrator"])};fn.source="This feature is disabled by your administrator";return fn;})(),
    "account": {
      "total_transaction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total transaction"])};fn.source="Total transaction";return fn;})(),
      "total_payout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payouts"])};fn.source="Total Payouts";return fn;})(),
      "no_payout_request": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Payouts Requested"])};fn.source="No. Payouts Requested";return fn;})(),
      "no_payout_approve": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Payouts Approved"])};fn.source="No. Payouts Approved";return fn;})(),
      "fpx": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FPX"])};fn.source="FPX";return fn;})(),
      "online_b2c": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking B2C"])};fn.source="Online Banking B2C";return fn;})(),
      "online_b2b": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking B2B"])};fn.source="Online Banking B2B";return fn;})(),
      "credit_card": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])};fn.source="Credit Card";return fn;})(),
      "total_gross_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Gross Sales"])};fn.source="Total Gross Sales";return fn;})(),
      "gross_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Sales"])};fn.source="Gross Sales";return fn;})(),
      "total_net_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Net Sales"])};fn.source="Total Net Sales";return fn;})(),
      "net_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Sales"])};fn.source="Net Sales";return fn;})(),
      "top_sales_collection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 10 Sales Collection"])};fn.source="Top 10 Sales Collection";return fn;})()
    },
    "operation": {
      "business_registered": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registered"])};fn.source="Business Registered";return fn;})(),
      "business_created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Created"])};fn.source="Business Created";return fn;})(),
      "business_pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Pending"])};fn.source="Business Pending";return fn;})(),
      "business_amended": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Amended"])};fn.source="Business Amended";return fn;})(),
      "business_rejected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rejected"])};fn.source="Business Rejected";return fn;})(),
      "business_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Approved"])};fn.source="Business Approved";return fn;})(),
      "summary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])};fn.source="Summary";return fn;})(),
      "payment_gateway": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Gateway"])};fn.source="Payment Gateway";return fn;})()
    },
    "master_business": {
      "business_registered": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registered"])};fn.source="Business Registered";return fn;})(),
      "business_created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Created"])};fn.source="Business Created";return fn;})(),
      "business_pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Pending"])};fn.source="Business Pending";return fn;})(),
      "business_amended": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Amended"])};fn.source="Business Amended";return fn;})(),
      "business_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Approved"])};fn.source="Business Approved";return fn;})(),
      "business_rejected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rejected"])};fn.source="Business Rejected";return fn;})(),
      "summary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master-Merchant's Business"])};fn.source="Master-Merchant's Business";return fn;})(),
      "summary_merchant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Merchant's Summary"])};fn.source="Sub-Merchant's Summary";return fn;})(),
      "available_balance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Balance"])};fn.source="Available Balance";return fn;})(),
      "ref_on_daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on daily balance"])};fn.source="Based on daily balance";return fn;})(),
      "gross_sale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Sales"])};fn.source="Gross Sales";return fn;})(),
      "net_volume": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Volume"])};fn.source="Net Volume";return fn;})(),
      "payout_received": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Received"])};fn.source="Payout Received";return fn;})()
    }
  },
  "business": {
    "main": {
      "structure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business structure"])};fn.source="Business structure";return fn;})(),
      "details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business details"])};fn.source="Business details";return fn;})(),
      "representative": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business representative"])};fn.source="Business representative";return fn;})(),
      "owners": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business owners"])};fn.source="Business owners";return fn;})(),
      "support_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support details"])};fn.source="Support details";return fn;})(),
      "support_documents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support documents"])};fn.source="Support documents";return fn;})(),
      "bank": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])};fn.source="Bank details";return fn;})(),
      "comments_reasons": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments/Reasons:"])};fn.source="Comments/Reasons:";return fn;})(),
      "business_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business List"])};fn.source="Business List";return fn;})(),
      "create_new_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new business"])};fn.source="Create new business";return fn;})(),
      "upgrade_success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business upgraded to master merchant!"])};fn.source="Business upgraded to master merchant!";return fn;})(),
      "upgrade_failed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrading business failed!"])};fn.source="Upgrading business failed!";return fn;})(),
      "upgrade_failed_reason": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrading business failed. Reason:"])};fn.source="Upgrading business failed. Reason:";return fn;})(),
      "business_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact support to make any changes."])};fn.source="Please contact support to make any changes.";return fn;})()
    },
    "structure": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About your business"])};fn.source="About your business";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We collect information about your company to guide us meet the standards of regulators, financial partners, and our services agreement."])};fn.source="We collect information about your company to guide us meet the standards of regulators, financial partners, and our services agreement.";return fn;})(),
      "create_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])};fn.source="Create Account";return fn;})(),
      "address_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use home address if you don't have any business address."])};fn.source="You can use home address if you don't have any business address.";return fn;})(),
      "country_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered business country"])};fn.source="Registered business country";return fn;})(),
      "info_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Name"])};fn.source="Brand Name";return fn;})(),
      "info_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This name will used as a display name"])};fn.source="This name will used as a display name";return fn;})(),
      "reg_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration No"])};fn.source="Registration No";return fn;})(),
      "address_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business address"])};fn.source="Business address";return fn;})(),
      "type_of_business_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of business"])};fn.source="Type of business";return fn;})()
    },
    "detail": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some details on your business"])};fn.source="Some details on your business";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us to understand your business in better ways."])};fn.source="Help us to understand your business in better ways.";return fn;})(),
      "company_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])};fn.source="Company name";return fn;})(),
      "company_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])};fn.source="Company";return fn;})(),
      "company_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])};fn.source="Company name";return fn;})(),
      "company_reg_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company registration no"])};fn.source="Company registration no";return fn;})(),
      "tax_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax identification number"])};fn.source="Tax identification number";return fn;})(),
      "company_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure it’s exactly match as your associated SSM certificate"])};fn.source="Please make sure it’s exactly match as your associated SSM certificate";return fn;})(),
      "company_reg_no_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company number (MyCoID)"])};fn.source="Company number (MyCoID)";return fn;})(),
      "company_reg_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456-A"])};fn.source="123456-A";return fn;})(),
      "company_location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company location"])};fn.source="Company location";return fn;})(),
      "activity_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business activity (optional)"])};fn.source="Business activity (optional)";return fn;})(),
      "activity_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business activity"])};fn.source="Business activity";return fn;})(),
      "address_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company address"])};fn.source="Company address";return fn;})(),
      "phone_no_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company phone number"])};fn.source="Company phone number";return fn;})(),
      "industry_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])};fn.source="Industry";return fn;})(),
      "industry_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your industry.."])};fn.source="Select your industry..";return fn;})(),
      "annual_turnover_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select annual turnover.."])};fn.source="Select annual turnover..";return fn;})(),
      "website_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company website / Social media"])};fn.source="Company website / Social media";return fn;})(),
      "website_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can put your business social media profile or your app store link if you dont have a website"])};fn.source="You can put your business social media profile or your app store link if you dont have a website";return fn;})(),
      "website_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://yourwebsite.com"])};fn.source="https://yourwebsite.com";return fn;})(),
      "product_desc_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product description"])};fn.source="Product description";return fn;})(),
      "product_desc_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly explain your product or services. This will help us to understand and process your application quickly."])};fn.source="Briefly explain your product or services. This will help us to understand and process your application quickly.";return fn;})(),
      "product_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly explain your product or services. This will help us to understand and process your application quickly."])};fn.source="Briefly explain your product or services. This will help us to understand and process your application quickly.";return fn;})()
    },
    "representative": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Details"])};fn.source="Personal Details";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account must be activated by an admin, finance, or other person with significant responsibility for the control and management of your business. If that isn't you, please have the appropriate person fill out this form."])};fn.source="This account must be activated by an admin, finance, or other person with significant responsibility for the control and management of your business. If that isn't you, please have the appropriate person fill out this form.";return fn;})(),
      "legal_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal name of person"])};fn.source="Legal name of person";return fn;})(),
      "designation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])};fn.source="Designation";return fn;})(),
      "job_position_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job position"])};fn.source="Job position";return fn;})(),
      "first_name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])};fn.source="First name";return fn;})(),
      "last_name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])};fn.source="Last name";return fn;})(),
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
      "date_of_birth": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])};fn.source="Date of birth";return fn;})(),
      "date_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd / mm / yyyy"])};fn.source="dd / mm / yyyy";return fn;})(),
      "home_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Address"])};fn.source="Home Address";return fn;})(),
      "address_placeholder_1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 1"])};fn.source="Address line 1";return fn;})(),
      "address_placeholder_2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 2"])};fn.source="Address line 2";return fn;})(),
      "postcode_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])};fn.source="Postal Code";return fn;})(),
      "city_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])};fn.source="City";return fn;})(),
      "phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "mykad": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC (MyKad)"])};fn.source="NRIC (MyKad)";return fn;})(),
      "passport": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport No."])};fn.source="Passport No.";return fn;})()
    },
    "owner": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Shareholders"])};fn.source="Add Shareholders";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are required by regulatory guidelines to collect information about the directors in your company."])};fn.source="We are required by regulatory guidelines to collect information about the directors in your company.";return fn;})(),
      "add_business_shareholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add business shareholder"])};fn.source="Add business shareholder";return fn;})(),
      "add_another_owner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another owner"])};fn.source="Add another owner";return fn;})(),
      "continue_with_no_shareholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with no shareholders"])};fn.source="Continue with no shareholders";return fn;})(),
      "label_name_of_person": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal name of person"])};fn.source="Legal name of person";return fn;})(),
      "label_identification": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])};fn.source="Identification";return fn;})(),
      "label_phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "label_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])};fn.source="Email Address";return fn;})(),
      "first_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])};fn.source="First Name";return fn;})(),
      "last_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])};fn.source="Last Name";return fn;})(),
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})()
    },
    "support_detail": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Details for Customer"])};fn.source="Support Details for Customer";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information assists customers in recognising your company."])};fn.source="This information assists customers in recognising your company.";return fn;})(),
      "business_name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your business name"])};fn.source="Your business name";return fn;})(),
      "statement_descriptor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement descriptor"])};fn.source="Statement descriptor";return fn;})(),
      "statement_descriptor_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A statement descriptor is the business name a customer will see on their card statements. Choose a name that’s at least five characters and easy to recognise to avoid potential chargebacks."])};fn.source="A statement descriptor is the business name a customer will see on their card statements. Choose a name that’s at least five characters and easy to recognise to avoid potential chargebacks.";return fn;})(),
      "business_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])};fn.source="Business";return fn;})(),
      "shortened_descriptor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortened descriptor"])};fn.source="Shortened descriptor";return fn;})(),
      "shortened_descriptor_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortened descriptors can be paired with specific details about a charge."])};fn.source="Shortened descriptors can be paired with specific details about a charge.";return fn;})(),
      "phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "support_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support email"])};fn.source="Support email";return fn;})(),
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})()
    },
    "bank_details": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account for payouts"])};fn.source="Bank account for payouts";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A payout is the transfer of funds from Swipe to your bank account."])};fn.source="A payout is the transfer of funds from Swipe to your bank account.";return fn;})(),
      "account_number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])};fn.source="Account number";return fn;})(),
      "account_holder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder name"])};fn.source="Account holder name";return fn;})()
    },
    "support_documents": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting Documents"])};fn.source="Supporting Documents";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are required to upload the below documents to activate your payment method."])};fn.source="You are required to upload the below documents to activate your payment method.";return fn;})(),
      "description_no_documents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already uploaded all required documents for verifications."])};fn.source="You already uploaded all required documents for verifications.";return fn;})(),
      "description_documents_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your documents are approved. Please contact support to make any changes."])};fn.source="Your documents are approved. Please contact support to make any changes.";return fn;})(),
      "delete_confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["are you sure to delete this file?"])};fn.source="are you sure to delete this file?";return fn;})(),
      "max_file": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum files is ", _interpolate(_named("max"))])};fn.source="Maximum files is {max}";return fn;})(),
      "click_or_drag_file": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click or drag file to this area to upload"])};fn.source="Click or drag file to this area to upload";return fn;})(),
      "upload_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload .PDF, .JPG, .PNG Format not more than 10MB. You can upload multiple files at one time"])};fn.source="You can upload .PDF, .JPG, .PNG Format not more than 10MB. You can upload multiple files at one time";return fn;})(),
      "preferred_payment_method": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Payment Method :"])};fn.source="Preferred Payment Method :";return fn;})(),
      "unsupported_file_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported file type"])};fn.source="Unsupported file type";return fn;})(),
      "terms_and_conditions": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By submitting this form, you agree to <a href='", _interpolate(_named("url")), "' target='_blank'>Terms and Conditions</a> provided by the payment provider."])};fn.source="By submitting this form, you agree to <a href='{url}' target='_blank'>Terms and Conditions</a> provided by the payment provider.";return fn;})(),
      "payment_method_application": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method Application"])};fn.source="Payment Method Application";return fn;})(),
      "payment_method_application_fpx": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method Application: FPX"])};fn.source="Payment Method Application: FPX";return fn;})(),
      "payment_method_application_cc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method Application: Credit Card (Optional)"])};fn.source="Payment Method Application: Credit Card (Optional)";return fn;})(),
      "label": {
        "Director_signatory_ic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director NRIC"])};fn.source="Director NRIC";return fn;})(),
        "Director_signatory_ic_cc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director’s Signatory NRIC"])};fn.source="Director’s Signatory NRIC";return fn;})(),
        "bank_statement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Bank Statement"])};fn.source="Company Bank Statement";return fn;})(),
        "ssm_llp_certificate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Limited Liability Partnership (LLP) Certificate"])};fn.source="SSM Limited Liability Partnership (LLP) Certificate";return fn;})(),
        "form_a": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form A"])};fn.source="SSM Form A";return fn;})(),
        "form_b": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form B"])};fn.source="SSM Form B";return fn;})(),
        "document_ssm_form_D": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form D"])};fn.source="SSM Form D";return fn;})(),
        "document_ssm_form_24": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form 24 / Form 51 & 78"])};fn.source="SSM Form 24 / Form 51 & 78";return fn;})(),
        "document_ssm_form_49": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form 49 / Form 58"])};fn.source="SSM Form 49 / Form 58";return fn;})(),
        "document_ssm_form_44": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form 44 / Form 46"])};fn.source="SSM Form 44 / Form 46";return fn;})(),
        "form_m&a": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form M&A"])};fn.source="SSM Form M&A";return fn;})(),
        "document_business_registration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Business Registration"])};fn.source="Document Business Registration";return fn;})(),
        "document_local_concil": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Council Trading License"])};fn.source="Local Council Trading License";return fn;})(),
        "utility_bills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utility Bills"])};fn.source="Utility Bills";return fn;})(),
        "signboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Photo(s) of Business Signboard "])};fn.source=" Photo(s) of Business Signboard ";return fn;})(),
        "premises": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo(s) of Premises"])};fn.source="Photo(s) of Premises";return fn;})(),
        "display_of_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo(s) of Products"])};fn.source="Photo(s) of Products";return fn;})(),
        "equipment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo(s) of Equipment"])};fn.source="Photo(s) of Equipment";return fn;})(),
        "director_signature": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director Signature"])};fn.source="Director Signature";return fn;})(),
        "company_stamp": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Stamp"])};fn.source="Company Stamp";return fn;})(),
        "complete_ssm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete SSM / E-SSM"])};fn.source="Complete SSM / E-SSM";return fn;})(),
        "document_representative_ic_copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative Identification Card"])};fn.source="Representative Identification Card";return fn;})()
      },
      "label_description": {
        "Director_signatory_ic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Director (Front)"])};fn.source="Please provide NRIC of one Company Director (Front)";return fn;})(),
        "Director_signatory_ic_back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Director (Back)"])};fn.source="Please provide NRIC of one Company Director (Back)";return fn;})(),
        "document_representative_ic_copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Representative (Front)"])};fn.source="Please provide NRIC of one Company Representative (Front)";return fn;})(),
        "document_representative_ic_back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Representative (Back)"])};fn.source="Please provide NRIC of one Company Representative (Back)";return fn;})(),
        "bank_statement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide the latest 3 month bank statement"])};fn.source="Please provide the latest 3 month bank statement";return fn;})(),
        "form_a": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form A"])};fn.source="Please provide SSM Form A";return fn;})(),
        "form_b": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form B"])};fn.source="Please provide SSM Form B";return fn;})(),
        "form_d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form D"])};fn.source="Please provide SSM Form D";return fn;})(),
        "form_m&a": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form M&A"])};fn.source="Please provide SSM Form M&A";return fn;})(),
        "document_local_concil": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide relevant licence (eg: DBKL, MBPJ, MPAJ, MBJB, MPSJ, MPK etc)"])};fn.source="Please provide relevant licence (eg: DBKL, MBPJ, MPAJ, MBJB, MPSJ, MPK etc)";return fn;})(),
        "utility_bills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide bills that match company name & address in the SSM and/or bank statement"])};fn.source="Please provide bills that match company name & address in the SSM and/or bank statement";return fn;})(),
        "signboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Please provide at least one photo clearly showing your Business Registration Number"])};fn.source=" Please provide at least one photo clearly showing your Business Registration Number";return fn;})(),
        "premises": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide at least one photo clearly showing an overview of your office"])};fn.source="Please provide at least one photo clearly showing an overview of your office";return fn;})(),
        "display_of_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide at least one photo clearly showing the equipment uses for the products"])};fn.source="Please provide at least one photo clearly showing the equipment uses for the products";return fn;})(),
        "equipment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide at least one photo clearly showing the equipment uses for the products"])};fn.source="Please provide at least one photo clearly showing the equipment uses for the products";return fn;})(),
        "director_signature": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide digital signature of the above Company Director"])};fn.source="Please provide digital signature of the above Company Director";return fn;})(),
        "company_stamp": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide digital company stamp"])};fn.source="Please provide digital company stamp";return fn;})(),
        "document_ssm_form_24": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form 24 / Form 51 & 78"])};fn.source="Please provide SSM Form 24 / Form 51 & 78";return fn;})(),
        "document_ssm_form_49": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form 49 / Form 58"])};fn.source="Please provide SSM Form 49 / Form 58";return fn;})(),
        "document_ssm_form_44": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form 44 / Form 46"])};fn.source="Please provide SSM Form 44 / Form 46";return fn;})(),
        "ssm_llp_certificate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide(LLP Certificate) / Full LLP Certificate"])};fn.source="Please provide(LLP Certificate) / Full LLP Certificate";return fn;})(),
        "complete_ssm": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please provide complete SSM / E-SSM. Click here to view example <a href='", _interpolate(_named("url")), "' target='_blank'>Support Document</a>"])};fn.source="Please provide complete SSM / E-SSM. Click here to view example <a href='{url}' target='_blank'>Support Document</a>";return fn;})()
      }
    },
    "summary": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's Review your details"])};fn.source="Let's Review your details";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One step left and you’re almost ready to get started with Swipe. Please double-check all the information."])};fn.source="One step left and you’re almost ready to get started with Swipe. Please double-check all the information.";return fn;})(),
      "agreement_description_1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By submitting this form, you agree to"])};fn.source="By submitting this form, you agree to";return fn;})(),
      "agreement_description_2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" provided by Swipe, to receive the text message from Swipe and you certify that the information provided is complete and correct."])};fn.source=" provided by Swipe, to receive the text message from Swipe and you certify that the information provided is complete and correct.";return fn;})(),
      "swipe_agreement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Terms and Conditions"])};fn.source=" Terms and Conditions";return fn;})(),
      "detail_company_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company number"])};fn.source="Company number";return fn;})(),
      "detail_activity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business activity"])};fn.source="Business activity";return fn;})(),
      "detail_company_annual_turnover": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company annual turnover"])};fn.source="Company annual turnover";return fn;})(),
      "missing_required_business_information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing required business information"])};fn.source="Missing required business information";return fn;})(),
      "business_submitted": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business has been Submitted. Confirmation email will be sent to the merchant shortly."])};fn.source="Business has been Submitted. Confirmation email will be sent to the merchant shortly.";return fn;})()
    },
    "table": {
      "header_businss_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])};fn.source="Business name";return fn;})(),
      "header_businss_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business type"])};fn.source="Business type";return fn;})(),
      "header_merchant_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant type"])};fn.source="Merchant type";return fn;})(),
      "header_industry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])};fn.source="Industry";return fn;})(),
      "header_country": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])};fn.source="Country";return fn;})(),
      "header_submitted_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted date"])};fn.source="Submitted date";return fn;})(),
      "header_status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "tab_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])};fn.source="All";return fn;})(),
      "tab_to_review": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Review"])};fn.source="To Review";return fn;})(),
      "tab_created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])};fn.source="Created";return fn;})(),
      "tab_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])};fn.source="Approved";return fn;})(),
      "tab_rejected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])};fn.source="Rejected";return fn;})(),
      "tab_amended": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amended"])};fn.source="Amended";return fn;})()
    },
    "modal": {
      "approve_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve this business?"])};fn.source="Approve this business?";return fn;})(),
      "approve_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign payment provider for this business after approval."])};fn.source="Assign payment provider for this business after approval.";return fn;})(),
      "approve_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve business"])};fn.source="Approve business";return fn;})(),
      "amend_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amend this business?"])};fn.source="Amend this business?";return fn;})(),
      "amend_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state reasons why this business need amendments"])};fn.source="Please state reasons why this business need amendments";return fn;})(),
      "amend_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])};fn.source="Submit";return fn;})(),
      "decline_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline this business?"])};fn.source="Decline this business?";return fn;})(),
      "decline_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state reasons why this business is declined"])};fn.source="Please state reasons why this business is declined";return fn;})(),
      "decline_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline business"])};fn.source="Decline business";return fn;})(),
      "upgrade_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade this business?"])};fn.source="Upgrade this business?";return fn;})(),
      "upgrade_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade this business to become master merchant."])};fn.source="Upgrade this business to become master merchant.";return fn;})(),
      "upgrade_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade business"])};fn.source="Upgrade business";return fn;})(),
      "create_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create business account first"])};fn.source="Create business account first";return fn;})(),
      "create_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can try our features in the test mode but first you need to create at least 1 business account."])};fn.source="You can try our features in the test mode but first you need to create at least 1 business account.";return fn;})(),
      "create_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Business"])};fn.source="Create Business";return fn;})(),
      "go_test_mode": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good to go for test mode!"])};fn.source="Good to go for test mode!";return fn;})(),
      "go_test_mode_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your test mode are enabled. You can continue register your business informations or go to dashbaord to try in the test mode."])};fn.source="Your test mode are enabled. You can continue register your business informations or go to dashbaord to try in the test mode.";return fn;})(),
      "continue_register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Registration"])};fn.source="Continue Registration";return fn;})(),
      "go_dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Dashboard"])};fn.source="Go to Dashboard";return fn;})()
    },
    "info": {
      "details_menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details Menu"])};fn.source="Details Menu";return fn;})(),
      "information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])};fn.source="Information";return fn;})(),
      "edit_information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Information"])};fn.source="Edit Information";return fn;})(),
      "cancel_edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel editing"])};fn.source="Cancel editing";return fn;})(),
      "approve": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve business"])};fn.source="Approve business";return fn;})(),
      "amend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amend business"])};fn.source="Amend business";return fn;})(),
      "decline": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline business"])};fn.source="Decline business";return fn;})(),
      "upgrade": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade business"])};fn.source="Upgrade business";return fn;})()
    },
    "company_logo": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])};fn.source="Branding";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize how your brand appears globally across the Swipe products your customers use."])};fn.source="Customize how your brand appears globally across the Swipe products your customers use.";return fn;})(),
      "label_icon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A smaller version of your logo like favicon or apps icon logo. This will appear on your business account list"])};fn.source="A smaller version of your logo like favicon or apps icon logo. This will appear on your business account list";return fn;})(),
      "label_logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full size version of your logo. This will appear in your invoice and payment links"])};fn.source="Full size version of your logo. This will appear in your invoice and payment links";return fn;})(),
      "upload_logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload logo"])};fn.source="Upload logo";return fn;})(),
      "label_merchant_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<merchant name>"])};fn.source="<merchant name>";return fn;})(),
      "logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])};fn.source="Logo";return fn;})(),
      "icon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])};fn.source="Icon";return fn;})()
    },
    "payment_provider": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider"])};fn.source="Payment Provider";return fn;})()
    },
    "copy": {
      "suggestion_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s make it easy!"])};fn.source="Let’s make it easy!";return fn;})(),
      "suggestion_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to copy business details from your other businesses? You can choose which section to copy"])};fn.source="Would you like to copy business details from your other businesses? You can choose which section to copy";return fn;})(),
      "suggestion_button_yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Please"])};fn.source="Yes, Please";return fn;})(),
      "suggestion_button_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Thank you"])};fn.source="No, Thank you";return fn;})(),
      "select_business_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy From Existing Business"])};fn.source="Copy From Existing Business";return fn;})(),
      "select_business_button_yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Please"])};fn.source="Yes, Please";return fn;})(),
      "select_business_button_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Thank you"])};fn.source="No, Thank you";return fn;})(),
      "info_business_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy From:"])};fn.source="Copy From:";return fn;})(),
      "loading_text": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please wait while we pasting details from ", _interpolate(_named("business_name"))])};fn.source="Please wait while we pasting details from {business_name}";return fn;})()
    },
    "settings": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Settings"])};fn.source="Business Settings";return fn;})(),
      "payout_option_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Options"])};fn.source="Payout Options";return fn;})(),
      "payout_option_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose between automatically or on demand payout to your bank account"])};fn.source="Choose between automatically or on demand payout to your bank account";return fn;})(),
      "reset_default_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to default"])};fn.source="Reset to default";return fn;})(),
      "payout_auto": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically daily"])};fn.source="Automatically daily";return fn;})(),
      "payout_on_demand": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On demand payout - We will process your payout once we received your request."])};fn.source="On demand payout - We will process your payout once we received your request.";return fn;})(),
      "payout_to_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose method of payout for your Sub Merchant account"])};fn.source="Choose method of payout for your Sub Merchant account";return fn;})(),
      "payout_to_sub_merchant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout to Sub Merchant account"])};fn.source="Payout to Sub Merchant account";return fn;})(),
      "payout_to_master": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout to Master Merchant account"])};fn.source="Payout to Master Merchant account";return fn;})()
    }
  },
  "payment": {
    "menu": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Menu"])};fn.source="Payments Menu";return fn;})(),
      "links": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Links"])};fn.source="Payment Links";return fn;})(),
      "link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])};fn.source="Payment Link";return fn;})(),
      "open_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment Link"])};fn.source="Open Payment Link";return fn;})(),
      "create_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Payments"])};fn.source="Create Payments";return fn;})(),
      "payment_gateway": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])};fn.source="Payment gateway";return fn;})(),
      "payment_method": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])};fn.source="Payment method";return fn;})(),
      "payment_channel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment channel"])};fn.source="Payment channel";return fn;})(),
      "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})(),
      "approved_payment_gateway": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved payment gateway"])};fn.source="Approved payment gateway";return fn;})(),
      "under_review": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under review/process"])};fn.source="Under review/process";return fn;})(),
      "fixed_charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed charges"])};fn.source="Fixed charges";return fn;})(),
      "percentage_charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage charges"])};fn.source="Percentage charges";return fn;})(),
      "charges_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges type"])};fn.source="Charges type";return fn;})(),
      "fixed_min_charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum charges"])};fn.source="Minimum charges";return fn;})(),
      "percentage_min_charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum charges"])};fn.source="Minimum charges";return fn;})(),
      "min_charges_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum charges type"])};fn.source="Minimum charges type";return fn;})(),
      "add_payment_gateway": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Payment Gateway"])};fn.source="Add Payment Gateway";return fn;})(),
      "fixed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])};fn.source="Fixed";return fn;})(),
      "percentage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])};fn.source="Percentage";return fn;})(),
      "mixed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed"])};fn.source="Mixed";return fn;})(),
      "enable_payment_gateway": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Payment Gateway"])};fn.source="Enable Payment Gateway";return fn;})(),
      "template": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template:"])};fn.source="Template:";return fn;})(),
      "settlement_day": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Day"])};fn.source="Processing Day";return fn;})(),
      "charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges"])};fn.source="Charges";return fn;})(),
      "configs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configs"])};fn.source="Configs";return fn;})(),
      "open_payment_links": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment Links"])};fn.source="Open Payment Links";return fn;})(),
      "create_open_payment_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Payment Link"])};fn.source="Create Open Payment Link";return fn;})(),
      "stripe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])};fn.source="Stripe";return fn;})()
    },
    "create": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Payment Link"])};fn.source="Create Payment Link";return fn;})(),
      "email_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
      "email_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address of customer"])};fn.source="Email address of customer";return fn;})(),
      "phone_no_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number (Optional)"])};fn.source="Phone number (Optional)";return fn;})(),
      "phone_no_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payer phone no (optional)"])};fn.source="payer phone no (optional)";return fn;})(),
      "title_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment title"])};fn.source="Payment title";return fn;})(),
      "title_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of the payment "])};fn.source="Purpose of the payment ";return fn;})(),
      "amount_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])};fn.source="Amount to pay";return fn;})(),
      "amount_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.00"])};fn.source="0.00";return fn;})(),
      "desc_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of payment (Optional)"])};fn.source="Description of payment (Optional)";return fn;})(),
      "desc_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the payment in detail"])};fn.source="Describe the payment in detail";return fn;})(),
      "ref1_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 1 (Optional)"])};fn.source="Reference note 1 (Optional)";return fn;})(),
      "ref1_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for customer reference"])};fn.source="Note for customer reference";return fn;})(),
      "ref2_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 2 (Optional)"])};fn.source="Reference note 2 (Optional)";return fn;})(),
      "ref2_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for customer reference"])};fn.source="Note for customer reference";return fn;})(),
      "redirect_url_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URL (Optional)"])};fn.source="Redirect URL (Optional)";return fn;})(),
      "redirect_url_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect payment URL"])};fn.source="Redirect payment URL";return fn;})(),
      "send_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send this payment link to customer's email"])};fn.source="Send this payment link to customer's email";return fn;})(),
      "preview_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])};fn.source="Preview";return fn;})(),
      "preview_hide": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide preview"])};fn.source="Hide preview";return fn;})(),
      "preview_show": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show preview"])};fn.source="Show preview";return fn;})(),
      "preview_tab_payment_page": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment page"])};fn.source="Payment page";return fn;})(),
      "preview_tab_email_page": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
      "select_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Business"])};fn.source="Select Business";return fn;})(),
      "max_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum amount ", _interpolate(_named("max_amount"))])};fn.source="Maximum amount {max_amount}";return fn;})(),
      "min_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum amount ", _interpolate(_named("min_amount"))])};fn.source="Minimum amount {min_amount}";return fn;})()
    },
    "link": {
      "phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "paid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAID"])};fn.source="PAID";return fn;})(),
      "amount_to_pay": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])};fn.source="Amount to pay";return fn;})(),
      "new_payment_request": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have received a new payment request from ", _interpolate(_named("business_name")), "!"])};fn.source="You have received a new payment request from {business_name}!";return fn;})(),
      "payment_method": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])};fn.source="Payment method";return fn;})(),
      "card": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])};fn.source="Card";return fn;})(),
      "online_banking": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online banking"])};fn.source="Online banking";return fn;})(),
      "select_bank": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bank"])};fn.source="Select bank";return fn;})(),
      "card_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card information"])};fn.source="Card information";return fn;})(),
      "card_name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Holder Name"])};fn.source="Card Holder Name";return fn;})(),
      "card_no_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0000 0000 0000 0000"])};fn.source="0000 0000 0000 0000";return fn;})(),
      "card_expiry_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM/YY"])};fn.source="MM/YY";return fn;})(),
      "card_ccv_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])};fn.source="CVV";return fn;})(),
      "proceed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed payment"])};fn.source="Proceed payment";return fn;})(),
      "ref1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 1"])};fn.source="Reference note 1";return fn;})(),
      "ref2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 2"])};fn.source="Reference note 2";return fn;})(),
      "copy_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link has been copied"])};fn.source="Link has been copied";return fn;})(),
      "resend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is being sent"])};fn.source="Email is being sent";return fn;})(),
      "requery": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Payment attempt: ", _interpolate(_named("attempt_id")), " has been lined up for requery, This process might take to few minutes."])};fn.source="Payment attempt: {attempt_id} has been lined up for requery, This process might take to few minutes.";return fn;})(),
      "empty": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment link created"])};fn.source="No payment link created";return fn;})(),
        "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get paid by someone with payment link. Ready to generate revenue?"])};fn.source="Get paid by someone with payment link. Ready to generate revenue?";return fn;})(),
        "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Payment Links"])};fn.source="Create Payment Links";return fn;})()
      },
      "filter": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])};fn.source="Title";return fn;})(),
        "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
        "currency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])};fn.source="Currency";return fn;})(),
        "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
        "payment_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])};fn.source="Payment Link";return fn;})(),
        "payment_link_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link Id"])};fn.source="Payment Link Id";return fn;})(),
        "payment_attempt_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Attempt Id"])};fn.source="Payment Attempt Id";return fn;})(),
        "payment_status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])};fn.source="Payment Status";return fn;})(),
        "settlement_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Id"])};fn.source="Processed Id";return fn;})(),
        "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])};fn.source="Date";return fn;})(),
        "date_created_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])};fn.source="Created At";return fn;})()
      },
      "header": {
        "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
        "charge_fees": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Fees"])};fn.source="Charge Fees";return fn;})(),
        "net_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])};fn.source="Net Amount";return fn;})(),
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])};fn.source="Title";return fn;})(),
        "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
        "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})(),
        "payment_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])};fn.source="Payment Link";return fn;})()
      },
      "attempt_status": {
        "created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempted"])};fn.source="Attempted";return fn;})(),
        "pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])};fn.source="Pending";return fn;})(),
        "receipt": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached Thank You Page"])};fn.source="Reached Thank You Page";return fn;})(),
        "paid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])};fn.source="Paid";return fn;})(),
        "failed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])};fn.source="Failed";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])};fn.source="Error";return fn;})(),
        "unknown": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned"])};fn.source="Abandoned";return fn;})(),
        "requires_action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires Action"])};fn.source="Requires Action";return fn;})()
      },
      "payment_methods": {
        "1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking"])};fn.source="Online Banking";return fn;})(),
        "2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking (Business)"])};fn.source="Online Banking (Business)";return fn;})(),
        "3": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit / Debit Card"])};fn.source="Credit / Debit Card";return fn;})(),
        "4": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit / Debit Card (Tokenization)"])};fn.source="Credit / Debit Card (Tokenization)";return fn;})()
      },
      "fpx_terms_word": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on the \"Proceed Payment\" button below, you are agreeing to the "])};fn.source="By clicking on the \"Proceed Payment\" button below, you are agreeing to the ";return fn;})(),
      "fpx_terms_conditions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FPX's Terms and Conditions"])};fn.source="FPX's Terms and Conditions";return fn;})()
    },
    "open-payment-link": {
      "header": {
        "minimum_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount"])};fn.source="Minimum Amount";return fn;})(),
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])};fn.source="Title";return fn;})(),
        "desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
        "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})(),
        "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})()
      },
      "status": {
        "1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])};fn.source="Active";return fn;})(),
        "2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])};fn.source="Deactivate";return fn;})()
      },
      "filter": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])};fn.source="Title";return fn;})(),
        "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
        "currency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])};fn.source="Currency";return fn;})(),
        "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
        "minimum_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount"])};fn.source="Minimum Amount";return fn;})(),
        "desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
        "open_payment_link_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment Link Id"])};fn.source="Open Payment Link Id";return fn;})(),
        "payment_link_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link Id"])};fn.source="Payment Link Id";return fn;})(),
        "payment_attempt_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Attempt Id"])};fn.source="Payment Attempt Id";return fn;})(),
        "payment_status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])};fn.source="Payment Status";return fn;})(),
        "settlement_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement Id"])};fn.source="Settlement Id";return fn;})(),
        "date_created_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])};fn.source="Created At";return fn;})()
      },
      "form": {
        "form_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Payment Link"])};fn.source="Create Open Payment Link";return fn;})(),
        "edit_form_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Open Payment Link"])};fn.source="Edit Open Payment Link";return fn;})(),
        "minimum_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount"])};fn.source="Minimum Amount";return fn;})(),
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment title"])};fn.source="Payment title";return fn;})(),
        "desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
        "declaration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration (Optional):"])};fn.source="Declaration (Optional):";return fn;})(),
        "declaration_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe declaration in detail"])};fn.source="Describe declaration in detail";return fn;})(),
        "banner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner (Optional)"])};fn.source="Banner (Optional)";return fn;})(),
        "ref1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 1 (Optional)"])};fn.source="Reference note 1 (Optional)";return fn;})(),
        "ref2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 2 (Optional)"])};fn.source="Reference note 2 (Optional)";return fn;})(),
        "ref1_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for payment"])};fn.source="Note for payment";return fn;})(),
        "ref2_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for payment"])};fn.source="Note for payment";return fn;})(),
        "redirect_url": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URL (Optional)"])};fn.source="Redirect URL (Optional)";return fn;})(),
        "redirect_url_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment redirect URL"])};fn.source="Payment redirect URL";return fn;})(),
        "button_save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])};fn.source="Save changes";return fn;})(),
        "edit_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Details"])};fn.source="Edit Details";return fn;})(),
        "button_create": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Payment Link"])};fn.source="Create Open Payment Link";return fn;})()
      },
      "payment_page": {
        "min_amount_pay": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount:"])};fn.source="Minimum Amount:";return fn;})(),
        "amount_to_pay": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay:"])};fn.source="Amount to pay:";return fn;})(),
        "payment_purpose": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose:"])};fn.source="Payment Purpose:";return fn;})(),
        "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])};fn.source="Description:";return fn;})(),
        "email_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
        "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (Optional)"])};fn.source="Name (Optional)";return fn;})(),
        "phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number (Optional)"])};fn.source="Phone number (Optional)";return fn;})(),
        "payment_for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment For:"])};fn.source="Payment For:";return fn;})(),
        "link_invalid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment link is invalid."])};fn.source="The payment link is invalid.";return fn;})(),
        "link_inactive": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment link is inactive."])};fn.source="The payment link is inactive.";return fn;})(),
        "link_inactive_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like the payment link you are looking for is  deactivated by the owner."])};fn.source="Looks like the payment link you are looking for is  deactivated by the owner.";return fn;})(),
        "link_inactive_support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there any issues with the link, Please email us at"])};fn.source="If there any issues with the link, Please email us at";return fn;})(),
        "accept_declaration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hereby acknowledge that I have read and understood the <a class='cursor-pointer'>declaration</a> as provided."])};fn.source="I hereby acknowledge that I have read and understood the <a class='cursor-pointer'>declaration</a> as provided.";return fn;})(),
        "modal_declaration_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration"])};fn.source="Declaration";return fn;})()
      },
      "qrcode-page": {
        "qr_code": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])};fn.source="QR Code";return fn;})(),
        "scan_to_pay": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCAN TO PAY"])};fn.source="SCAN TO PAY";return fn;})(),
        "download_image": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Image"])};fn.source="Download Image";return fn;})()
      },
      "modal": {
        "activate_deactivate": {
          "positive_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Open Payment Link?"])};fn.source="Activate Open Payment Link?";return fn;})(),
          "positive_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This payment will be visible to anyone who has the links."])};fn.source="This payment will be visible to anyone who has the links.";return fn;})(),
          "positive_action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])};fn.source="Activate";return fn;})(),
          "negative_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate open payment link?"])};fn.source="Deactivate open payment link?";return fn;})(),
          "negative_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyone with the link will not able to access."])};fn.source="Anyone with the link will not able to access.";return fn;})(),
          "negative_action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])};fn.source="Deactivate";return fn;})(),
          "skip_prompt": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't ask again for this session"])};fn.source="Don't ask again for this session";return fn;})()
        },
        "details": {
          "modal_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open payment link details"])};fn.source="Open payment link details";return fn;})(),
          "view_payment_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Payment Details"])};fn.source="View Payment Details";return fn;})(),
          "payment_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])};fn.source="Payment Details";return fn;})(),
          "business_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name:"])};fn.source="Business Name:";return fn;})(),
          "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title:"])};fn.source="Title:";return fn;})(),
          "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])};fn.source="Description:";return fn;})(),
          "minimum_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Amount:"])};fn.source="Min. Amount:";return fn;})(),
          "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])};fn.source="Status:";return fn;})(),
          "link_opened": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Opened"])};fn.source="Link Opened";return fn;})(),
          "successful_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Payment"])};fn.source="Successful Payment";return fn;})(),
          "unsuccessful_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsuccessful Payment"])};fn.source="Unsuccessful Payment";return fn;})(),
          "pending_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Payment"])};fn.source="Pending Payment";return fn;})()
        }
      },
      "detail-page": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment: "])};fn.source="Open Payment: ";return fn;})()
      },
      "empty": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open payment link created"])};fn.source="No open payment link created";return fn;})(),
        "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get paid by someone with open payment link. Ready to generate revenue?"])};fn.source="Get paid by someone with open payment link. Ready to generate revenue?";return fn;})(),
        "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Payment Links"])};fn.source="Create Open Payment Links";return fn;})()
      }
    },
    "email": {
      "payment_link_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link ID: "])};fn.source="Payment Link ID: ";return fn;})(),
      "thank_you": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for using Swipe. This is example for payment link email."])};fn.source="Thank you for using Swipe. This is example for payment link email.";return fn;})(),
      "regards": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Regards,"])};fn.source="Best Regards,";return fn;})(),
      "open_payment_page": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment Page"])};fn.source="Open Payment Page";return fn;})(),
      "having_trouble": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you’re having trouble with the button above, copy and paste the URL below into your web browser."])};fn.source="If you’re having trouble with the button above, copy and paste the URL below into your web browser.";return fn;})(),
      "question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about this invoice, contact us at "])};fn.source="If you have any questions about this invoice, contact us at ";return fn;})(),
      "question_2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" or call us at "])};fn.source=" or call us at ";return fn;})()
    },
    "info": {
      "active_your_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your account to create a payment link"])};fn.source="Activate your account to create a payment link";return fn;})(),
      "active_your_account_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your account first"])};fn.source="Activate your account first";return fn;})(),
      "active_your_account_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a payment link."])};fn.source="Your account must be activated before you can create a payment link.";return fn;})(),
      "active_later": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate later"])};fn.source="Activate later";return fn;})(),
      "active_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])};fn.source="Activate account";return fn;})()
    },
    "settings": {
      "tab": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})(),
      "payment_provider": {
        "tab_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider"])};fn.source="Payment Provider";return fn;})(),
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment gateway to enable"])};fn.source="Select payment gateway to enable";return fn;})(),
        "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can enable one payment method at a time"])};fn.source="You can enable one payment method at a time";return fn;})(),
        "payment_gateway": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway "])};fn.source="Payment gateway ";return fn;})(),
        "enabled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enabled"])};fn.source="enabled";return fn;})(),
        "disabled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])};fn.source="disabled";return fn;})(),
        "channel_used": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Used: "])};fn.source="Channel Used: ";return fn;})(),
        "fpx_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FPX Payment"])};fn.source="FPX Payment";return fn;})(),
        "debit_credit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit/Credit"])};fn.source="Debit/Credit";return fn;})(),
        "list_payment_provider": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of payment provider"])};fn.source="List of payment provider";return fn;})(),
        "contact_support_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact our support if you don't have any payment provider listed in here"])};fn.source="Please contact our support if you don't have any payment provider listed in here";return fn;})(),
        "no_cc_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently not applying any Credit/Debit Card Payment method."])};fn.source="You are currently not applying any Credit/Debit Card Payment method.";return fn;})(),
        "apply_cc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to apply"])};fn.source="Click here to apply";return fn;})(),
        "apply_cc_dialog_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applying for Credit/Debit Card Payment"])};fn.source="Applying for Credit/Debit Card Payment";return fn;})(),
        "apply_cc_dialog_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to apply for Credit / Debit Card, kindly click 'Apply Now' button. Our team will contact you separately to assist you on this application.  "])};fn.source="If you would like to apply for Credit / Debit Card, kindly click 'Apply Now' button. Our team will contact you separately to assist you on this application.  ";return fn;})(),
        "apply_cc_proceed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Now"])};fn.source="Apply Now";return fn;})(),
        "apply_cc_sent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application has been sent to our team."])};fn.source="Application has been sent to our team.";return fn;})()
      }
    },
    "thank_you": {
      "payment_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment ID: "])};fn.source="Payment ID: ";return fn;})(),
      "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount: "])};fn.source="Amount: ";return fn;})(),
      "paid_to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment paid to: "])};fn.source="Payment paid to: ";return fn;})(),
      "paid_on": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid on: "])};fn.source="Paid on: ";return fn;})(),
      "payment_for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment for: "])};fn.source="Payment for: ";return fn;})(),
      "channel_used": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel used: "])};fn.source="Channel used: ";return fn;})(),
      "thank_you_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your payment"])};fn.source="Thank you for your payment";return fn;})(),
      "attempt_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref ID: "])};fn.source="Ref ID: ";return fn;})(),
      "payment_gateway": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider: "])};fn.source="Payment Provider: ";return fn;})(),
      "payment_method": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method: "])};fn.source="Payment Method: ";return fn;})(),
      "close_window": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may close this window"])};fn.source="You may close this window";return fn;})()
    },
    "detail": {
      "title_modal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])};fn.source="Details";return fn;})(),
      "business_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])};fn.source="Business Name";return fn;})(),
      "payment_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])};fn.source="Payment";return fn;})(),
      "paid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])};fn.source="Paid";return fn;})(),
      "unpaid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])};fn.source="Unpaid";return fn;})(),
      "copy_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Link"])};fn.source="Copy Link";return fn;})(),
      "resend_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Email"])};fn.source="Resend Email";return fn;})(),
      "refresh": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])};fn.source="Refresh";return fn;})(),
      "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})(),
      "method": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])};fn.source="Method";return fn;})(),
      "channel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])};fn.source="Channel";return fn;})(),
      "email_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
      "attempt_history": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempt history"])};fn.source="Attempt history";return fn;})(),
      "created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])};fn.source="Created";return fn;})(),
      "payment_history": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment history"])};fn.source="Payment history";return fn;})(),
      "payment_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])};fn.source="Payment Details";return fn;})(),
      "payment_wallet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Transaction details"])};fn.source="Processed Transaction details";return fn;})(),
      "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])};fn.source="Title";return fn;})(),
      "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
      "charge_fees": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Fees"])};fn.source="Charge Fees";return fn;})(),
      "net_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])};fn.source="Net Amount";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
      "ref_1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref 1"])};fn.source="Ref 1";return fn;})(),
      "ref_2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref 2"])};fn.source="Ref 2";return fn;})(),
      "phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No."])};fn.source="Phone No.";return fn;})(),
      "payment_method": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])};fn.source="Payment method";return fn;})(),
      "ref_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])};fn.source="Reference ID";return fn;})(),
      "id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])};fn.source="Id";return fn;})(),
      "refund": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])};fn.source="Refund";return fn;})(),
      "requery": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requery"])};fn.source="Requery";return fn;})(),
      "process_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process payment"])};fn.source="Process payment";return fn;})(),
      "processed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed"])};fn.source="Processed";return fn;})()
    },
    "config": {
      "stripe": {
        "send_document": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Documents to Stripe?"])};fn.source="Send Documents to Stripe?";return fn;})(),
        "send_document_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First, you need to send merchant’s document to stripe before setup configs."])};fn.source="First, you need to send merchant’s document to stripe before setup configs.";return fn;})(),
        "connect_to_stripe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to Stripe"])};fn.source="Connect to Stripe";return fn;})(),
        "resubmit_to_stripe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmit to Stripe"])};fn.source="Resubmit to Stripe";return fn;})(),
        "modal_title_confirmation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to send the documents to Stripe?"])};fn.source="Would you like to send the documents to Stripe?";return fn;})(),
        "modal_description_confirmation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send merchant’s document to stripe for approval to use their payment gateway."])};fn.source="Send merchant’s document to stripe for approval to use their payment gateway.";return fn;})(),
        "modal_action_confirmation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Documents"])};fn.source="Send Documents";return fn;})(),
        "no_stripe_status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Stripe status found"])};fn.source="No Stripe status found";return fn;})()
      },
      "form_merchant_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])};fn.source="Merchant ID";return fn;})(),
      "form_merchant_id_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the merchant ID here"])};fn.source="Paste the merchant ID here";return fn;})(),
      "form_secret_key": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Key"])};fn.source="Secret Key";return fn;})(),
      "form_secret_key_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the secret key here"])};fn.source="Paste the secret key here";return fn;})(),
      "form_api_key": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key"])};fn.source="API Key";return fn;})(),
      "form_api_key_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the API key here"])};fn.source="Paste the API key here";return fn;})(),
      "no_payment_gateway_charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please update the payment charges before create the config."])};fn.source="Please update the payment charges before create the config.";return fn;})(),
      "update_payment_charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update payment charges"])};fn.source="Update payment charges";return fn;})()
    },
    "test_mode": {
      "generete_payment_provider": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Payment Provider"])};fn.source="Generate Payment Provider";return fn;})(),
      "generete_payment_provider_success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Payment Provider Generated"])};fn.source="Successful Payment Provider Generated";return fn;})()
    }
  },
  "wallet": {
    "menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])};fn.source="Menu";return fn;})(),
    "balances_menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances Menu"])};fn.source="Balances Menu";return fn;})(),
    "account_balance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Balance"])};fn.source="Account Balance";return fn;})(),
    "overview": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])};fn.source="Overview";return fn;})(),
      "on_the_way_swipe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way to your Swipe account"])};fn.source="On the way to your Swipe account";return fn;})(),
      "expected_payout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Payouts"])};fn.source="Requested Payouts";return fn;})(),
      "otw_bank": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way to your bank account"])};fn.source="On the way to your bank account";return fn;})(),
      "on_the_way_bank": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Transfer"])};fn.source="Success Transfer";return fn;})(),
      "header": {
        "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
        "charges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges"])};fn.source="Charges";return fn;})(),
        "net_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])};fn.source="Net Amount";return fn;})(),
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])};fn.source="Title";return fn;})(),
        "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
        "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])};fn.source="Date";return fn;})(),
        "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})()
      },
      "succeded": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])};fn.source="Paid";return fn;})()
    },
    "transaction": {
      "type_transfer_in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer In"])};fn.source="Transfer In";return fn;})(),
      "type_transfer_out": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Out"])};fn.source="Transfer Out";return fn;})(),
      "settled_in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled In"])};fn.source="Settled In";return fn;})(),
      "settled_out": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settle Out"])};fn.source="Settle Out";return fn;})(),
      "pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])};fn.source="Pending";return fn;})(),
      "id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])};fn.source="ID";return fn;})(),
      "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
      "type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])};fn.source="Type";return fn;})(),
      "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})(),
      "settlement_reference": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Transaction Reference"])};fn.source="Processed Transaction Reference";return fn;})(),
      "start_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])};fn.source="Start Date";return fn;})(),
      "end_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])};fn.source="End Date";return fn;})(),
      "processing_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing ID"])};fn.source="Processing ID";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
      "count": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])};fn.source="Count";return fn;})(),
      "gross": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross"])};fn.source="Gross";return fn;})(),
      "fee": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])};fn.source="Fees";return fn;})(),
      "net_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net"])};fn.source="Net";return fn;})(),
      "business_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business details"])};fn.source="Business details";return fn;})(),
      "transaction_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Details"])};fn.source="Transaction Details";return fn;})(),
      "business_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business ID"])};fn.source="Business ID";return fn;})(),
      "business_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])};fn.source="Business name";return fn;})(),
      "created_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])};fn.source="Created Date";return fn;})(),
      "submitted_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted Date"])};fn.source="Submitted Date";return fn;})(),
      "payment_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])};fn.source="Payment Details";return fn;})(),
      "view_payment_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Payment Details"])};fn.source="View Payment Details";return fn;})(),
      "transactions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])};fn.source="Transactions";return fn;})(),
      "total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])};fn.source="Total";return fn;})(),
      "processed-payment-link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Payment Link"])};fn.source="Processed Payment Link";return fn;})()
    },
    "payout": {
      "withdraw": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])};fn.source="Withdraw";return fn;})(),
      "payout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])};fn.source="Payout";return fn;})(),
      "payout_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Details"])};fn.source="Payout Details";return fn;})(),
      "bank_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])};fn.source="Bank Name";return fn;})(),
      "account_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])};fn.source="Account Number";return fn;})(),
      "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})(),
      "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
      "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "bank_acc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account"])};fn.source="Bank Account";return fn;})(),
      "email_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])};fn.source="Email Address";return fn;})(),
      "account_bank_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Bank Details"])};fn.source="Account Bank Details";return fn;})(),
      "origin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])};fn.source="Origin";return fn;})(),
      "issuer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer"])};fn.source="Issuer";return fn;})(),
      "withdraw_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw Amount"])};fn.source="Withdraw Amount";return fn;})(),
      "withdraw_menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can withdraw up to:"])};fn.source="You can withdraw up to:";return fn;})(),
      "withdraw_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw all"])};fn.source="Withdraw all";return fn;})(),
      "withdraw_amount_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter withdraw amount"])};fn.source="Enter withdraw amount";return fn;})(),
      "request_withdraw": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Withdraw"])};fn.source="Request Withdraw";return fn;})(),
      "business_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Businesses Name"])};fn.source="Businesses Name";return fn;})(),
      "id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Id"])};fn.source="Reference Id";return fn;})(),
      "original_round_off": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Original amount : <b>", _interpolate(_named("amount")), "</b> <br> Type : <b>", _interpolate(_named("type")), "</b>"])};fn.source="Original amount : <b>{amount}</b> <br> Type : <b>{type}</b>";return fn;})(),
      "daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])};fn.source="Daily";return fn;})(),
      "summary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])};fn.source="Summary";return fn;})()
    }
  },
  "integration": {
    "menu": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations Menu"])};fn.source="Integrations Menu";return fn;})(),
      "api": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])};fn.source="API";return fn;})(),
      "webhook": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])};fn.source="Webhooks";return fn;})(),
      "please_activate_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This features for approved business only"])};fn.source="This features for approved business only";return fn;})()
    },
    "api": {
      "key": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key"])};fn.source="API Key";return fn;})(),
      "api_docs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration Documentation"])};fn.source="Integration Documentation";return fn;})(),
      "reset_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Api Key"])};fn.source="Reset Api Key";return fn;})(),
      "notes_1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes: API keys are for advanced users. Please make sure that you have read the "])};fn.source="Notes: API keys are for advanced users. Please make sure that you have read the ";return fn;})(),
      "notes_2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" before proceeding."])};fn.source=" before proceeding.";return fn;})(),
      "copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key has been copied."])};fn.source="API Key has been copied.";return fn;})(),
      "reset_prompt_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset API Key?"])};fn.source="Reset API Key?";return fn;})(),
      "reset_prompt": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure want to reset API Key? This will block existing integration until you update with a new key."])};fn.source="Are you sure want to reset API Key? This will block existing integration until you update with a new key.";return fn;})()
    },
    "webhook": {
      "new": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Webhook"])};fn.source="Add New Webhook";return fn;})(),
      "key": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Key"])};fn.source="Signature Key";return fn;})(),
      "reset_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Signature Key"])};fn.source="Reset Signature Key";return fn;})(),
      "notes_1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes: Webhooks are for advanced users. Please make sure that you have read the "])};fn.source="Notes: Webhooks are for advanced users. Please make sure that you have read the ";return fn;})(),
      "notes_2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" before proceeding."])};fn.source=" before proceeding.";return fn;})(),
      "copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Key has been copied."])};fn.source="Signature Key has been copied.";return fn;})(),
      "reset_prompt_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Signature Key?"])};fn.source="Reset Signature Key?";return fn;})(),
      "reset_prompt": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure want to reset Signature Key? This will make the system to generate a signature using the old key, Please update the signature key on client side."])};fn.source="Are you sure want to reset Signature Key? This will make the system to generate a signature using the old key, Please update the signature key on client side.";return fn;})(),
      "form": {
        "url": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Url"])};fn.source="Target Url";return fn;})(),
        "event_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])};fn.source="Event";return fn;})(),
        "enable": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])};fn.source="Enable";return fn;})()
      }
    },
    "webhook_event": {
      "webhook_event_logs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook logs"])};fn.source="Webhook logs";return fn;})(),
      "no_webhook_logs_found": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No webhook logs found"])};fn.source="No webhook logs found";return fn;})(),
      "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "uuid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])};fn.source="UUID";return fn;})(),
      "url": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])};fn.source="URL";return fn;})(),
      "payload": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload"])};fn.source="Payload";return fn;})(),
      "created_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])};fn.source="Created at";return fn;})(),
      "updated_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])};fn.source="Updated at";return fn;})(),
      "exception": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])};fn.source="Error";return fn;})(),
      "retries": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retries"])};fn.source="Retries";return fn;})(),
      "no_of_attempt": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No of Attempt"])};fn.source="No of Attempt";return fn;})(),
      "last_attempt_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Attempt at"])};fn.source="Last Attempt at";return fn;})()
    }
  },
  "user": {
    "detail": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Details"])};fn.source="User Details";return fn;})(),
      "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
      "country": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])};fn.source="Country";return fn;})(),
      "id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])};fn.source="ID";return fn;})(),
      "business_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])};fn.source="Business Name";return fn;})(),
      "master_business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])};fn.source="Master";return fn;})(),
      "verify_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify at"])};fn.source="Verify at";return fn;})(),
      "register_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at"])};fn.source="Register at";return fn;})(),
      "resend_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Password"])};fn.source="Resend Password";return fn;})(),
      "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})(),
      "role": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])};fn.source="Role";return fn;})(),
      "confirm_delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["are you sure to delete this role"])};fn.source="are you sure to delete this role";return fn;})()
    }
  },
  "admin": {
    "audit": {
      "id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])};fn.source="ID";return fn;})(),
      "event": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])};fn.source="Event";return fn;})(),
      "auditable_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Type"])};fn.source="Model Type";return fn;})(),
      "user_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User email"])};fn.source="User email";return fn;})(),
      "via": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])};fn.source="Via";return fn;})(),
      "type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger By Type"])};fn.source="Trigger By Type";return fn;})(),
      "type_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger By Details"])};fn.source="Trigger By Details";return fn;})(),
      "user_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])};fn.source="User name";return fn;})(),
      "business_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])};fn.source="Business name";return fn;})(),
      "created_at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])};fn.source="Created at";return fn;})(),
      "old_values": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old values"])};fn.source="Old values";return fn;})(),
      "new_values": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New values"])};fn.source="New values";return fn;})(),
      "auditable_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Id"])};fn.source="Model Id";return fn;})(),
      "user_id": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User id"])};fn.source="User id";return fn;})(),
      "user_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type"])};fn.source="User type";return fn;})(),
      "url": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])};fn.source="URL";return fn;})(),
      "ip_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP address"])};fn.source="IP address";return fn;})(),
      "user_agent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User agent"])};fn.source="User agent";return fn;})(),
      "tags": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])};fn.source="Tags";return fn;})(),
      "made_by": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made by"])};fn.source="Made by";return fn;})()
    },
    "cron": {
      "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
      "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})(),
      "payout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])};fn.source="Payout";return fn;})(),
      "settlement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement"])};fn.source="Settlement";return fn;})(),
      "payment_profit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Profit"])};fn.source="Payment Profit";return fn;})(),
      "run_cron": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Cron"])};fn.source="Run Cron";return fn;})()
    }
  },
  "payout": {
    "table": {
      "account": {
        "tab_request": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested "])};fn.source="Requested ";return fn;})(),
        "tab_settlement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way to merchant account"])};fn.source="On the way to merchant account";return fn;})(),
        "tab_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success transferred to the bank"])};fn.source="Success transferred to the bank";return fn;})(),
        "tab_failed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed transferred to the bank"])};fn.source="Failed transferred to the bank";return fn;})(),
        "header_date_requested": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date requested"])};fn.source="Date requested";return fn;})(),
        "header_date_proceeded": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date proceeded"])};fn.source="Date proceeded";return fn;})(),
        "header_date_transferred": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date transferred"])};fn.source="Date transferred";return fn;})(),
        "header_bank_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])};fn.source="Bank name";return fn;})(),
        "header_acc_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])};fn.source="Account number";return fn;})(),
        "header_acc_holder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder"])};fn.source="Account holder";return fn;})(),
        "header_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
        "header_business_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])};fn.source="Business name";return fn;})(),
        "header_status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
        "header_action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})()
      },
      "merchant": {
        "tab_request": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested "])};fn.source="Requested ";return fn;})(),
        "tab_settlement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way to your account"])};fn.source="On the way to your account";return fn;})(),
        "tab_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success transferred to your bank"])};fn.source="Success transferred to your bank";return fn;})(),
        "tab_failed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed transferred to your bank"])};fn.source="Failed transferred to your bank";return fn;})()
      }
    },
    "modal": {
      "download_csv_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download .CSV format"])};fn.source="Download .CSV format";return fn;})(),
      "download_csv_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the pending completion after transfer the payout to the destinated bank account."])};fn.source="Please complete the pending completion after transfer the payout to the destinated bank account.";return fn;})(),
      "download_csv_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])};fn.source="Download CSV";return fn;})(),
      "insert_ref_id_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insert reference ID"])};fn.source="insert reference ID";return fn;})(),
      "upload_proof_documents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload proof documents"])};fn.source="Upload proof documents";return fn;})(),
      "proceed_to_settlement_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to settlement?"])};fn.source="Proceed to settlement?";return fn;})(),
      "proceed_to_settlement_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to settlement"])};fn.source="Proceed to settlement";return fn;})(),
      "proceed_settlement_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to proceed this request to settlement?"])};fn.source="Do you want to proceed this request to settlement?";return fn;})(),
      "mark_as_fail_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked as failed"])};fn.source="Marked as failed";return fn;})(),
      "marked_as_failed_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as failed?"])};fn.source="Mark as failed?";return fn;})(),
      "mark_as_fail_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state reasons why this transfers is failed"])};fn.source="Please state reasons why this transfers is failed";return fn;})(),
      "mark_as_transferred_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked as transferred"])};fn.source="Marked as transferred";return fn;})()
    },
    "payment_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout List"])};fn.source="Payout List";return fn;})(),
    "upload_documents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload documents"])};fn.source="Upload documents";return fn;})(),
    "transferred_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred"])};fn.source="Transferred";return fn;})(),
    "download_csv": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed download CSV"])};fn.source="Proceed download CSV";return fn;})(),
    "alert_transferred_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred Completed!"])};fn.source="Transferred Completed!";return fn;})()
  },
  "support": {
    "payment_link_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Links"])};fn.source="Payment Links";return fn;})(),
    "contact_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact no"])};fn.source="Contact no";return fn;})(),
    "currency_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])};fn.source="Currency";return fn;})(),
    "business_support_email_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business support email"])};fn.source="Business support email";return fn;})(),
    "payment_id_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment id"])};fn.source="Payment id";return fn;})(),
    "payment_link_id_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment link id"])};fn.source="Payment link id";return fn;})(),
    "payment_attempt_id_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment attempt id"])};fn.source="Payment attempt id";return fn;})(),
    "ref_1_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference 1"])};fn.source="Reference 1";return fn;})(),
    "ref_2_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference 2"])};fn.source="Reference 2";return fn;})(),
    "payout": {
      "tab_settlement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way to merchant account"])};fn.source="On the way to merchant account";return fn;})(),
      "tab_approved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success transfer to merchant bank"])};fn.source="Success transfer to merchant bank";return fn;})(),
      "tab_failed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed transfer to merchant bank"])};fn.source="Failed transfer to merchant bank";return fn;})()
    }
  },
  "subscription": {
    "menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions Menu"])};fn.source="Subscriptions Menu";return fn;})(),
    "active_your_account_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a subscription package."])};fn.source="Your account must be activated before you can create a subscription package.";return fn;})(),
    "add_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Package"])};fn.source="Add Package";return fn;})(),
    "create_subscription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Subscription"])};fn.source="Create Subscription";return fn;})(),
    "tab_subscriber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subscriber"])};fn.source="Add Subscriber";return fn;})(),
    "no_package_available": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No package available"])};fn.source="No package available";return fn;})(),
    "tab_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package List"])};fn.source="Package List";return fn;})(),
    "tab_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})(),
    "package_list_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Package"])};fn.source="Subscription Package";return fn;})(),
    "subscriber": {
      "add_subscriber_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subscriber & Package"])};fn.source="Add Subscriber & Package";return fn;})(),
      "name_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber Name"])};fn.source="Subscriber Name";return fn;})(),
      "name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of subscriber"])};fn.source="Name of subscriber";return fn;})(),
      "email_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber email address"])};fn.source="Subscriber email address";return fn;})(),
      "email_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address of subscriber"])};fn.source="Email address of subscriber";return fn;})(),
      "phone_no_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber phone number"])};fn.source="Subscriber phone number";return fn;})(),
      "phone_no_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number of subscriber"])};fn.source="Phone number of subscriber";return fn;})(),
      "choose_package_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose package"])};fn.source="Choose package";return fn;})(),
      "package_for_subscriber_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package for subscriber"])};fn.source="Package for subscriber";return fn;})(),
      "choose_package_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose or add your package"])};fn.source="Choose or add your package";return fn;})(),
      "submit_subscription_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Subscription Plan"])};fn.source="Send Subscription Plan";return fn;})(),
      "preview_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])};fn.source="Preview";return fn;})(),
      "preview_hide": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide preview"])};fn.source="Hide preview";return fn;})(),
      "preview_show": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show preview"])};fn.source="Show preview";return fn;})(),
      "create_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new package"])};fn.source="Create new package";return fn;})(),
      "package_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package List"])};fn.source="Package List";return fn;})(),
      "add_subscriber_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber"])};fn.source="Subscriber";return fn;})(),
      "list_of_subscribers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of Subscribers :"])};fn.source="List of Subscribers :";return fn;})(),
      "change_package_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose New Package"])};fn.source="Choose New Package";return fn;})(),
      "change_package_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose package"])};fn.source="Choose package";return fn;})(),
      "change_package_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Package"])};fn.source="Change Package";return fn;})(),
      "confirm_change_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Package for Subscriber"])};fn.source="Change Package for Subscriber";return fn;})(),
      "confirm_change_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send email to the subscriber for confirmation of changing subscription package."])};fn.source="We will send email to the subscriber for confirmation of changing subscription package.";return fn;})(),
      "confirm_change_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation email has been sent to user."])};fn.source="Confirmation email has been sent to user.";return fn;})(),
      "cancel_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel The Subscription?"])};fn.source="Cancel The Subscription?";return fn;})(),
      "cancel_active_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not receive any payment from this subscriber. We will notify subscriber by email."])};fn.source="You will not receive any payment from this subscriber. We will notify subscriber by email.";return fn;})(),
      "cancel_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is not active yet? Are you sure want to cancel his/her subscription?"])};fn.source="This user is not active yet? Are you sure want to cancel his/her subscription?";return fn;})(),
      "cancel_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Subscription"])};fn.source="Cancel Subscription";return fn;})(),
      "copy_activation_link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy activation link"])};fn.source="Copy activation link";return fn;})(),
      "status": {
        "created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])};fn.source="Created";return fn;})(),
        "active": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])};fn.source="Active";return fn;})(),
        "to_be_change_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Change Package"])};fn.source="Pending Change Package";return fn;})(),
        "to_be_canceled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Cancellation"])};fn.source="Pending Cancellation";return fn;})(),
        "canceled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])};fn.source="Cancelled";return fn;})(),
        "incomplete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])};fn.source="Incomplete";return fn;})(),
        "deactivate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactive"])};fn.source="Deactive";return fn;})(),
        "update_card": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to Update Card"])};fn.source="Need to Update Card";return fn;})()
      },
      "empty": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subscribers"])};fn.source="No subscribers";return fn;})(),
        "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription package will be use to create your subscription link. Let’s start with create one subscription package"])};fn.source="Subscription package will be use to create your subscription link. Let’s start with create one subscription package";return fn;})(),
        "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package"])};fn.source="Create Package";return fn;})()
      }
    },
    "activation": {
      "subscription_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Details"])};fn.source="Your Details";return fn;})(),
      "subscription_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])};fn.source="Package";return fn;})(),
      "subscription_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])};fn.source="Amount to pay";return fn;})(),
      "subscription_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])};fn.source="Subscribe";return fn;})()
    },
    "detail": {
      "title_modal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])};fn.source="Details";return fn;})(),
      "status_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "last_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last payment"])};fn.source="Last payment";return fn;})(),
      "next_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next payment"])};fn.source="Next payment";return fn;})(),
      "method": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])};fn.source="Method";return fn;})(),
      "payment_history": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment history"])};fn.source="Payment history";return fn;})(),
      "package_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Description"])};fn.source="Package Description";return fn;})(),
      "package_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])};fn.source="Package Name";return fn;})(),
      "subscriber_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])};fn.source="name";return fn;})(),
      "subscriber_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
      "subscriber_phone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "subscribed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])};fn.source="Subscribed";return fn;})(),
      "refund": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])};fn.source="Refund";return fn;})(),
      "retry_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry payment"])};fn.source="Retry payment";return fn;})(),
      "term_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term amount"])};fn.source="Term amount";return fn;})(),
      "term_length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term length"])};fn.source="Term length";return fn;})(),
      "first_billing_cycle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First billing cycle"])};fn.source="First billing cycle";return fn;})(),
      "after": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After"])};fn.source="After";return fn;})(),
      "package_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Details"])};fn.source="Package Details";return fn;})(),
      "subscriber_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber Details"])};fn.source="Subscriber Details";return fn;})(),
      "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
      "email_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
      "phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "package_term_length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package term length"])};fn.source="Package term length";return fn;})(),
      "amount_to_pay": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])};fn.source="Amount to pay";return fn;})(),
      "next_bill_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next bill date"])};fn.source="Next bill date";return fn;})(),
      "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "payment_status": {
        "created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment attempted"])};fn.source="Payment attempted";return fn;})(),
        "pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment pending"])};fn.source="Payment pending";return fn;})(),
        "receipt": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached Thank You Page"])};fn.source="Reached Thank You Page";return fn;})(),
        "paid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment succeeded"])};fn.source="Payment succeeded";return fn;})(),
        "failed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed"])};fn.source="Payment failed";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment error"])};fn.source="Payment error";return fn;})(),
        "unknown": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned"])};fn.source="Abandoned";return fn;})()
      }
    },
    "redirect": {
      "message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You For Subscribing!"])};fn.source="Thank You For Subscribing!";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have successfully subscribed <b>", _interpolate(_named("package_name")), "</b>. Here are the details of the subscription."])};fn.source="You have successfully subscribed <b>{package_name}</b>. Here are the details of the subscription.";return fn;})(),
      "pending_description": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your subscription <b>", _interpolate(_named("package_name")), "</b> is still pending activation. Here are the details of the subscription."])};fn.source="Your subscription <b>{package_name}</b> is still pending activation. Here are the details of the subscription.";return fn;})(),
      "subscribed_to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have subscribed to:"])};fn.source="You have subscribed to:";return fn;})(),
      "subscription_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Name: "])};fn.source="Subscription Name: ";return fn;})(),
      "subscription_plan": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Plan: "])};fn.source="Subscription Plan: ";return fn;})(),
      "subscription_date_started": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Started: "])};fn.source="Data Started: ";return fn;})(),
      "subscription_price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Price: "])};fn.source="Subscription Price: ";return fn;})(),
      "change_package_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Change Subscription"])};fn.source="Successfully Change Subscription";return fn;})(),
      "change_package_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have successfully change your subscription to <b>", _interpolate(_named("package_name")), "</b>. Here are the details of the new subscription."])};fn.source="You have successfully change your subscription to <b>{package_name}</b>. Here are the details of the new subscription.";return fn;})(),
      "cancel_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Cancel Subscription"])};fn.source="Successfully Cancel Subscription";return fn;})(),
      "cancel_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have confirm your <b>", _interpolate(_named("package_name")), "'s</b> subscription cancellation. Here are the details of the new subscription."])};fn.source="You have confirm your <b>{package_name}'s</b> subscription cancellation. Here are the details of the new subscription.";return fn;})(),
      "close_window": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may close this window"])};fn.source="You may close this window";return fn;})()
    },
    "create_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package"])};fn.source="Create Package";return fn;})(),
    "edit_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Package"])};fn.source="Edit Package";return fn;})(),
    "subscription_button_done": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])};fn.source="Done";return fn;})(),
    "table_header": {
      "edit_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Package"])};fn.source="Edit Package";return fn;})(),
      "delete_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete package"])};fn.source="Delete package";return fn;})(),
      "delete_package_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? This process cannot be undone."])};fn.source="Are you sure you want to delete this? This process cannot be undone.";return fn;})(),
      "package_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])};fn.source="Package Name";return fn;})(),
      "package_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Description"])};fn.source="Package Description";return fn;})(),
      "package_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Amount"])};fn.source="Package Amount";return fn;})(),
      "package_term": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Term"])};fn.source="Package Term";return fn;})(),
      "date_started": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Started"])};fn.source="Date Started";return fn;})(),
      "started_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started  Date"])};fn.source="Started  Date";return fn;})(),
      "next_bill_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Bill Date"])};fn.source="Next Bill Date";return fn;})(),
      "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})(),
      "subscriber_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber"])};fn.source="Subscriber";return fn;})(),
      "subscriber_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])};fn.source="Email Address";return fn;})(),
      "subscriber_phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])};fn.source="Phone Number";return fn;})(),
      "subscriber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber"])};fn.source="Subscriber";return fn;})(),
      "subscriber_started_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started date"])};fn.source="Started date";return fn;})(),
      "subscriber_next_bill_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next bill date"])};fn.source="Next bill date";return fn;})(),
      "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "currency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])};fn.source="Currency";return fn;})()
    },
    "empty": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subscription package"])};fn.source="No subscription package";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription package will be use to create your subscription link. Let’s start with create one subscription package"])};fn.source="Subscription package will be use to create your subscription link. Let’s start with create one subscription package";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package"])};fn.source="Create Package";return fn;})()
    },
    "package": {
      "package_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name"])};fn.source="Package name";return fn;})(),
      "package_name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of package"])};fn.source="Name of package";return fn;})(),
      "package_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package description"])};fn.source="Package description";return fn;})(),
      "package_description_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of package"])};fn.source="Description of package";return fn;})(),
      "amount_of_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of package"])};fn.source="Amount of package";return fn;})(),
      "package_frequency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Frequency"])};fn.source="Package Frequency";return fn;})(),
      "term": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["term"])};fn.source="term";return fn;})(),
      "package_term": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package term"])};fn.source="Package term";return fn;})(),
      "package_term_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term of package"])};fn.source="Term of package";return fn;})(),
      "start_package_cycle_after": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start package cycle after"])};fn.source="Start package cycle after";return fn;})(),
      "package_frequency_number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package frequency number"])};fn.source="Package frequency number";return fn;})(),
      "start_after_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start after type"])};fn.source="Start after type";return fn;})(),
      "start_after_number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start after number"])};fn.source="Start after number";return fn;})(),
      "price_per_billing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per billing period"])};fn.source="Price per billing period";return fn;})(),
      "first_bill_is_on": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First bill is on"])};fn.source="First bill is on";return fn;})(),
      "no_of_billing_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of billing periods"])};fn.source="No. of billing periods";return fn;})(),
      "billing_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing periods"])};fn.source="billing periods";return fn;})(),
      "max_packages_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum packages"])};fn.source="Maximum packages";return fn;})(),
      "max_packages_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry you are only allowed to add 5 packages at one time."])};fn.source="Sorry you are only allowed to add 5 packages at one time.";return fn;})(),
      "disable_action_tooltip_description": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You may not be able to ", _interpolate(_named("action")), " <br> as you have current active subscriber"])};fn.source="You may not be able to {action} <br> as you have current active subscriber";return fn;})(),
      "edit_existing_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit the existing details"])};fn.source="edit the existing details";return fn;})(),
      "delete_package": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete this package"])};fn.source="delete this package";return fn;})()
    },
    "date_charge_start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date charge start"])};fn.source="Date charge start";return fn;})(),
    "edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])};fn.source="Edit";return fn;})(),
    "edit_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Details"])};fn.source="Edit Details";return fn;})(),
    "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])};fn.source="Delete";return fn;})(),
    "amount_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])};fn.source="0";return fn;})(),
    "view_subscriber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Subscriber"])};fn.source="View Subscriber";return fn;})()
  },
  "customer": {
    "customer_menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers Menu"])};fn.source="Customers Menu";return fn;})(),
    "active_your_account_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a customer."])};fn.source="Your account must be activated before you can create a customer.";return fn;})(),
    "customer_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer List"])};fn.source="Customer List";return fn;})(),
    "add_customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Customer"])};fn.source="Add Customer";return fn;})(),
    "add_a_customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a customer"])};fn.source="Add a customer";return fn;})(),
    "add_new_customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Customer"])};fn.source="Add New Customer";return fn;})(),
    "add_new_customer_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Customer"])};fn.source="New Customer";return fn;})(),
    "add_additional_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add additional details"])};fn.source="Add additional details";return fn;})(),
    "search_customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Customer"])};fn.source="Search Customer";return fn;})(),
    "customer_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])};fn.source="Customer Name";return fn;})(),
    "customer_name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide customer name"])};fn.source="Please provide customer name";return fn;})(),
    "customer_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Email"])};fn.source="Customer Email";return fn;})(),
    "customer_phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Phone Number"])};fn.source="Customer Phone Number";return fn;})(),
    "customer_email_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer email address"])};fn.source="Customer email address";return fn;})(),
    "invoice_prefix": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Prefix"])};fn.source="Invoice Prefix";return fn;})(),
    "invoice_prefix_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state"])};fn.source="Please state";return fn;})(),
    "invoice_sequence": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Sequence"])};fn.source="Invoice Sequence";return fn;})(),
    "billing_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])};fn.source="Billing Address";return fn;})(),
    "shipping_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])};fn.source="Shipping Address";return fn;})(),
    "same_billing_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as billing address"])};fn.source="Same as billing address";return fn;})(),
    "delete_customer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Customer"])};fn.source="Delete Customer";return fn;})(),
    "delete_customer_confirmation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? this process can not be undone."])};fn.source="Are you sure you want to delete this? this process can not be undone.";return fn;})(),
    "no_customer_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no customer found"])};fn.source="Oops no customer found";return fn;})(),
    "no_customer_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customer found"])};fn.source="No customer found";return fn;})(),
    "table_header": {
      "customer_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
      "customer_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
      "customer_phone_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])};fn.source="Phone Number";return fn;})(),
      "customer_date_created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})(),
      "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})()
    },
    "empty": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customer created"])};fn.source="No customer created";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill customers with one-off or recurring invoices, or subscriptions. Ready to add one?"])};fn.source="Bill customers with one-off or recurring invoices, or subscriptions. Ready to add one?";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Customer"])};fn.source="Add Customer";return fn;})()
    }
  },
  "product": {
    "product_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product List"])};fn.source="Product List";return fn;})(),
    "active_your_account_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a product."])};fn.source="Your account must be activated before you can create a product.";return fn;})(),
    "add_a_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a product"])};fn.source="Add a product";return fn;})(),
    "add_new_product_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Product"])};fn.source="New Product";return fn;})(),
    "add_new_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new product"])};fn.source="Add new product";return fn;})(),
    "search_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Product"])};fn.source="Search Product";return fn;})(),
    "no_product_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no product found"])};fn.source="Oops no product found";return fn;})(),
    "no_product_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No product found"])};fn.source="No product found";return fn;})(),
    "kg": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilogram"])};fn.source="Kilogram";return fn;})(),
    "g": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gram"])};fn.source="Gram";return fn;})(),
    "cm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centimeter"])};fn.source="Centimeter";return fn;})(),
    "m": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meter"])};fn.source="Meter";return fn;})(),
    "menu": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Menu"])};fn.source="Products Menu";return fn;})(),
      "list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product List"])};fn.source="Product List";return fn;})(),
      "settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})()
    },
    "filter": {
      "name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
      "curency_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])};fn.source="Currency";return fn;})(),
      "price_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])};fn.source="Price";return fn;})()
    },
    "table_header": {
      "image": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Image"])};fn.source="Product Image";return fn;})(),
      "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])};fn.source="Product Name";return fn;})(),
      "price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])};fn.source="Product Price";return fn;})(),
      "date_created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})()
    },
    "modal": {
      "add_product_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])};fn.source="Add Product";return fn;})(),
      "edit_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])};fn.source="Edit Product";return fn;})(),
      "image_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Image"])};fn.source="Product Image";return fn;})(),
      "name_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])};fn.source="Product Name";return fn;})(),
      "price_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])};fn.source="Product Price";return fn;})(),
      "unit_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit of Measure"])};fn.source="Unit of Measure";return fn;})(),
      "name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name to display"])};fn.source="Product name to display";return fn;})(),
      "name_placeholder_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])};fn.source="Product name";return fn;})(),
      "price_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.00"])};fn.source="0.00";return fn;})(),
      "unit_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select unit of measure"])};fn.source="Select unit of measure";return fn;})(),
      "unit_placeholder_prefix": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per"])};fn.source="Per";return fn;})(),
      "unit_placeholder_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])};fn.source="Unit";return fn;})(),
      "preview_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])};fn.source="Preview";return fn;})(),
      "delete_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Product"])};fn.source="Delete Product";return fn;})(),
      "delete_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? This process cannot be undone."])};fn.source="Are you sure you want to delete this? This process cannot be undone.";return fn;})()
    },
    "empty": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No product created"])};fn.source="No product created";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products that you sell to your customers. It can be anything from physical goods to digital services or subscription plans. Ready to create one?"])};fn.source="Products that you sell to your customers. It can be anything from physical goods to digital services or subscription plans. Ready to create one?";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Product"])};fn.source="Create Product";return fn;})()
    }
  },
  "tax": {
    "menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Menu"])};fn.source="Tax Menu";return fn;})(),
    "active_your_account_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create tax."])};fn.source="Your account must be activated before you can create tax.";return fn;})(),
    "add_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])};fn.source="Add Tax";return fn;})(),
    "tax_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Rates"])};fn.source="Tax Rates";return fn;})(),
    "new_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Rate"])};fn.source="New Rate";return fn;})(),
    "edit_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tax"])};fn.source="Edit Tax";return fn;})(),
    "tab_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax List"])};fn.source="Tax List";return fn;})(),
    "tab_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})(),
    "tax_list_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax List"])};fn.source="Tax List";return fn;})(),
    "btn_add_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new tax"])};fn.source="Add new tax";return fn;})(),
    "no_tax_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no tax found"])};fn.source="Oops no tax found";return fn;})(),
    "no_tax_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tax found"])};fn.source="No tax found";return fn;})(),
    "attach_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])};fn.source="Add Tax";return fn;})(),
    "attach_tax_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding tax is optional"])};fn.source="Adding tax is optional";return fn;})(),
    "add_new_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Tax"])};fn.source="Add New Tax";return fn;})(),
    "type_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tax is:"])};fn.source="This tax is:";return fn;})(),
    "select_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Tax"])};fn.source="Select Tax";return fn;})(),
    "select_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or add new tax"])};fn.source="Select or add new tax";return fn;})(),
    "table_header": {
      "tax_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Name"])};fn.source="Tax Name";return fn;})(),
      "tax_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
      "tax_rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate (%)"])};fn.source="Rate (%)";return fn;})(),
      "tax_date_created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])};fn.source="Date Created";return fn;})(),
      "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})()
    },
    "filter": {
      "placeholder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
      "placeholder_rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])};fn.source="Rate";return fn;})(),
      "placeholder_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})()
    },
    "form_add_tax": {
      "label_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Name"])};fn.source="Tax Name";return fn;})(),
      "label_rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Rate"])};fn.source="Tax Rate";return fn;})(),
      "label_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
      "label_btn_add_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])};fn.source="Add Tax";return fn;})(),
      "label_btn_save_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
      "placeholder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide tax name"])};fn.source="Please provide tax name";return fn;})(),
      "placeholder_rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])};fn.source="3";return fn;})(),
      "placeholder_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly explain about your tax rate"])};fn.source="Briefly explain about your tax rate";return fn;})()
    },
    "empty": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tax created"])};fn.source="No tax created";return fn;})(),
      "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax that will be use along when created invoice. It can be anything. Ready to create one?"])};fn.source="Tax that will be use along when created invoice. It can be anything. Ready to create one?";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Tax"])};fn.source="Create Tax";return fn;})()
    },
    "types": {
      "inclusive": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusive"])};fn.source="Inclusive";return fn;})(),
      "exclusive": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive"])};fn.source="Exclusive";return fn;})()
    },
    "edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])};fn.source="Edit";return fn;})(),
    "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])};fn.source="Delete";return fn;})(),
    "delete_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Tax"])};fn.source="Delete Tax";return fn;})(),
    "delete_tax_confirmation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? this process can not be undone."])};fn.source="Are you sure you want to delete this? this process can not be undone.";return fn;})()
  },
  "invoice": {
    "invoice_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice List"])};fn.source="Invoice List";return fn;})(),
    "create_new_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new invoice"])};fn.source="Create new invoice";return fn;})(),
    "no_invoice_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no invoice found"])};fn.source="Oops no invoice found";return fn;})(),
    "no_invoice_description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoice found"])};fn.source="No invoice found";return fn;})(),
    "menu": {
      "overview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])};fn.source="Overview";return fn;})(),
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Menu"])};fn.source="Invoice Menu";return fn;})(),
      "list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice List"])};fn.source="Invoice List";return fn;})(),
      "report": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])};fn.source="Reporting";return fn;})(),
      "aging_report": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging Reporting"])};fn.source="Aging Reporting";return fn;})(),
      "settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})()
    },
    "filter": {
      "customer_name_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])};fn.source="Customer Name";return fn;})()
    },
    "table_header": {
      "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Status"])};fn.source="Invoice Status";return fn;})(),
      "no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No."])};fn.source="Invoice No.";return fn;})(),
      "customer_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])};fn.source="Customer Name";return fn;})(),
      "customer_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Email"])};fn.source="Customer Email";return fn;})(),
      "due_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Due"])};fn.source="Amount Due";return fn;})(),
      "due_amount_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
      "due_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])};fn.source="Due Date";return fn;})(),
      "due_date_full": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Due Date"])};fn.source="Invoice Due Date";return fn;})(),
      "aging": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging (Day)"])};fn.source="Aging (Day)";return fn;})(),
      "tab_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Invoices"])};fn.source="All Invoices";return fn;})(),
      "tab_paid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])};fn.source="Paid";return fn;})(),
      "tab_unpaid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])};fn.source="Unpaid";return fn;})(),
      "tab_overdue": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])};fn.source="Overdue";return fn;})()
    },
    "create": {
      "create_invoice_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Invoice"])};fn.source="New Invoice";return fn;})(),
      "create_invoice_button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Invoice"])};fn.source="Create Invoice";return fn;})(),
      "save_draft": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as draft"])};fn.source="Save as draft";return fn;})(),
      "send_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice"])};fn.source="Send invoice";return fn;})(),
      "send_invoice_hint": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create and send invoice for ", _interpolate(_named("amount")), " to ", _interpolate(_named("customer")), "?"])};fn.source="Create and send invoice for {amount} to {customer}?";return fn;})(),
      "uneditable_invoice_hint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice can't be edited after they are sent"])};fn.source="Invoice can't be edited after they are sent";return fn;})(),
      "edit_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit invoice"])};fn.source="Edit invoice";return fn;})(),
      "continue_editing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue editing"])};fn.source="Continue editing";return fn;})(),
      "customer_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])};fn.source="Customer";return fn;})(),
      "product_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])};fn.source="Products";return fn;})(),
      "product_image": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prod. Image"])};fn.source="Prod. Image";return fn;})(),
      "product_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
      "product_price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])};fn.source="Price";return fn;})(),
      "product_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])};fn.source="Tax";return fn;})(),
      "product_qty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])};fn.source="Quantity";return fn;})(),
      "product_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
      "tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])};fn.source="Tax";return fn;})(),
      "tax_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Tax"])};fn.source="Total Tax";return fn;})(),
      "add_tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])};fn.source="Add Tax";return fn;})(),
      "subtotal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])};fn.source="Subtotal";return fn;})(),
      "total_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])};fn.source="Total";return fn;})(),
      "memo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])};fn.source="Memo";return fn;})(),
      "memo_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add some memo for your customer"])};fn.source="Add some memo for your customer";return fn;})(),
      "internal_memo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Memo"])};fn.source="Internal Memo";return fn;})(),
      "internal_memo_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add some memo for yourself (this memo will not appear on the invoice)"])};fn.source="Add some memo for yourself (this memo will not appear on the invoice)";return fn;})(),
      "payment_term": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Term"])};fn.source="Payment Term";return fn;})(),
      "payment_term_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])};fn.source="Due Date";return fn;})(),
      "terms": {
        "0d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])};fn.source="Today";return fn;})(),
        "1d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])};fn.source="Tomorrow";return fn;})(),
        "7d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 Days"])};fn.source="7 Days";return fn;})(),
        "14d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 Days"])};fn.source="14 Days";return fn;})(),
        "30d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 Days"])};fn.source="30 Days";return fn;})(),
        "45d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45 Days"])};fn.source="45 Days";return fn;})(),
        "60d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60 Days"])};fn.source="60 Days";return fn;})(),
        "90d": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90 Days"])};fn.source="90 Days";return fn;})(),
        "custom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Date"])};fn.source="Custom Date";return fn;})()
      },
      "type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])};fn.source="Type";return fn;})(),
      "types": {
        "one_time_payment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Payment"])};fn.source="Standard Payment";return fn;})(),
        "recurring": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring"])};fn.source="Recurring";return fn;})()
      },
      "allow_partial": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow partial payment"])};fn.source="Allow partial payment";return fn;})(),
      "custom_attributes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Fields"])};fn.source="Custom Fields";return fn;})(),
      "custom_attributes_key_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name"])};fn.source="Field name";return fn;})(),
      "custom_attributes_value_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field value"])};fn.source="Field value";return fn;})(),
      "add_new_custom_attribute": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new custom field"])};fn.source="Add new custom field";return fn;})(),
      "footer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer"])};fn.source="Footer";return fn;})(),
      "footer_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add some footer for your customer"])};fn.source="Add some footer for your customer";return fn;})(),
      "preview_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])};fn.source="Preview";return fn;})(),
      "preview_hide": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide preview"])};fn.source="Hide preview";return fn;})(),
      "preview_show": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show preview"])};fn.source="Show preview";return fn;})()
    },
    "detail": {
      "invoice_no": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invoice No: ", _interpolate(_named("no"))])};fn.source="Invoice No: {no}";return fn;})(),
      "total_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])};fn.source="Total Amount";return fn;})(),
      "total_paid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Paid"])};fn.source="Total Paid";return fn;})(),
      "amount_due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Due"])};fn.source="Amount Due";return fn;})(),
      "status_label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
      "status": {
        "created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Created"])};fn.source="Invoice Created";return fn;})(),
        "created_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])};fn.source="Created";return fn;})(),
        "open": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Sent"])};fn.source="Invoice Sent";return fn;})(),
        "open_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])};fn.source="Open";return fn;})(),
        "paid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Paid"])};fn.source="Invoice Paid";return fn;})(),
        "paid_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])};fn.source="Paid";return fn;})(),
        "paid_with_amount": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invoice Paid (", _interpolate(_named("amount")), ")"])};fn.source="Invoice Paid ({amount})";return fn;})(),
        "overdue": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Overdue"])};fn.source="Invoice Overdue";return fn;})(),
        "overdue_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])};fn.source="Overdue";return fn;})(),
        "void": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Void"])};fn.source="Invoice Void";return fn;})(),
        "void_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Void"])};fn.source="Void";return fn;})(),
        "uncollectible": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Uncollectible"])};fn.source="Invoice Uncollectible";return fn;})(),
        "uncollectible_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncollectible"])};fn.source="Uncollectible";return fn;})()
      },
      "created_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])};fn.source="Created Date";return fn;})(),
      "due_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])};fn.source="Due Date";return fn;})(),
      "due_in": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Due in ", _interpolate(_named("n")), " days"])};fn.source="Due in {n} days";return fn;})(),
      "due_in_short": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Days"])};fn.source="{n} Days";return fn;})(),
      "payment_due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Due"])};fn.source="Payment Due";return fn;})(),
      "history": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice History"])};fn.source="Invoice History";return fn;})(),
      "invoice_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Details"])};fn.source="Invoice Details";return fn;})(),
      "payment_term": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment term"])};fn.source="Payment term";return fn;})(),
      "payment_type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type"])};fn.source="Payment type";return fn;})(),
      "amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
      "customer_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Details"])};fn.source="Customer Details";return fn;})(),
      "customer_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])};fn.source="Customer name";return fn;})(),
      "customer_email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])};fn.source="Email address";return fn;})(),
      "customer_billing_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])};fn.source="Billing address";return fn;})(),
      "customer_shipping_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping address"])};fn.source="Shipping address";return fn;})(),
      "customer_phone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "product_details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Details"])};fn.source="Product Details";return fn;})(),
      "product_image": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Photo"])};fn.source="Product Photo";return fn;})(),
      "product_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])};fn.source="Product Name";return fn;})(),
      "product_price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])};fn.source="Product Price";return fn;})(),
      "product_unit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Label"])};fn.source="Unit Label";return fn;})(),
      "events": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])};fn.source="Events";return fn;})()
    },
    "invoice_pdf": {
      "preview_tab_invoice_pdf": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice PDF"])};fn.source="Invoice PDF";return fn;})(),
      "invoice_no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No"])};fn.source="Invoice No";return fn;})(),
      "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])};fn.source="Date";return fn;})(),
      "due_date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])};fn.source="Due Date";return fn;})(),
      "amount_to_pay": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])};fn.source="Amount to pay";return fn;})(),
      "subtotal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])};fn.source="Subtotal";return fn;})(),
      "total_due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Due"])};fn.source="Total Due";return fn;})(),
      "memo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])};fn.source="Memo";return fn;})(),
      "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])};fn.source="to";return fn;})(),
      "billing_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])};fn.source="Billing Address";return fn;})(),
      "shipping_address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])};fn.source="Shipping Address";return fn;})()
    },
    "report": {
      "aging_report_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging Report Summary"])};fn.source="Aging Report Summary";return fn;})(),
      "aging_report_title_short": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging Report"])};fn.source="Aging Report";return fn;})(),
      "as_of": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["As of ", _interpolate(_named("time"))])};fn.source="As of {time}";return fn;})(),
      "summary": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Summary (", _interpolate(_named("currency")), ")"])};fn.source="Summary ({currency})";return fn;})(),
      "total_outstanding": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total Outstanding Invoice: ", _interpolate(_named("outstanding"))])};fn.source="Total Outstanding Invoice: {outstanding}";return fn;})(),
      "n_outstanding": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Outstanding Invoices"])};fn.source="{n} Outstanding Invoices";return fn;})(),
      "within_period": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Within ", _interpolate(_named("from")), " - ", _interpolate(_named("to")), " days"])};fn.source="Within {from} - {to} days";return fn;})(),
      "over_period": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Over ", _interpolate(_named("from")), " days"])};fn.source="Over {from} days";return fn;})(),
      "list_summary_within": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " of ", _interpolate(_named("total")), " of aging report within ", _interpolate(_named("from")), " - ", _interpolate(_named("to")), " days"])};fn.source="{n} of {total} of aging report within {from} - {to} days";return fn;})(),
      "list_summary_over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " of ", _interpolate(_named("total")), " of aging report over ", _interpolate(_named("from")), " days"])};fn.source="{n} of {total} of aging report over {from} days";return fn;})(),
      "temp_notes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Notes"])};fn.source="Temporary Notes";return fn;})(),
      "temp_notes_placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your note here..."])};fn.source="Type your note here...";return fn;})(),
      "delete_temp_notes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Temporary Notes"])};fn.source="Delete Temporary Notes";return fn;})(),
      "delete_temp_notes_hint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this temporary notes?"])};fn.source="Are you sure you want to delete this temporary notes?";return fn;})()
    },
    "action": {
      "download_as_pdf": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download as PDF"])};fn.source="Download as PDF";return fn;})(),
      "edit_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Invoice"])};fn.source="Edit Invoice";return fn;})(),
      "view_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Invoice"])};fn.source="View Invoice";return fn;})(),
      "delete_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Invoice"])};fn.source="Delete Invoice";return fn;})(),
      "delete_invoice_desc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? This process cannot be undone."])};fn.source="Are you sure you want to delete this? This process cannot be undone.";return fn;})(),
      "duplicate_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Invoice"])};fn.source="Duplicate Invoice";return fn;})(),
      "resend_invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Invoice"])};fn.source="Resend Invoice";return fn;})(),
      "change_status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Invoice Status"])};fn.source="Change Invoice Status";return fn;})(),
      "add_temporary_notes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Temporary Notes"])};fn.source="Add Temporary Notes";return fn;})(),
      "export_as": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export As"])};fn.source="Export As";return fn;})(),
      "export_all_as": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Export all as ", _interpolate(_named("format"))])};fn.source="Export all as {format}";return fn;})(),
      "export_selection_as": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Export selection as ", _interpolate(_named("format"))])};fn.source="Export selection as {format}";return fn;})()
    }
  }
}