import AuthRepository from "../../data/repository/auth-repository";
import InvoiceRepository from "../../data/repository/invoice-repository";
import { logEvent } from "firebase/analytics";
import baseStore from "../base-store";

const initialInvoice = {
  _id: null,
  customer_id: null,
  customer: {},
  title: null,
  tax_id: "",
  tax_type: null,
  tax_name: null,
  tax_rate: 0,
  tax_amount: 0,
  date: new Date().toUTCString(),
  currency: "MYR",
  payment_term_date: "",
  type: "one_time_payment",
  allow_partial: false,
  ref2: null,
  memo: "",
  internal_memo: "",
  footer: "",
  custom_attributes: [{ key: "", value: "" }],
  items: [],
  subtotal: 0,
  total_amount: 0,
};

export default {
  namespaced: true,
  state: {
    ...baseStore.state,

    invoice: { ...initialInvoice },
    invoiceHistory: []
  },

  getters: {
    ...baseStore.getters,

    invoice(state) {
      return state.invoice;
    },
    invoiceHistory(state) {
      return state.invoiceHistory
    }
  },

  mutations: {
    ...baseStore.mutations,

    initInvoice(state, invoice = initialInvoice) {
      state.invoice = this.$lodash.cloneDeep(invoice);
    },
    setInvoice(state, invoice) {
      state.invoice = invoice;
    },
    setInvoiceHistory(state, invoiceHistory) {
      state.invoiceHistory = invoiceHistory
    }
  },

  actions: {
    async retrieveInvoices(state, filters) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);
      const invoiceIndexResult = await invoiceRepository.indexInvoice(
        business_id,
        filters
      );

      if (invoiceIndexResult.message != undefined) {
        logEvent(this.$analytics, "invoice-index", { result: "failed" });
        state.commit("setErrorMessage", invoiceIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "invoice-index", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", invoiceIndexResult);
      return invoiceIndexResult;
    },

    async getSingleInvoice(state, invoiceId) {
      const invoiceRepository = new InvoiceRepository();

      state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.getSingleInvoice(
        invoiceId
      );

      if (invoiceResult.message != undefined) {
        logEvent(this.$analytics, "get-invoice", { result: "failed" });
        state.commit("setErrorMessage", invoiceResult);
      } else {
        logEvent(this.$analytics, "get-invoice", { result: "success" });
        state.commit("setInvoice", invoiceResult.data ?? {});
      }

      state.commit("setLoading", false);
      return invoiceResult;
    },

    async createOrUpdateInvoice(state, invoice_form) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id === null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(invoice_form).forEach((key) => {
        if (invoice_form[key] === "") {
          delete invoice_form[key];
        }
      });

      const invoiceResult = await invoiceRepository.createOrUpdateInvoice(
        business_id,
        invoice_form
      );

      const isCreate = invoice_form._id === null;

      logEvent(
        this.$analytics,
        isCreate ? "create-invoice" : "update-invoice",
        { result: invoiceResult.message ? "failed" : "success" }
      );

      if (invoiceResult.message) {
        state.commit("setErrorMessage", invoiceResult);
        return;
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return invoiceResult;
    },

    async resetInvoice(state) {
      state.commit("initInvoice");
    },

    async getInvoiceById(state, invoiceId) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const businessId = authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      // state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.getInvoiceById(
        businessId,
        invoiceId
      );
      if (invoiceResult.message != undefined) {
        logEvent(this.$analytics, "get-invoice", { result: "failed" });
        state.commit("setErrorMessage", invoiceResult);
      } else {
        logEvent(this.$analytics, "get-invoice", { result: "success" });
        state.commit("setInvoice", invoiceResult.data ?? {});
      }

      state.commit("setLoading", false);
      return invoiceResult;
    },

    async downloadInvoicePdf(state, payload) {
      const invoiceRepository = new InvoiceRepository();

      state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.downloadInvoicePdf(
        payload.businessId,
        payload.invoiceId
      );

      logEvent(this.$analytics, "download-invoice", {
        result: invoiceResult.message ? "failed" : "success",
      });

      state.commit("setLoading", false);

      window.open(
        invoiceResult.data.url,
        "_blank"
      );
    },

    async deleteInvoice(state, invoice) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.deleteInvoice(
        business_id,
        invoice
      );

      if (invoiceResult.message != undefined) {
        logEvent(this.$analytics, "delete-invoice", { result: "failed" });
        state.commit("setErrorMessage", invoiceResult);
      } else {
        logEvent(this.$analytics, "delete-invoice", { result: "success" });
      }

      state.commit("setLoading", false);

      return invoiceResult;
    },

    async getInvoiceHistory(state, invoiceId) {

      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();
      const businessId = authRepository.getSelectedRole()?.business_id;
      if (businessId == null) {
        return;
      }
      state.commit("setLoading", true);
      const invoiceResult = await invoiceRepository.getInvoiceHistory(
        businessId,
        invoiceId
      );

      logEvent(this.$analytics, "invoice-history", {
        result: invoiceResult.message ? "failed" : "success",
      });

      state.commit("setInvoiceHistory", invoiceResult.data ?? []);
      state.commit("setLoading", false);

      return invoiceResult;
    },
  },
};
