<template>
  <app-modal class="rounded-md" :showCancelButton="false">
    <template v-slot:title>
      <div class="flex flex-col justify-between text-base px-1 space-y-1">
        <p>{{ $t("tax.attach_tax") }}</p>
        <p class="text-sm text-gray-400">
          {{ $t("tax.attach_tax_description") }}
        </p>
      </div>
    </template>
    <template v-slot:body>
      <app-modal-add-tax
        :show="isEditing"
        @close="isEditing = false"
        @success="handleTaxChanged"
      />
      <div class="space-y-5">
        <app-form-select-custom-item
          :datas="taxes"
          :loading="apiPaginationLoading"
          :showLabel="true"
          :labelText="$t('tax.select_title')"
          :placeholder="$t('tax.select_description')"
          :allowFilter="true"
          :allowButton="true"
          :buttonText="$t('tax.add_new_tax')"
          :showListTitle="true"
          :listTitleText="$t('tax.tax_list_title')"
          v-model="selectedTax"
          @retry="getTaxes"
          @button-click="displayTaxForm"
          @valueSelected="handleTaxChanged"
          @filter="handleFilterTextChanged"
        >
          <template #item="{ item }">
            <div class="flex flex-row space-x-2 border py-3 px-3 items-center">
              <span class="block truncate flex-grow">
                {{ item.name }}
              </span>
              <span class="block truncate text-gray-400">
                {{ item.rate }}%
              </span>
            </div>
          </template>
        </app-form-select-custom-item>
        <app-form-radio
          class="text-sm"
          :label="$t('tax.type_description')"
          :options="typeOpts"
          v-model="selectedType"
        />
      </div>
    </template>
    <template v-slot:action>
      <app-button class="w-full" @click="handleSubmit()" :showf70Icon="false">
        {{ $t("tax.attach_tax") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import { MAX_DROPDOWN_ITEMS } from "@/utils/const/invoice_settings";
import TAX_TYPE from "@/utils/const/tax_type";

export default {
  emits: ["success"],

  data() {
    return {
      isEditing: false,
      typeOpts: [TAX_TYPE.INCLUSIVE, TAX_TYPE.EXCLUSIVE].map((id) => ({
        id,
        name: this.$t(`tax.types.${id}`),
      })),
      selectedTax: null,
      selectedType: TAX_TYPE.INCLUSIVE,
      filterText: "",
    };
  },

  computed: {
    taxes() {
      return this.$store.getters["taxStore/apiPaginationResponse"]?.data?.data;
    },
    apiPaginationLoading() {
      return this.$store.getters["taxStore/apiPaginationLoading"];
    },
  },

  async mounted() {
    await this.getTaxes();
  },

  methods: {
    async getTaxes() {
      const filters = { page: 1, per_page: MAX_DROPDOWN_ITEMS };
      if (this.filterText) {
        filters["filter[name]"] = this.filterText;
      }
      await this.$store.dispatch("taxStore/retrieveTax", filters);
    },
    handleFilterTextChanged(filterText) {
      this.filterText = filterText;
      this.getTaxes();
    },
    handleTaxChanged(tax) {
      this.selectedTax = tax;
    },
    handleSubmit() {
      if (this.selectedTax) {
        const retval = { tax: this.selectedTax, type: this.selectedType };
        this.selectedTax = null;
        this.selectedType = TAX_TYPE.INCLUSIVE;
        if (this.filterText) {
          this.filterText = "";
          this.getTaxes();
        }
        this.$emit("success", retval);
      }
    },
    displayTaxForm() {
      this.isEditing = true;
    },
  },
};
</script>