<template>
  <!-- Select Date -->
  <div class="flex mt-5 sm:mt-0">
    <!-- Daily -->
    <app-button-outline
      class="md:flex-none flex-1 rounded-r-none px-8 py-2 text-xs"
      :active="active_type == $t('general.daily')"
      @onClick="select_date($t('general.daily'))"
    >
      {{ $t("general.daily") }}
    </app-button-outline>
    <!-- Weekly -->
    <app-button-outline
      class="
        px-8
        py-2
        md:flex-none
        flex-1
        border-l-0
        rounded-r-none rounded-l-none
      "
      :active="active_type == $t('general.weekly')"
      @onClick="select_date($t('general.weekly'))"
    >
      {{ $t("general.week_to_date") }}
    </app-button-outline>
    <!-- Monthly -->
    <app-button-outline
      class="
        px-8
        py-2
        md:flex-none
        flex-1
        border-l-0 border-r-0
        rounded-r-none rounded-l-none
      "
      :active="active_type == $t('general.monthly')"
      @onClick="select_date($t('general.monthly'))"
    >
      {{ $t("general.month_to_date") }}
    </app-button-outline>
    <!-- Yearly -->
    <app-button-outline
      class="md:flex-none flex-1 rounded-l-none px-8 py-2"
      :active="active_type == $t('general.yearly')"
      @onClick="select_date($t('general.yearly'))"
    >
      {{ $t("general.year_to_date") }}
    </app-button-outline>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active_type: this.$t("general.daily"),
    };
  },
  mounted() {
    this.select_date(this.active_type);
  },
  methods: {
    select_date(type) {
      let dateFrom = this.$moment().startOf("day");
      let dateTo = this.$moment().endOf("day").add(1, "days");

      switch (type) {
        case this.$t("general.yearly"):
          dateFrom = this.$moment().startOf("year");
          dateTo = this.$moment().endOf("year");
          break;
        case this.$t("general.monthly"):
          dateFrom = this.$moment().startOf("month");
          dateTo = this.$moment().endOf("month");
          break;
        case this.$t("general.weekly"):
          dateFrom = this.$moment().startOf("isoWeek");
          dateTo = this.$moment().endOf("isoWeek");
          break;
      }

      this.active_type = type;

      let date = {
        from: dateFrom.format("YYYY-MM-DD"),
        to: dateTo.format("YYYY-MM-DD"),
      };

      let date_format = date.from + "," + date.to;

      this.$emit("select_date_format", date_format);
      this.$emit("select_date_type", type);
      this.$emit("select_date", date);
      this.$emit("on_select");
    },
  },
};
</script>
