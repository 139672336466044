<template>
  <div class="bg-white border-b">
    <div class="container mx-auto py-3 px-5">
      <div class="flex justify-between items-center">
        <div class="flex flex-col xl:flex-row w-full">
          <div
            class="
              md:flex md:space-x-2
              flex-wrap
              gap-2
              grid grid-cols-2
              sm:grid-cols-3
              md:grid-cols-4
            "
          >
            <div v-for="item in navigation_left_menu()" :key="item.route_name">
              <app-button-menu
                v-if="item.subMenus == undefined"
                :showToolTip="getSelectedRoleId() != ROLE.USER"
                :active="$route.name?.startsWith(item.route_name)"
                :disabled="!item.disabled && item.disabled != null"
                @onMenuClick="clickMenu(item.route_name, item.redirect_url)"
                class="items-center"
              >
                <app-icon
                  :name="item.icon"
                  class="sm:h-5 sm:w-5 flex-none h-4 w-4"
                />
                <span class="ml-2">{{ item.title }}</span>
              </app-button-menu>
              <app-button-dropdown
                v-else
                :showToolTip="getSelectedRoleId() != ROLE.USER"
                :disabled="!item.disabled && item.disabled != null"
                :showArrow="false"
                margin="mt-2"
                position="left"
              >
                <template v-slot="{ is_open }">
                  <app-button-menu
                    class="items-center"
                    :active="$route.name?.startsWith(item.route_name)"
                  >
                    <app-icon
                      :name="item.icon"
                      class="sm:h-5 sm:w-5 flex-none h-4 w-4"
                    />
                    <span class="ml-2 mr-2">{{ item.title }}</span>
                    <app-icon
                      v-if="is_open"
                      name="ChevronUpIcon"
                      class="sm:h-5 sm:w-5 flex-none h-4 w-4"
                    />
                    <app-icon
                      v-else
                      name="ChevronDownIcon"
                      class="sm:h-5 sm:w-5 flex-none h-4 w-4"
                    />
                  </app-button-menu>
                </template>
                <template #menu-items>
                  <app-button-menu
                    v-for="subMenu in item.subMenus"
                    :key="subMenu.id"
                    :rounded="false"
                    @onMenuClick="
                      clickMenu(subMenu.route_name, subMenu.redirect_url)
                    "
                    :active="$route.name.startsWith(subMenu.route_name)"
                    class="items-center w-full"
                  >
                    <span class="ml-2">{{ subMenu.title }}</span>
                  </app-button-menu>
                </template>
              </app-button-dropdown>
            </div>
          </div>
          <hr
            v-if="navigation_right_menu()?.length > 0"
            class="my-3 md:hidden"
          />
          <div
            class="
              md:flex md:flex-auto md:space-x-2 md:justify-end
              grid grid-cols-2
              sm:grid-cols-3
            "
          >
            <app-button-menu
              v-for="item in navigation_right_menu()"
              :key="item.route_name"
              :active="$route.name?.startsWith(item.route_name)"
              @onMenuClick="clickMenu(item.route_name)"
              :disabled="!item.disabled && item.disabled != null"
              :showToolTip="getSelectedRoleId() != ROLE.USER"
              :toolTipMessage="item.tooltip"
            >
              <app-icon :name="item.icon" class="h-5 w-5 flex-none" />
              <span class="ml-2">{{ item.title }}</span>
            </app-button-menu>
            <app-form-switch
              v-model="testMode"
              :loading="loading"
              class="text-litepie-secondary-500"
              v-if="
                [
                  ROLE.BUSINESS_OWNER,
                  ROLE.BUSINESS_ADMIN,
                  ROLE.BUSINESS_DEVELOPER,
                  ROLE.MASTER_BUSINESS_OWNER,
                  ROLE.MASTER_BUSINESS_ADMIN,
                  ROLE.MASTER_BUSINESS_DEVELOPER,
                ].includes(this.getSelectedRoleId()) ||
                [ROLE.OPERATION, ROLE.SUPPORT].includes(
                  this.getSelectedRoleId()
                )
              "
            >
              {{ $t("layout.navbar.test_mode") }}
            </app-form-switch>
            <app-form-switch
              :disabled="testMode"
              @click="createBusinessModal(event)"
              class="text-litepie-secondary-500"
              v-if="[ROLE.USER].includes(this.getSelectedRoleId())"
            >
              {{ $t("layout.navbar.test_mode") }}
            </app-form-switch>
          </div>
        </div>
      </div>
    </div>

    <!--Test Mode Modal-->
    <app-modal :show="show_modal_testmode" @close="show_modal_testmode = false">
      <template v-slot:title>{{
        $t("business.modal.create_business")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("business.modal.create_description") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button
          @click="createBusiness()"
          :showf70Icon="false"
          type="button"
          class="sm:ml-3 sm:w-auto"
        >
          {{ $t("business.modal.create_button") }}
        </app-button>
      </template>
    </app-modal>

    <transition name="fadeHeight">
      <div
        class="flex h-7 bg-test-mode items-center justify-center"
        v-if="testMode && !loading"
      >
        <p class="text-white text-sm">
          {{ $t("general.test_mode_environment") }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import ROLE from "@/utils/const/role";
import BUSINESS_STATUS from "@/utils/const/business_status";

export default {
  computed: {
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    testMode: {
      get() {
        return this.$store.getters["businessStore/test_mode"];
      },
      set(value) {
        this.$store.dispatch("businessStore/setTestMode", value);
        setTimeout(() => {
          window.location.href = this.$route.path;
        }, 100);
      },
    },
  },
  data() {
    return {
      loading: false,
      ROLE: ROLE,
      BUSINESS_STATUS: BUSINESS_STATUS,
      show_modal_testmode: false,
     // testMode: false,
    };
  },

  methods: {
    checkIfUserBusiness(modelHasRole) {
      if (modelHasRole == null || modelHasRole.role_id == undefined)
        return ROLE.USER;
    },
    clickMenu(name, redirect_url = null) {
      if (redirect_url != null) {
        window.open(redirect_url, "_blank");
        return;
      }

      this.$router.push({ name: name });
    },
    navigation_left_menu() {
      if (this.selectedRole?.role_id == ROLE.ADMIN) {
        // ROLE ADMIN
        return [
          {
            title: this.$t("layout.navbar.list_user"),
            route_name: "user.index",
            icon: "UserIcon",
          },
          {
            title: this.$t("layout.navbar.audit_trail"),
            route_name: "audit_trail.index",
            icon: "ClipboardListIcon",
          },
          {
            title: this.$t("layout.navbar.telescope"),
            route_name: null,
            icon: "ServerIcon",
            redirect_url:
              this.$store.$backendURL +
              "/redirect/admin?service=telescope&token=" +
              this.$store.getters["authStore/adminToken"],
          },
          {
            title: this.$t("layout.navbar.cron"),
            route_name: "cron.index",
            icon: "TerminalIcon",
          },
        ];
      } else if (this.selectedRole?.role_id == ROLE.OPERATION) {
        // ROLE OPERATION
        return [
          {
            title: this.$t("layout.navbar.dashboard"),
            route_name: "dashboard",
            icon: "HomeIcon",
          },
          {
            title: this.$t("layout.navbar.list_business"),
            route_name: "business.index",
            icon: "BriefcaseIcon",
          },
        ];
      } else if (this.selectedRole?.role_id == ROLE.ACCOUNT) {
        // ROLE ACCOUNT
        return [
          {
            title: this.$t("layout.navbar.dashboard"),
            route_name: "dashboard",
            icon: "HomeIcon",
          },
          {
            title: this.$t("layout.navbar.list_payout"),
            route_name: "account.payout.index",
            icon: "CashIcon",
          },
        ];
      } else if (this.selectedRole?.role_id == ROLE.SUPPORT) {
        // ROLE SUPPORT
        return [
          {
            title: this.$t("layout.navbar.payment"),
            route_name: "support.payment.link.index",
            icon: "CashIcon",
          },
          {
            title: this.$t("layout.navbar.balance"),
            route_name: "support.balance",
            icon: "CashIcon",
          },
        ];
      } else if (
        [
          ROLE.BUSINESS_OWNER,
          ROLE.BUSINESS_ADMIN,
          ROLE.BUSINESS_MANAGER,
          ROLE.BUSINESS_DEVELOPER,
        ].includes(this.selectedRole?.role_id)
      ) {
        // ROLE BUSINESS
        return [
          {
            title: this.$t("layout.navbar.dashboard"),
            route_name: "dashboard",
            icon: "HomeIcon",
          },
          {
            title: this.$t("layout.navbar.payment"),
            route_name: "payment",
            icon: "CreditCardIcon",
            disabled: [
              ROLE.BUSINESS_OWNER,
              ROLE.BUSINESS_ADMIN,
              ROLE.BUSINESS_MANAGER,
              ROLE.BUSINESS_DEVELOPER,
              ROLE.BUSINESS_SUPPORT,
            ].includes(this.getSelectedRoleId()),
            subMenus: [
              {
                title: this.$t("layout.navbar.payment_link"),
                route_name: "payment-link",
              },
              {
                title: this.$t("layout.navbar.open_payment_link"),
                route_name: "payment-open-link",
              },
              {
                title: this.$t("layout.navbar.setting"),
                route_name: "payment-settings",
              },
            ],
          },
          {
            title: this.$t("layout.navbar.balance"),
            route_name: "balance",
            icon: "CashIcon",
            disabled: [ROLE.BUSINESS_OWNER, ROLE.BUSINESS_MANAGER].includes(
              this.getSelectedRoleId()
            ),
          },
          // {
          //   title: this.$t("layout.navbar.subscription"),
          //   route_name: "subscription",
          //   icon: "CollectionIcon",
          // },
          // {
          //   title: this.$t("layout.navbar.more"),
          //   route_name: null,
          //   icon: "ViewGridIcon",
          //   disabled: [
          //     ROLE.BUSINESS_OWNER,
          //     ROLE.BUSINESS_ADMIN,
          //     ROLE.BUSINESS_MANAGER,
          //     ROLE.BUSINESS_DEVELOPER,
          //     ROLE.BUSINESS_SUPPORT,
          //   ].includes(this.getSelectedRoleId()),
          //   subMenus: [
          //     {
          //       title: this.$t("layout.navbar.customer"),
          //       route_name: "customer",
          //     },
          //     {
          //       title: this.$t("layout.navbar.product"),
          //       route_name: "product.index",
          //     },
          //     {
          //       title: this.$t("layout.navbar.tax"),
          //       route_name: "tax.index",
          //     },
          //   ],
          // },
          // {
          //   title: this.$t("layout.navbar.customer"),
          //   route_name: "customer",
          //   icon: "UsersIcon",
          //   disabled: [
          //     ROLE.BUSINESS_OWNER,
          //     ROLE.BUSINESS_ADMIN,
          //     ROLE.BUSINESS_MANAGER,
          //     ROLE.BUSINESS_DEVELOPER,
          //   ].includes(this.getSelectedRoleId()),
          // },
          // {
          //   title: this.$t("layout.navbar.products"),
          //   route_name: "products",
          //   icon: "CollectionIcon",
          //   disabled: [
          //     ROLE.BUSINESS_OWNER,
          //     ROLE.BUSINESS_ADMIN,
          //     ROLE.BUSINESS_MANAGER,
          //     ROLE.BUSINESS_DEVELOPER,
          //   ].includes(this.getSelectedRoleId()),
          // },
          // {
          //   title: this.$t("layout.navbar.report"),
          //   route_name: "report",
          //   icon: "ChartBarIcon",
          //   disabled: [
          //     ROLE.BUSINESS_OWNER,
          //     ROLE.BUSINESS_ADMIN,
          //     ROLE.BUSINESS_MANAGER,
          //     ROLE.BUSINESS_DEVELOPER,
          //   ].includes(this.getSelectedRoleId()),
          // },
          // {
          //   title: this.$t("layout.navbar.link"),
          //   route_name: "link",
          //   icon: "PuzzleIcon",
          //   disabled: [
          //     ROLE.BUSINESS_OWNER,
          //     ROLE.BUSINESS_ADMIN,
          //     ROLE.BUSINESS_MANAGER,
          //     ROLE.BUSINESS_DEVELOPER,
          //   ].includes(this.getSelectedRoleId()),
          // },
        ];
      } else if (
        [
          ROLE.MASTER_BUSINESS_OWNER,
          ROLE.MASTER_BUSINESS_ADMIN,
          ROLE.MASTER_BUSINESS_MANAGER,
          ROLE.MASTER_BUSINESS_DEVELOPER,
          ROLE.MASTER_BUSINESS_SUPPORT,
        ].includes(this.selectedRole?.role_id)
      ) {
        return [
          {
            title: this.$t("layout.navbar.dashboard"),
            route_name: "dashboard",
            icon: "HomeIcon",
          },
          {
            title: this.$t("layout.navbar.list_business"),
            route_name: "master-business.list-business",
            icon: "BriefcaseIcon",
          },
          {
            title: this.$t("layout.navbar.payment"),
            route_name: "payment-link",
            icon: "CreditCardIcon",
            disabled: [
              ROLE.BUSINESS_OWNER,
              ROLE.BUSINESS_ADMIN,
              ROLE.BUSINESS_MANAGER,
              ROLE.BUSINESS_DEVELOPER,
              ROLE.BUSINESS_SUPPORT,
            ].includes(this.getSelectedRoleId()),
            subMenus: [
              {
                title: this.$t("layout.navbar.payment_link"),
                route_name: "payment-link",
              },
              {
                title: this.$t("layout.navbar.open_payment_link"),
                route_name: "payment-open-link",
              },
            ],
          },
          {
            title: this.$t("layout.navbar.balance"),
            route_name: "balance",
            icon: "CashIcon",
          },
        ];
      } else {
        return [
          {
            title: this.$t("layout.navbar.dashboard"),
            route_name: "dashboard",
            icon: "HomeIcon",
          },
        ];
      }
    },

    navigation_right_menu() {
      if (this.selectedRole?.role_id == ROLE.ADMIN) {
        // ROLE ADMIN
        return [];
      } else if (this.selectedRole?.role_id == ROLE.OPERATION) {
        // ROLE OPERATION
        return [];
      } else if (this.selectedRole?.role_id == ROLE.SUPPORT) {
        // ROLE SUPPORT
        return [];
      } else if (this.selectedRole?.role_id == ROLE.ACCOUNT) {
        // ROLE ACCOUNT
        return [];
      } else if (
        [
          ROLE.MASTER_BUSINESS_OWNER,
          ROLE.MASTER_BUSINESS_ADMIN,
          ROLE.MASTER_BUSINESS_MANAGER,
          ROLE.MASTER_BUSINESS_DEVELOPER,
        ].includes(this.selectedRole?.role_id)
      ) {
        // ROLE MASTER MERCHANT
        return [
          {
            title: this.$t("layout.navbar.integration"),
            route_name: "integration",
            icon: "CodeIcon",
            tooltip: this.$t("integration.menu.please_activate_business"),
            disabled: [
              ROLE.MASTER_BUSINESS_OWNER,
              ROLE.MASTER_BUSINESS_ADMIN,
              ROLE.MASTER_BUSINESS_DEVELOPER,
            ].includes(this.getSelectedRoleId()),
          },
        ];
      } else if (
        [
          ROLE.BUSINESS_OWNER,
          ROLE.BUSINESS_ADMIN,
          ROLE.BUSINESS_MANAGER,
          ROLE.BUSINESS_DEVELOPER,
        ].includes(this.selectedRole?.role_id) 
      ) {
        // ROLE BUSINESS
        return [
          // {
          //   title: this.$t("layout.navbar.tutorial"),
          //   route_name: "tutorial",
          //   icon: "AcademicCapIcon",
          //   disabled: [
          //     ROLE.BUSINESS_OWNER,
          //     ROLE.BUSINESS_ADMIN,
          //     ROLE.BUSINESS_MANAGER,
          //     ROLE.BUSINESS_DEVELOPER,
          //   ].includes(this.getSelectedRoleId()),
          // },
          {
            title: this.$t("layout.navbar.integration"),
            route_name: "integration",
            icon: "CodeIcon",
            tooltip: this.$t("integration.menu.please_activate_business"),
            disabled:
              [
                ROLE.BUSINESS_OWNER,
                ROLE.BUSINESS_ADMIN,
                ROLE.BUSINESS_DEVELOPER,
              ].includes(this.getSelectedRoleId()) &&
              this.business.business_status_id == BUSINESS_STATUS.APPROVED ||
              this.testMode == true && this.business.business_status_id != BUSINESS_STATUS.APPROVED
          },
        ];
      } else {
        return [];
      }
    },

    getSelectedRoleId() {
      if (this.selectedRole == null || this.selectedRole?.role_id == undefined)
        return ROLE.USER;

      return this.selectedRole.role_id;
    },
    hasRole(roles) {
      return this.$store.getters["authStore/hasRole"](roles);
    },
    roles() {
      return this.$store.getters["authStore/roles"];
    },

    createBusiness() {
      this.$router.push({ path: "/business/structure" });
    },

    createBusinessModal(event) {
      if (event) {
        event.preventDefault();
      }
      this.show_modal_testmode = true;
    },

    testModeIntegration() {
      return this.$store.getters["authStore/test_mode"] ?? false;
    }
  },
};
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.4s;
  max-height: 230px;
}
.fadeHeight-enter-from,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>