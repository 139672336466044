<template>
  <!-- Tabs -->
  <div class="flex flex-row mb-5 md:px-0 px-3 z-0" v-if="tabs?.length > 0">
    <app-button-border-bottom v-for="item in tabs" :key="item.key" :active="isCurrentTabName(item.key)"
      @onClick="onTabClick(item.key)">
      {{ item.title }}
    </app-button-border-bottom>
    <span v-if="tabs?.length > 0" class="flex-1 border-b-2 w-full" />
  </div>

  <div class="flex flex-col md:px-0 px-3 py-3 z-0">
    <!-- Menu Button -->
    <div class="flex justify-between space-x-2 border-b p-3">
      <div class="flex items-center">
        <slot name="title" />
      </div>
      <div class="flex items-center space-x-2">
        <slot name="button" />
        <app-button width="w-24" v-if="filters.length > 0" :disabled="loading" :primary="false" :showf70Icon="false"
          @click="toggleFilter" ref="filterButton">
          <template v-slot:icon>
            <app-icon name="FilterIcon" v-if="activeFiltersNo > 0" class="h-4 w-4 mr-2 text-gray-500" />
            <app-icon-outline name="FilterIcon" v-else class="h-4 w-4 mr-2 text-gray-500" />
          </template>
          <template v-slot:default>
            {{ $t("datatable.filter") }}
          </template>
        </app-button>
      </div>
    </div>

    <!-- Filter -->
    <transition name="bounce">
      <div v-show="showFilter" class="flex flex-col bg-gray-200 pt-6 pb-8 pr-4 pl-4 mt-2 items-center">
        <div class="flex space-x-3 items-center self-start ml-2 text-sm">
          <p class="flex-none">{{ $t("datatable.active_filters") }}</p>
          <div class="bg-gray-300 px-3 rounded-r-xl rounded-l-xl h-5">
            {{ activeFiltersNo }}
          </div>

          <app-button class="ml-1 self-start cursor-pointer text-sm text-gray-600" @click="resetFilter"
            :showf70Icon="false" :primary="false">
            {{ $t("datatable.clear_filter") }}
          </app-button>
        </div>

        <div class="
            w-full
            grid grid-cols-1
            md:grid-cols-2
            xl:grid-cols-3
            gap-2
            md:place-content-between
          " v-show="renderInput">
          <div v-for="filter in filters" :key="filter.key">
            <app-form-input v-if="filter.type === 'text' || filter.type === 'number'" inputclass="
                      w-full
                      h-10
                      pl-2
                      pr-2
                      text-sm
                    " :placeholder="filter.placeholder" :type="filter.type" :key="filter.key + keyInt"
              v-model="filterDatas[filter.key]" />

            <app-form-calendar-range v-if="filter.type === 'date'" :key="filter.key + keyInt"
              v-model="filterDatas[filter.key]" />

            <app-form-select v-if="filter.type === 'select'" inputclass="ring-0 pb-0" :key="filter.key + keyInt"
              :placeholder="filter.placeholder" :datas="filter.items" :showicon="false"
              v-model="filterDatas[filter.key]" />
          </div>
        </div>
      </div>
    </transition>

    <!-- Table -->
    <div class="flex justify-center transition-all delay-300">
      <!-- Loading -->
      <div class="w-full h-full bg-white flex flex-col items-center justify-center py-28"
        :class="[loading ? 'cursor-wait' : 'cursor-default']" v-if="loading">
        <app-icon-loading iconColor="black" customClass="h-14 w-14" />
        <span class="mt-4 text-lg">{{ $t("datatable.loading_data") }}</span>
      </div>
      <!-- Data Empty -->
      <div v-else-if="isDataEmpty" class="flex h-64 m-20 justify-center items-center">
        <div class="flex flex-col items-center">
          <component class="mb-4" :is="emptyIcon" />
          <span class="text-center font-bold text-lg mt-4">
            {{ emptyTitleText }}
          </span>
          <span class="text-center mt-1 mb-6 w-96">
            {{ emptyDescriptionText }}
          </span>
          <app-button width="w-80" @click="emptyButtonClicked" :showf70Icon="false" :loading="emptyButtonLoading"
            v-if="showEmptyButton">
            <template v-slot:icon>
              <app-icon class="h-5 w-5 mr-2" name="PlusIcon" />
            </template>
            <template v-slot:default>{{ emptyButtonText }}</template>
          </app-button>
        </div>
      </div>
      <!-- Data Table -->
      <div v-else class="align-middle inline-block min-w-full">
        <div class="flex flex-col">
          <div class="overflow-x-auto mt-2">
            <table class="
                min-w-full
                divide-y divide-divider-gray
                border-b border-divider-gray
              ">
              <thead class="bg-white">
                <tr>
                  <th v-if="selectable">
                    <app-form-checkbox v-model="allSelected" @onClick="selectAll" />
                  </th>
                  <slot name="header" />
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-divider-gray">
                <tr v-for="model in apiResponse.data" :key="model.id" class="hover:bg-gray-50 cursor-pointer"
                  @click="$emit('on-item-click', model)">
                  <td v-if="selectable">
                    <app-form-checkbox @click.stop :value="model" v-model="selected" />
                  </td>
                  <slot name="body" :model="model" />
                </tr>
              </tbody>
            </table>
          </div>
          <div class="
              lg:relative
              flex
              lg:inline-flex
              flex-col
              justify-center
              items-center
              mt-6
            ">
            <div class="lg:flex-row inline-flex flex-wrap">
              <button :disabled="!activePreviousButton" class="
                  bg-white
                  p-1
                  rounded-l-lg
                  border border-divider-gray
                  hover:bg-gray-200
                  disabled:opacity-60
                " :class="[
                  !activePreviousButton
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer',
                ]" @click="previousPageClicked">
                <ChevronLeftIcon class="h-6 w-6 m-1 mr-2 ml-2" :class="[
                  !activePreviousButton ? 'text-gray-400' : 'text-black',
                ]" />
              </button>

              <button v-for="(page, key) in pageRange" :key="key" class="h-8 w-8 m-2 rounded-full" :class="[
                page == currentPage
                  ? 'bg-primary text-white'
                  : 'bg-transparent text-black',
                'bg-transparent text-black',
                page === '...' ? '' : 'hover:bg-primary hover:text-white',
              ]" @click="paginationButtonClicked(page)">
                {{ page }}
              </button>

              <button :disabled="!activeNextButton" class="
                  bg-white
                  p-1
                  rounded-r-lg
                  border border-divider-gray
                  hover:bg-gray-200
                  disabled:opacity-60
                " :class="[
                  !activeNextButton ? 'cursor-not-allowed' : 'cursor-pointer',
                ]" @click="nextPageClicked">
                <ChevronRightIcon class="h-6 w-6 m-1 mr-2 ml-2"
                  :class="[!activeNextButton ? 'text-gray-400' : 'text-black']" />
              </button>
            </div>

            <div class="lg:absolute lg:right-0 flex-col text-xs space-x-3">
              <span>{{ $t("datatable.show") }}</span>
              <select class="
                  md:mt-0
                  mt-5
                  rounded-md
                  outline-none
                  border-gray-200
                  focus:border-primary focus:ring-primary
                  text-left
                  transition
                  ease-in-out
                  duration-150
                  text-xs sm:leading-5
                " @change="paginationPerPageSelected($event)">
                <option value="15" :selected="filterDatas.per_page == 15">
                  15
                </option>
                <option value="25" :selected="filterDatas.per_page == 25">
                  25
                </option>
                <option value="50" :selected="filterDatas.per_page == 50">
                  50
                </option>
                <option value="100" :selected="filterDatas.per_page == 100">
                  100
                </option>
              </select>
              <span class="ml-1">{{ $t("datatable.per_page") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  emits: [
    "pagination-per-page-selected",
    "pagination-change-page",
    "empty-button-clicked",
    "select-table-changed",
    "params-changed",
    "tab-changed",
    "on-item-click",
  ],
  data() {
    return {
      selected: [],
      renderInput: true,
      allSelected: false,
      showFilter: false,
      activeFiltersNo: 0,
      filterDatas: {
        page: 1,
        per_page: 15,
      },
      queryParams: "",
      firstRender: true,
      keyInt: 0,
    };
  },

  components: {
    ChevronRightIcon,
    ChevronLeftIcon,
  },

  props: {
    emptyIcon: {
      type: String,
      default: "app-icon-empty-placeholder",
    },
    emptyTitle: {
      type: String,
      default: "",
    },
    emptyDescription: {
      type: String,
      default: "",
    },
    emptyButton: {
      type: String,
      default: "",
    },
    showEmptyButton: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    apiResponse: {
      type: Object,
      default: () => { },
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 4,
    },
    emptyButtonLoading: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    loadFilterFromUrl: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },

  beforeMount() {
    if (this.loadFilterFromUrl) {
      var filterNo = 0;
      for (const key in this.$route.query) {
        const findFilter = this.filters.find((filter) => filter.key == key);

        if (findFilter != null) {
          filterNo++;
          if (findFilter.type == "select") {
            this.filterDatas[key] = findFilter.items.find(
              (item) => item.id == this.$route.query[key]
            );
          } else {
            this.filterDatas[key] = this.$route.query[key];
          }
        }
      }

      this.activeFiltersNo = filterNo;
      this.firstRender = false;
    }
  },

  computed: {
    emptyTitleText: function () {
      return this.emptyTitle === ""
        ? this.$t("datatable.empty_item_title")
        : this.emptyTitle;
    },
    emptyDescriptionText: function () {
      return this.emptyDescription === ""
        ? this.$t("datatable.empty_item_description")
        : this.emptyDescription;
    },
    emptyButtonText: function () {
      return this.emptyButton === ""
        ? this.$t("datatable.empty_button_text")
        : this.emptyButton;
    },
    isDataEmpty: function () {
      return this.$lodash.isEmpty(this.apiResponse.data);
    },
    activePreviousButton: function () {
      return this.apiResponse.prev_page_url != null;
    },
    activeNextButton: function () {
      return this.apiResponse.next_page_url != null;
    },
    currentPage() {
      return this.apiResponse.current_page;
    },
    firstPageUrl() {
      return this.apiResponse.first_page_url;
    },
    from() {
      return this.apiResponse.from;
    },
    lastPage() {
      return this.apiResponse.last_page;
    },
    lastPageUrl() {
      return this.apiResponse.last_page_url;
    },
    nextPageUrl() {
      return this.apiResponse.next_page_url;
    },
    perPage() {
      return this.apiResponse.per_page;
    },
    prevPageUrl() {
      return this.apiResponse.prev_page_url;
    },
    to() {
      return this.apiResponse.to;
    },
    total() {
      return this.apiResponse.total;
    },
    pageRange() {
      if (this.limit === -1) {
        return 0;
      }
      if (this.limit === 0) {
        return this.lastPage;
      }
      var current = this.currentPage;
      var last = this.lastPage;
      var delta = this.limit;
      var left = current - delta;
      var right = current + delta + 1;
      var range = [];
      var pages = [];
      var l;
      for (var i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i);
        }
      }
      range.forEach(function (i) {
        if (l) {
          if (i - l === 2) {
            pages.push(l + 1);
          } else if (i - l !== 1) {
            pages.push("...");
          }
        }
        pages.push(i);
        l = i;
      });
      return pages;
    },
    computedFilterDatas() {
      return this.$lodash.cloneDeep(this.filterDatas);
    },
  },

  methods: {
    onValueSelected(value) {
      Object.assign(this.filterSelectionSelected, value);
    },
    selectAll() {
      this.selected = [];

      if (!this.allSelected) {
        this.apiResponse.data.forEach((model) => {
          this.selected.push(model);
        });
      }
    },
    paginationButtonClicked(page) {
      if (page === "...") {
        return;
      }
      this.filterDatas.page = page;
      this.$emit("pagination-change-page", page);
    },
    previousPageClicked() {
      this.paginationButtonClicked(this.currentPage - 1);
    },
    nextPageClicked() {
      this.paginationButtonClicked(this.currentPage + 1);
    },
    paginationPerPageSelected(event) {
      this.filterDatas.per_page = event.target.value;
      this.$emit("pagination-per-page-selected", event.target.value);
      this.paginationButtonClicked(1);
    },
    emptyButtonClicked() {
      this.$emit("empty-button-clicked");
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
      if (this.showFilter) {
        this.$refs.filterButton.$el.focus();
      } else {
        this.$refs.filterButton.$el.blur();
      }
    },
    isCurrentTabName(tab_name) {
      if (this.$route.query.tab == null && this.tabs[0]?.key == tab_name) {
        return true;
      }
      return this.$route.query.tab == tab_name;
    },
    onTabClick(tab_name) {
      this.resetFilter();
      this.$route.query.tab = tab_name;
      this.$emit("tab-changed", tab_name);
      this.paginationButtonClicked(1);
    },
    resetFilter() {
      if (this.loadFilterFromUrl) {
        this.$router.replace({ query: null });
      }
      this.keyInt++;
      this.renderInput = false;
      this.firstRender = false;
      this.filterDatas = {
        page: 1,
        per_page: 15,
      };
      this.$nextTick(() => {
        this.renderInput = true;
      });
    },
    filterChanged(queryText) {
      this.queryParams =
        "?" +
        Object.keys(queryText)
          .map(function (prop) {
            if (
              typeof queryText[prop] === "object" &&
              queryText[prop].id != undefined
            ) {
              return [prop, queryText[prop].id]
                .map(encodeURIComponent)
                .join("=");
            }

            if (typeof queryText[prop] === "object") {
              var updatedValue = "";

              this.$lodash.forOwn(queryText[prop], function (value, _) {
                if (updatedValue != "") {
                  updatedValue += ",";
                }
                updatedValue += value;
              });

              return [prop, updatedValue].map(encodeURIComponent).join("=");
            }

            return [prop, queryText[prop]].map(encodeURIComponent).join("=");
          }.bind(this))
          .join("&");

      this.activeFiltersNo = Object.keys(this.filterDatas).length - 2;
      this.$emit("params-changed", queryText, this.queryParams);
    },
  },
  watch: {
    computedFilterDatas: {
      handler(value, oldValue) {
        if (this.firstRender && this.loadFilterFromUrl) {
          this.firstRender = false;
          return;
        }

        clearTimeout(this.timeout);

        const delay = Object.keys(value).some((key) => key.includes("filter"))
          ? 500
          : 0;

        let self = this;
        this.timeout = setTimeout(function () {
          self.filterChanged(value);
        }, delay);
      },
      deep: true,
    },
    selected(data) {
      this.$emit("select-table-changed", data);
    },
  },
};
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.7s;
}

.bounce-leave-active {
  animation: bounce-in 0.7s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
</style>