<template>
  <app-form-select
    v-if="this.showIfExist"
    :datas="all_business"
    :showLabel="showLabel"
    :labelText="labelText ?? $t('payment.create.select_business')"
    :placeholder="placeholder"
    :loading="loading"
    :allowFilter="allowFilter"
    :errorMessage="errorMessage"
    v-model="checkedValue"
  />
</template>

<script>
import Role from "@/utils/const/role";
import Business_status from "@/utils/const/business_status";

export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelText: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    allowFilter: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: Array,
      default: () => [],
    },

    modelValue: {
      type: Object,
      default: () => {},
    },
    includeMasterBusiness: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Role: Role,
      checkRoleMasterBusiness: [
        Role.MASTER_BUSINESS_OWNER,
        Role.MASTER_BUSINESS_ADMIN,
        Role.MASTER_BUSINESS_MANAGER,
        Role.MASTER_BUSINESS_DEVELOP,
        Role.MASTER_BUSINESS_SUPPORT,
      ],
    };
  },

  computed: {
    checkedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.onValueSelected(value);
      },
    },

    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    all_business() {
      let businesses = this.$store.getters["masterBusinessStore/all_business"];
      let business = this.business;

      if (this.includeMasterBusiness && this.showIfExist && business.id) {
        let label = `(${this.$t("general.master_merchant")})`;

        // change label if business is master business
        if (business?.name?.includes(label) == false) {
          business.name = `${business.name} ${label}`;
        }

        this.onValueSelected(business);
        businesses.unshift(business);
      }

      return businesses;
    },

    showIfExist() {
      return this.checkRoleMasterBusiness.includes(this.selectedRole?.role_id);
    },
  },

  mounted() {
    this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: Business_status.APPROVED,
    });
  },

  methods: {
    onValueSelected(value) {
      this.$store.commit("masterBusinessStore/setSelectBusiness", value);
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
