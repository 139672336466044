export default Object.freeze({
  SENANG_PAY: 'senangpay',
  GO_BIZ: 'gobiz',
  GO_BIZ_UPP: 'gobiz-upp',
  ROSE_ENGINE: 'rosengine',
  AI_GROUP: 'aigroup',
  STRIPE: 'stripe',
  ISERVE: 'iserve',
});

