<template>
  <div class="space-y-2" v-if="rule == 'all' || rule == type[title]" :class="{
    ' border-t border-dashed pt-5':
      title == SUPPORT_DOCUMENT.DIRECTOR_SIGNATURE,
  }">
    <!-- Title -->
    <div class="flex space-x-1">
      <p class="text-md" :class="[{ 'text-red-500': errorsFromServer }]">
        {{ addLabel(title) }}
      </p>
      <p v-if="type[title] == 'sometimes'" class="text-md"> {{ $t("general.optional") }}</p>
    </div>

    <!-- Description -->
    <div class="mb-1">
      <p class="text-sm" v-html="addDescription(title)"></p>
    </div>

    <!-- Upload Document -->
    <div class="
        py-3
        px-5
        w-full
        h-full
        relative
        space-y-1
        rounded-lg
        cursor-pointer
        hover:bg-gray-50
        '
      " :class="[
        dropped
          ? 'border-primary border-2 border-dashed'
          : 'border-gray-400 border-2 border-dashed',
        { 'bg-red-50 border-red-300': errorsFromServer },
        { 'border-red-300': error },
        { 'bg-gray-50 hover:bg-gray-50 cursor-not-allowed': disabled },
      ]" @dragleave.prevent="dragLeave" @dragover.prevent="dragOver" @drop.prevent="dropFile">
      <div class="w-full h-full top-0 left-0 rounded-lg absolute bg-gray-50" v-show="dropped" />

      <!-- Error Message from server -->
      <div v-if="errorsFromServer" class="text-error text-center">
        {{ errorsFromServer[0] }}
      </div>

      <!-- Error Files Message from internal -->
      <div v-show="error" class="text-error text-center">
        {{ error }}
      </div>

      <!-- Upload Image -->
      <div class="relative flex flex-col space-y-3 antialiased">
        <input v-if="!disabled" multiple type="file" ref="uploadInput" @change="fileChange" :accept="supportFileType"
          class="block w-full h-full opacity-0 absolute cursor-pointer z-0" />

        <div class="flex flex-col justify-center items-center px-5 py-1 space-y-2">
          <img alt="Documents" class="object-contain w-10" src="@/assets/image/upload-icon.svg" />
          <p class="font-medium">
            {{ $t("business.support_documents.click_or_drag_file") }}
          </p>
          <p class="text-sm text-gray-500" v-if="documents.length == 0 && files.length == 0">
            {{
                documents.length == 0 && files.length == 0
                  ? $t("business.support_documents.upload_info")
                  : ""
            }}
          </p>
        </div>
      </div>

      <div class="space-y-3">
        <!-- Document File -->
        <div v-for="(document, index) in documents" :key="index" class="
            py-2
            px-4
            flex
            rounded
            space-x-3
            items-center
            justify-between
            hover:bg-gray-100
            border border-gray-300
          ">
          <app-icon-outline name="PaperClipIcon" class="w-5 h-5" />
          <div class="flex-1 flex items-center space-x-2">
            <a class="text-sm uppercase" :href="document.original_url" target="_blank">{{ document.file_name }}</a>
            <p class="text-xs text-gray-500 mt-1">
              {{ Math.round(document.size / 1024) }} KB
            </p>
          </div>
          <app-icon v-if="!disabled" name="XCircleIcon" class="w-5 h-5 text-gray-400 hover:text-red-500"
            @click.prevent.stop="deleteDocument(document)" />
        </div>

        <!-- Temp Document File -->
        <div v-for="(file, index) in files" :key="index" class="
            py-2
            px-4
            flex
            rounded
            space-x-3
            bg-white
            items-center
            justify-between
            hover:bg-gray-100
            border border-gray-300
          ">
          <app-icon-outline name="PaperClipIcon" class="w-5 h-5" />
          <div class="flex-1 flex items-center space-x-2">
            <p class="text-sm uppercase">{{ file.name }}</p>
            <p class="text-xs text-gray-500">
              {{ Math.round(file.size / 1024) }} KB
            </p>
          </div>
          <app-icon v-if="!disabled" name="XCircleIcon" class="w-5 h-5 text-gray-400 hover:text-red-500"
            @click="deleteFile(index)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SUPPORT_DOCUMENT from "@/utils/const/support_document";
import PAYMENT_METHODS from "@/utils/const/payment_methods";
export default {
  data() {
    return {
      SUPPORT_DOCUMENT: SUPPORT_DOCUMENT,
      PAYMENT_METHODS: PAYMENT_METHODS,
      error: "",
      files: [],
      dropped: false,
      tempDocuments: [],
      supportFileType: [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/webp",
      ],
    };
  },
  props: {
    max: Number,
    type: Object,
    rule: String,
    title: String,
    errors: Object,
    documents: Object,
    disabled: Boolean,
  },
  computed: {
    errorsFromServer: {
      get() {
        return this.errors[this.title];
      },
      set(value) {
        //
      },
    },
    required() {
      return this.type[this.title] == "required";
    },
    totalFiles() {
      return (this.documents?.length ?? 0) + (this.files?.length ?? 0);
    },
  },
  methods: {
    dropFile(event) {
      if (this.disabled) return;

      let files = Array.from(event.dataTransfer.files);

      if (files) {
        if (this.validateSupportFileType(files)) {
          let length = files.length ?? 0;

          if (this.totalFiles + length > this.max) {
            this.error = this.$t("business.support_documents.max_file", {
              max: this.max,
            });
          } else {
            this.files.push(...files);
            this.uploadFile(files);
          }
        } else {
          this.error = this.$t(
            "business.support_documents.unsupported_file_type"
          );
        }
      }

      this.dropped = false;
    },

    async fileChange(event) {
      if (this.disabled) return;

      let files = Array.from(event.currentTarget.files);

      if (files) {
        if (this.validateSupportFileType(files)) {
          let length = files.length ?? 0;

          if (this.max && this.totalFiles + length > this.max) {
            this.error = this.$t("business.support_documents.max_file", {
              max: this.max,
            });
            return;
          }

          if (!this.dropped) {
            this.files.push(...files);
          }

          this.uploadFile(files);
        } else {
          this.error = this.$t(
            "business.support_documents.unsupported_file_type"
          );
        }
      }
    },

    async uploadFile(files) {
      if (this.disabled) return;

      let self = this;

      files.forEach(function (file) {
        const form = new FormData();

        form.append("file", file);

        self.$store
          .dispatch("mediaStore/uploadFile", {
            file: form,
          })
          .then((response) => {
            if (response.errors?.file) {
              self.error = response.errors.file[0];
              return;
            }

            file.media_path = response.data.media_path;

            self.resetErrors();

            self.$emit(
              "changed",
              self.files.map((file) => file.media_path),
              self.title
            );
          });
      });
    },

    async deleteFile(index) {
      if (this.disabled) return;

      const confirm_delete = confirm(
        this.$t("business.support_documents.delete_confirm")
      );

      if (confirm_delete) {
        this.files.splice(index, 1);
        this.$emit(
          "changed",
          this.files.map((file) => file.media_path),
          this.title
        );
        this.$refs.uploadInput.value = null;
      }
    },

    async deleteDocument(document) {
      if (this.disabled) return;

      this.$emit("delete", document);
    },

    validateSupportFileType(files) {
      var supportFileType = true;

      files.forEach((file) => {
        if (supportFileType) {
          supportFileType = this.supportFileType.includes(file.type);
        }
      });

      return supportFileType;
    },

    dragOver() {
      this.dropped = true;
    },
    dragLeave() {
      this.dropped = false;
    },
    resetErrors() {
      this.error = "";
      this.errorsFromServer = "";
      this.$store.commit("businessStore/setErrorMessage");
    },

    addLabel(key) {
      switch (key) {
        case SUPPORT_DOCUMENT.DOCUMENT_IC_COPY:
          return PAYMENT_METHODS.ONLINE_BANKING_B2C
            ? this.$t("business.support_documents.label.Director_signatory_ic")
            : this.$t(
              "business.support_documents.label.Director_signatory_ic_cc"
            );
        case SUPPORT_DOCUMENT.DOCUMENT_IC_COPY_BACK:
          return PAYMENT_METHODS.ONLINE_BANKING_B2C
            ? this.$t("business.support_documents.label.Director_signatory_ic")
            : this.$t(
              "business.support_documents.label.Director_signatory_ic_cc"
            );
        case SUPPORT_DOCUMENT.DOCUMENT_COMPANY_BANK_STATEMENT:
          return this.$t("business.support_documents.label.bank_statement");
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_LLP_CERT:
          return this.$t(
            "business.support_documents.label.ssm_llp_certificate"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_A:
          return this.$t("business.support_documents.label.form_a");
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_B:
          return this.$t("business.support_documents.label.form_b");
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_D:
          return this.$t(
            "business.support_documents.label.document_ssm_form_D"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_24:
          return this.$t(
            "business.support_documents.label.document_ssm_form_24"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_49:
          return this.$t(
            "business.support_documents.label.document_ssm_form_49"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_44:
          return this.$t(
            "business.support_documents.label.document_ssm_form_44"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_M_A:
          return this.$t("business.support_documents.label.form_m&a");
        case SUPPORT_DOCUMENT.DOCUMENT_BUSINESS_REGISTRATION:
          return this.$t(
            "business.support_documents.label.document_business_registration"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_LOCAL_COUNCIL:
          return this.$t(
            "business.support_documents.label.document_local_concil"
          );
        case SUPPORT_DOCUMENT.PHOTO_SIGNBOARD:
          return this.$t("business.support_documents.label.signboard");
        case SUPPORT_DOCUMENT.PHOTO_PREMISES:
          return this.$t("business.support_documents.label.premises");
        case SUPPORT_DOCUMENT.PHOTO_DISPLAY_OF_PRODUCT:
          return this.$t("business.support_documents.label.display_of_product");
        case SUPPORT_DOCUMENT.PHOTO_EQUIPMENT:
          return this.$t("business.support_documents.label.equipment");
        case SUPPORT_DOCUMENT.DIRECTOR_SIGNATURE:
          return this.$t("business.support_documents.label.director_signature");
        case SUPPORT_DOCUMENT.COMPANY_STAMP:
          return this.$t("business.support_documents.label.company_stamp");
        case SUPPORT_DOCUMENT.COMPLETE_SSM:
          return this.$t("business.support_documents.label.complete_ssm");
        case SUPPORT_DOCUMENT.DOCUMENT_REPRESENTATIVE_IC_COPY:
          return this.$t("business.support_documents.label.document_representative_ic_copy");
        case SUPPORT_DOCUMENT.DOCUMENT_REPRESENTATIVE_IC_COPY_BACK:
          return this.$t("business.support_documents.label.document_representative_ic_copy");
        case SUPPORT_DOCUMENT.UTILITY_BILLS:
          return this.$t("business.support_documents.label.utility_bills");
        default:
          return key;
      }
    },
    addDescription(key) {
      switch (key) {
        case SUPPORT_DOCUMENT.DOCUMENT_IC_COPY:
          return this.$t(
            "business.support_documents.label_description.Director_signatory_ic"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_IC_COPY_BACK:
          return this.$t(
            "business.support_documents.label_description.Director_signatory_ic_back"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_REPRESENTATIVE_IC_COPY:
          return this.$t(
            "business.support_documents.label_description.document_representative_ic_copy"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_REPRESENTATIVE_IC_COPY_BACK:
          return this.$t(
            "business.support_documents.label_description.document_representative_ic_back"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_COMPANY_BANK_STATEMENT:
          return this.$t(
            "business.support_documents.label_description.bank_statement"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_A:
          return this.$t("business.support_documents.label_description.form_a");
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_B:
          return this.$t("business.support_documents.label_description.form_b");
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_M_A:
          return this.$t(
            "business.support_documents.label_description.form_m&a"
          );
        case SUPPORT_DOCUMENT.UTILITY_BILLS:
          return this.$t(
            "business.support_documents.label_description.utility_bills"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_LOCAL_COUNCIL:
          return this.$t(
            "business.support_documents.label_description.document_local_concil"
          );
        case SUPPORT_DOCUMENT.PHOTO_SIGNBOARD:
          return this.$t(
            "business.support_documents.label_description.signboard"
          );
        case SUPPORT_DOCUMENT.PHOTO_PREMISES:
          return this.$t(
            "business.support_documents.label_description.premises"
          );
        case SUPPORT_DOCUMENT.PHOTO_DISPLAY_OF_PRODUCT:
          return this.$t(
            "business.support_documents.label_description.display_of_product"
          );
        case SUPPORT_DOCUMENT.PHOTO_EQUIPMENT:
          return this.$t(
            "business.support_documents.label_description.equipment"
          );
        case SUPPORT_DOCUMENT.DIRECTOR_SIGNATURE:
          return this.$t(
            "business.support_documents.label_description.director_signature"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_D:
          return this.$t("business.support_documents.label_description.form_D");
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_24:
          return this.$t(
            "business.support_documents.label_description.document_ssm_form_24"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_49:
          return this.$t(
            "business.support_documents.label_description.document_ssm_form_49"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_44:
          return this.$t(
            "business.support_documents.label_description.document_ssm_form_44"
          );
        case SUPPORT_DOCUMENT.COMPANY_STAMP:
          return this.$t(
            "business.support_documents.label_description.company_stamp"
          );
        case SUPPORT_DOCUMENT.DOCUMENT_SSM_LLP_CERT:
          return this.$t(
            "business.support_documents.label_description.ssm_llp_certificate"
          );
        case SUPPORT_DOCUMENT.COMPLETE_SSM:
          return this.$t(
            "business.support_documents.label_description.complete_ssm",
            { url: "http://www.swipego.io/supporting-document" }
          );

        default:
          break;
      }
    },
  },
};
</script>
