import baseStore from "../base-store";
import BusinessRepository from "../../data/repository/business-repository";
import BusinessDetailRepository from "../../data/repository/business-detail-repository";
import BusinessBankRepository from "../../data/repository/business-bank-repository";
import AuthRepository from "../../data/repository/auth-repository";
import { logEvent } from "firebase/analytics";
import BusinessSupportDetailsRepository from "../../data/repository/business-support-details-repository";
import BusinessRepresentativeRepository from "../../data/repository/business-representative-repository";
import BusinessSupportDocumentsRepository from "../../data/repository/business-support-documents-repository";
import TestModeRepository from "../../data/repository/test-mode-repository";
import MEDIA_CATEGORY from "@/utils/const/media_category";

const initialBusiness = {
  id: null,
  business_status_id: null,
  country: {
    id: 160,
    name: "Malaysia",
    icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
  },
  comment: null,
  detail: {
    id: null,
    business_id: null,
    company_name: null,
    company_reg_no: null,
    tax_id: null,
    business_activity: null,
    address_1: null,
    address_2: null,
    postcode: null,
    city: null,
    state: null,
    state_id: null,
    phone_no: null,
    industry: null,
    industry_id: null,
    website: null,
    description: null,
  },
  representative: {
    first_name: null,
    last_name: null,
    email: null,
    date_of_birth: null,
    address_1: null,
    address_2: null,
    postcode: null,
    city: null,
    country_id: null,
    country: {
      id: 160,
      name: "Malaysia",
      icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
    },
    state_id: null,
    phone_no: null,
    identification_type: "MyKad",
    identification: null,
  },
  status: null,
  owners: null,
  support_detail: {
    statement_descriptor: null,
    short_descriptor: null,
    support_tel_no: null,
    support_email: null,
  },
  bank: {
    account_no: null,
    account_holder: null,
  },
  config:{
    payout_type: "daily"
  },
};

export default {
  namespaced: true,
  state: {
    // variable
    ...baseStore.state,
    business: initialBusiness,
    list_business: [],
    all_business: [],
    test_mode: null,
    support_documents: [],
    type_support_documents: [],
    validate_support_documents: false,
    merchant_types: [],
  },

  getters: {
    // method untuk di akses
    ...baseStore.getters,

    business(state) {
      if (state.business == null) return state.business;

      if (state.business.detail == null) {
        state.business.detail = initialBusiness.detail;
      }
      if (state.business.representative == null) {
        state.business.representative = initialBusiness.representative;
      }
      if (state.business.support_detail == null) {
        state.business.support_detail = initialBusiness.support_detail;
      }
      if (state.business.bank == null) {
        state.business.bank = initialBusiness.bank;
      }
      return state.business;
    },

    list_business(state) {
      return state.list_business;
    },

    all_business(state) {
      return state.all_business;
    },

    logo(state) {
      return state.business.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.LOGO
      )?.original_url;
    },

    icon(state) {
      return state.business.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.ICON
      )?.original_url;
    },

    test_mode(state) {
      if (state.test_mode == null) {
        const testModeRepository = new TestModeRepository();
        state.test_mode = testModeRepository.getTestMode();
      }
      return state.test_mode;
    },

    support_documents(state) {
      return state.support_documents;
    },

    validate_support_documents(state) {
      return state.validate_support_documents;
    },

    type_support_documents(state) {
      return state.type_support_documents;
    },

    merchant_types(state) {
      return state.merchant_types;
    },
  },

  mutations: {
    // method untuk ubah value variable di state
    ...baseStore.mutations,

    setBusiness(state, business) {
      state.business = business;
    },

    setBusinessDetail(state, detail) {
      state.business.detail = detail;
    },

    setBusinessRepresentative(state, representative) {
      state.business.representative = representative;
    },

    setBusinessOwners(state, owners) {
      state.business.owners = owners;
    },

    setBusinessBank(state, bank) {
      if (bank.bank) {
        bank.bank.name = bank.bank.full_name;
        bank.bank.icon = bank.bank.logo_url;
      }
      state.business.bank = bank;
    },

    setTestMode(state, test_mode) {
      state.test_mode = test_mode;
    },

    setBusinessSupportDetails(state, support_detail) {
      state.business.support_detail = support_detail;
    },

    clearBusiness(state) {
      state.business = initialBusiness;
    },

    setListBusiness(state, list_business) {
      state.list_business = list_business;
    },

    setAllBusiness(state, all_business) {
      state.all_business = all_business;
    },

    setSupportDocuments(state, support_documents) {
      state.support_documents = support_documents;
    },
    setValidateSupportDocuments(state, validate_support_documents) {
      state.validate_support_documents = validate_support_documents;
    },
    setTypeSupportDocuments(state, type_support_documents) {
      state.type_support_documents = type_support_documents;
    },
    setTestModePublicPage(state, testMode) {
      this.$backendURL = (testMode)
        ? process.env.VUE_APP_BACKEND_URL_TEST
        : process.env.VUE_APP_BACKEND_URL;
    },
    setMerchantTypes(state, merchantTypes) {
      state.merchant_types = merchantTypes;
    }
  },

  actions: {
    // method untuk long process / asynchronous
    async createOrUpdate(state, business) {
      const businessRepository = new BusinessRepository();

      state.commit("setLoading", true);

      const businessResult = await businessRepository.createOrUpdate(business);

      if (businessResult.message != null) {
        logEvent(
          this.$analytics,
          business.id == null ? "create-business" : "update-business",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(
          this.$analytics,
          businessResult.data.id == null
            ? "create-business"
            : "update-business",
          { result: "success" }
        );

        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);
      return businessResult.data;
    },

    async getBusinessFromSelectedRoleBusinessId(state) {
      const businessRepository = new BusinessRepository();
      const authRepository = new AuthRepository();

      const businessId = authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      const businessResult = await businessRepository.getBusinessById(
        businessId
      );

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-business", { result: "success" });
        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async getBusinessSupportDocuments(state, businessId) {
      const businessRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      businessId = businessId ?? authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      const businessResult = await businessRepository.index(businessId);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "index-business-support-document", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "index-business-support-document", { result: "success" });
        state.commit("setSupportDocuments", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    async deleteBusinessSupportDocument(state, data) {
      const businessRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const businessId = data.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      const businessResult = await businessRepository.delete(
        businessId,
        data.document_id
      );

      if (businessResult.message != null) {
        logEvent(this.$analytics, "delete-business-support-document", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "delete-business-support-document", { result: "success" });
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    async createOrUpdateBusinessSupportDocument(state, data) {
      const businessRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const businessId = data.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      const businessResult = await businessRepository.createOrUpdate(
        businessId,
        data.documents
      );

      if (businessResult.message != null) {
        logEvent(this.$analytics, "create-business-support-document", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "create-business-support-document", { result: "success" });
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async validateBusinessSupportDocuments(state) {
      const businessRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const businessId = authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      const businessResult = await businessRepository.validate(businessId);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "validate-business-business-support-document", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "validate-business-business-support-document", { result: "success" });
        state.commit("setValidateSupportDocuments", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    async typeBusinessSupportDocuments(state, business_id) {
      const businessRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const businessId = business_id ?? authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      const businessResult = await businessRepository.type(businessId);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "type-business-business-support-document", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "type-business-business-support-document", { result: "success" });
        state.commit("setTypeSupportDocuments", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    async createOrUpdateBusinessOwner(state, business_owner) {
      const businessRepository = new BusinessRepository();
      const authRepository = new AuthRepository();

      const businessId = business_owner.business_id
        ? business_owner.business_id
        : authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      state.commit("setLoading", true);

      const businessOwnerResult =
        await businessRepository.createOrUpdateBusinessOwner(
          businessId,
          business_owner
        );

      if (businessOwnerResult.message != undefined) {
        logEvent(
          this.$analytics,
          businessOwnerResult.id == null
            ? "create-business-owner"
            : "update-business-owner",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessOwnerResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          businessOwnerResult.id == null
            ? "create-business-owner"
            : "update-business-owner",
          { result: "success" }
        );
      }

      state.commit("setLoading", false);

      return businessOwnerResult;
    },

    async deleteBusinessOwner(state, businessOwner) {
      const businessRepository = new BusinessRepository();
      const authRepository = new AuthRepository();

      const businessId = businessOwner.business_id
        ? businessOwner.business_id
        : authRepository.getSelectedRole()?.business_id;

      state.commit("setLoading", true);

      const businessOwnerResult = await businessRepository.deleteBusinessOwner(
        businessId,
        businessOwner.id
      );

      if (businessOwnerResult.message != null) {
        logEvent(this.$analytics, "delete-business-owner", {
          result: "failed",
        });
        state.commit("setErrorMessage", businessOwnerResult);
      } else {
        logEvent(this.$analytics, "delete-business-owner", {
          result: "success",
        });
      }

      state.commit("setLoading", false);

      return businessOwnerResult;
    },

    async createOrUpdateBusinessDetail(state, detail) {
      detail.business_id = detail.business_id
        ? detail.business_id
        : state.getters["business"].id;

      const businessDetailRepository = new BusinessDetailRepository();

      const businessDetailResult =
        await businessDetailRepository.createOrUpdate(detail);

      if (businessDetailResult.message != null) {
        logEvent(
          this.$analytics,
          detail.id == null
            ? "create-business-detail"
            : "update-business-detail",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessDetailResult);
        return;
      }

      logEvent(
        this.$analytics,
        detail.id == null ? "create-business-detail" : "update-business-detail",
        { result: "success" }
      );

      state.commit("setBusinessDetail", businessDetailResult.data);
      state.commit("setLoading", false);
      return businessDetailResult.data;
    },

    async createOrUpdateBusinessRepresentative(state, representative) {
      representative.business_id = state.getters["business"].id;

      const businessRepresentativeRepository =
        new BusinessRepresentativeRepository();

      const businessRepresentativeResult =
        await businessRepresentativeRepository.createOrUpdate(representative);

      if (businessRepresentativeResult.message != null) {
        logEvent(
          this.$analytics,
          representative.id == null
            ? "create-business-representative"
            : "update-business-representative",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessRepresentativeResult);
        return;
      }
      logEvent(
        this.$analytics,
        representative.id == null
          ? "create-business-representative"
          : "update-business-representative",
        { result: "success" }
      );

      state.commit(
        "setBusinessRepresentative",
        businessRepresentativeResult.data
      );
      state.commit("setLoading", false);

      return businessRepresentativeResult.data;
    },

    async createOrUpdateBusinessBank(state, bank) {
      bank.business_id = state.getters["business"].id;

      const businessBankRepository = new BusinessBankRepository();

      const businessBankResult = await businessBankRepository.createOrUpdate(
        bank
      );

      if (businessBankResult.message != null) {
        logEvent(
          this.$analytics,
          bank.id == null ? "create-business-bank" : "update-business-bank",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessBankResult);
        return;
      }
      logEvent(
        this.$analytics,
        bank.id == null ? "create-business-bank" : "update-business-bank",
        { result: "success" }
      );

      state.commit("setBusinessBank", businessBankResult.data);
      state.commit("setLoading", false);
      return businessBankResult.data;
    },

    async createOrUpdateBusinessSupportDetails(state, support_detail) {
      support_detail.business_id = support_detail.business_id
        ? support_detail.business_id
        : state.getters["business"].id;

      const businessSupportDetailsRepository =
        new BusinessSupportDetailsRepository();

      const businessSupportDetailsResult =
        await businessSupportDetailsRepository.createOrUpdate(support_detail);

      if (businessSupportDetailsResult.message != null) {
        logEvent(
          this.$analytics,
          support_detail.id == null
            ? "create-business-support-details"
            : "update-business-support-details",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessSupportDetailsResult);
        return;
      }

      logEvent(
        this.$analytics,
        support_detail.id == null
          ? "create-business-support-details"
          : "update-business-support-details",
        { result: "success" }
      );

      state.commit(
        "setBusinessSupportDetails",
        businessSupportDetailsResult.data
      );
      state.commit("setLoading", false);

      return businessSupportDetailsResult.data;
    },

    async getListBusiness(state, params = {}) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();

      const businessResult = await businessRepository.getListBusiness(params);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-business-index", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-business-index", { result: "success" });
        state.commit("setListBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async getAllMerchantType(state) {

      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();
      const merchantTypeResults = await businessRepository.getAllMerchantType();

      if (merchantTypeResults.message != null) {
        logEvent(this.$analytics, "get-merchant-type", { result: "failed" });
        state.commit("setErrorMessage", merchantTypeResults);
      } else {
        logEvent(this.$analytics, "get-merchant-type", { result: "success" });
      }

      const merchantTypes = [];

      merchantTypeResults.data?.forEach((merchantType) => {
        merchantTypes.push(
          {
            id: merchantType,
            name: merchantType?.split("_").join(" "),
          }
        );
      });

      state.commit("setLoading", false);
      state.commit("setMerchantTypes", merchantTypes);

      return merchantTypeResults;
    },

    async getBusinessById(state, businessId) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();

      const businessResult = await businessRepository.getBusinessById(
        businessId
      );

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-business", { result: "success" });
        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async statusBusiness(state, business) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();

      const businessResult = await businessRepository.statusBusiness(
        business.id,
        business.business_status_id,
        business.comment
      );

      if (businessResult.message != null) {
        logEvent(this.$analytics, "update-status-business", {
          result: "failed",
        });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "update-status-business", {
          result: "success",
        });
        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async statusPayout(state, data) {
      state.commit("setLoading", true);
      const authRepository = new AuthRepository();

      const business_id = data.business_id ?? authRepository.getSelectedRole()?.business_id;
      const businessRepository = new BusinessRepository();

      const businessResult = await businessRepository.statusPayout(
        business_id,
        data.payout_status,
      );
      if (businessResult.message != null) {
        logEvent(this.$analytics, "update-status-business", {
          result: "failed",
        });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "update-status-business", {
          result: "success",
        });
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async upgradeBusiness(state, business) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();
      var master_business_type = "master_merchant";

      const businessResult = await businessRepository.upgradeBusiness(
        business.id,
        master_business_type,
      );

      if (businessResult.message != null) {
        logEvent(this.$analytics, "upgrade-business", {
          result: "failed",
        });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "upgrade-business", {
          result: "success",
        });
        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async submitBusiness(state, businessId) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();

      const businessResult = await businessRepository.submitBusiness(
        businessId
      );

      state.commit("setLoading", false);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "submit-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
        return null;
      } else {
        logEvent(this.$analytics, "submit-business", { result: "success" });
        state.commit("setBusiness", businessResult.data);
      }

      return businessResult;
    },

    async getAllBusiness(state, params = {}) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();

      const businessResult = await businessRepository.getAllBusiness(params);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-all-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-all-business", { result: "success" });
        state.commit("setAllBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async getBusinesses(state, params = {}) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();
      const businessResult = await businessRepository.getBusinesses(params);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-user-businesses", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-user-businesses", { result: "success" });
        state.commit("setAllBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    async copyBusiness(state, businessId) {
      state.commit("setLoading", true);

      const businessRepository = new BusinessRepository();
      const businessResult = await businessRepository.copyBusiness(businessId);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "copy-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "copy-business", { result: "success" });
        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    setTestMode(state, value) {
      const testModeRepository = new TestModeRepository();

      testModeRepository.setTestMode(value);

      state.commit("setTestMode", value);

      return value;
    },

    removeTestMode(state) {
      const testModeRepository = new TestModeRepository();

      testModeRepository.removeTestMode();

      state.commit("setTestMode", null);
    },
  },
};
